export type CustomModelHumanAngle = {
  id: string;
  shortenedPrompt: string;
  prompt: string;
  img: string;
};

export const humanAngleOptions: CustomModelHumanAngle[] = [
  {
    id: "human-angle-front",
    shortenedPrompt: "Human angle is front facing.",
    prompt:
      "Human angle is front full body. A figure facing forward in a full-body view, showing the entire figure from head to toe.",
    img: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/86b662df-931d-4bfb-c922-394658a60600/512",
  },
  {
    id: "human-angle-left-3-4",
    shortenedPrompt: "Human angle is left 3/4 facing.",
    prompt:
      "Human angle is left 3/4 full body. A figure standing at a left three-quarter angle in full-body view, revealing both front and side from head to toe.",
    img: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/222ed6b5-4c8b-41da-ed2e-60c239be7700/512",
  },
  {
    id: "human-angle-right-3-4",
    shortenedPrompt: "Human angle is right 3/4 facing.",
    prompt:
      "Human angle is right 3/4 full body. A figure standing at a right three-quarter angle in full-body view, combining partial front and side perspectives from head to toe.",
    img: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/4d6b32ab-004b-4ac0-292c-4303d0e6ed00/512",
  },
  {
    id: "human-angle-back",
    shortenedPrompt: "Human angle is back facing.",
    prompt:
      "Human angle is back full body. A figure standing in a full-body view from behind, focusing on the back and posture from head to toe.",
    img: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/0ad80d42-a2bb-496e-90eb-6b1941aa3a00/512",
  },
];
