import { debugError } from "@/core/utils/print-utilts";

export type CustomModelScaleConfig = { scale?: number; modelId: string; trainingId: string };

export type CustomModelScaleConfigs = Record<string, CustomModelScaleConfig>;

export function isCustomModelScaleConfig(obj: any): obj is CustomModelScaleConfig {
  try {
    // Check if object exists and is an object
    if (!obj || typeof obj !== "object") {
      return false;
    }

    // Check modelId property (must be a non-empty string)
    if (typeof obj.modelId !== "string" || !obj.modelId.trim()) {
      return false;
    }

    // Check trainingId property (must be a non-empty string)
    if (typeof obj.trainingId !== "string" || !obj.trainingId.trim()) {
      return false;
    }

    // All checks passed
    return true;
  } catch (error) {
    debugError("Error in isCustomModelScaleConfig:", error);
    return false;
  }
}

export function isCustomModelScaleConfigs(obj: any): obj is CustomModelScaleConfigs {
  try {
    // Check if object exists and is an object
    if (!obj || typeof obj !== "object") {
      return false;
    }

    // Check if it's an array (should be an object, not an array)
    if (Array.isArray(obj)) {
      return false;
    }

    // Check each entry in the object
    for (const key in obj) {
      if (!isCustomModelScaleConfig(obj[key])) {
        return false;
      }
    }

    // All checks passed
    return true;
  } catch (error) {
    debugError("Error in isCustomModelScaleConfigs:", error);
    return false;
  }
}
