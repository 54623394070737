import { CustomModelPromptSubject } from "@/core/common/types/custom-model-types";

const CustomModelPromptSubjectToAppendInstructionMap = {
  [CustomModelPromptSubject.Human]: appendHumanPromptInstructions,
  [CustomModelPromptSubject.Product]: appendProductPromptInstructions,
  [CustomModelPromptSubject.Background]: appendBackgroundPromptInstructions,
  [CustomModelPromptSubject.HumanAngle]: appendHumanAnglePromptInstructions,
};

export function appendPromptInstructions({
  textToAdd,
  subjectToAdd,
  prompt,
  triggerWordsToMaintain,
}: {
  textToAdd: string;
  subjectToAdd: CustomModelPromptSubject;
  prompt: string;
  triggerWordsToMaintain: string[];
}): string {
  const selectedInstruction = CustomModelPromptSubjectToAppendInstructionMap[subjectToAdd];

  if (!selectedInstruction) {
    throw new Error(`No prompt instruction found for subject: ${subjectToAdd}`);
  }

  return selectedInstruction({ textToAdd, prompt, triggerWordsToMaintain });
}

const CustomModelPromptSubjectToRemoveInstructionMap = {
  [CustomModelPromptSubject.Human]: removeHumanPromptInstructions,
  [CustomModelPromptSubject.Product]: removeProductPromptInstructions,
  [CustomModelPromptSubject.Background]: removeBackgroundPromptInstructions,
  [CustomModelPromptSubject.HumanAngle]: removeHumanAnglePromptInstructions,
};

export function removePromptInstructions({
  textToRemove,
  subjectToRemove,
  prompt,
  triggerWordsToRemove,
  triggerWordsToMaintain,
}: {
  textToRemove?: string;
  subjectToRemove: CustomModelPromptSubject;
  prompt: string;
  triggerWordsToRemove: string[];
  triggerWordsToMaintain: string[];
}): string {
  const selectedInstruction = CustomModelPromptSubjectToRemoveInstructionMap[subjectToRemove];

  if (!selectedInstruction) {
    throw new Error(`No prompt instruction found for subject: ${subjectToRemove}`);
  }

  return selectedInstruction({
    textToRemove,
    prompt,
    triggerWordsToRemove,
    triggerWordsToMaintain,
  });
}

export function appendHumanPromptInstructions({
  textToAdd,
  prompt,
  triggerWordsToMaintain,
}: {
  textToAdd: string;
  prompt: string;
  triggerWordsToMaintain: string[];
}) {
  return `Given a prompt and some text describing a human, append or replace the human description in the prompt. Follow these specific rules:

1) HUMAN DESCRIPTIONS
If the text describes a human model (e.g., "young woman [trigger1] with sleek pulled back hair," "a fair skinned blond woman," or "a tall handsome man") and there is ONLY ONE human in the prompt, REPLACE that human with the new description (even if genders differ). Always condense the output to the most relevant details. I.e. if the text says "a young woman [trigger1] with sleek pulled back hair," the output should be "a woman [trigger1]".

2) TRIGGER HANDLING
If the prompt or the text contains a [trigger] token, do not alter it. For example, if the new text says "light blue [trigger0] pleated skirt," keep "[trigger0]" unchanged. No changes to spelling, casing, or bracket notation are allowed. Treat triggers with extreme caution; if "[trigger4]" is not mentioned in the prompt or the text to append, do not create it. Each trigger represents a single noun (human, fashion item, product, or style).

3) FINAL OUTPUT
Return the updated, combined prompt as a single coherent description in JSON format. Maintain details from the original prompt that do not conflict with or get replaced by the new text. Ensure all [trigger]s are preserved.

Return a JSON object:
json { "modifiedPrompt": string }
"modifiedPrompt" is the new prompt if the original was invalid, otherwise the same as input.

EXAMPLES:

Example #1: Replacing a human.
Prompt: "A photo of a middle-aged man with short blond hair wearing a casual [trigger0] gray t-shirt..."
Text: "A teenage boy [trigger1] with curly black hair wearing a red hoodie."
Output: "A photo of a teenage boy [trigger1]..."
Only one human, so replace him with the new description.

INPUTS
-------------------------------------------------------------------------------------------
Prompt: ${prompt}
Text to be added: ${textToAdd}
Trigger words to maintain: ${triggerWordsToMaintain}
-------------------------------------------------------------------------------------------`.trim();
}

export function appendProductPromptInstructions({
  textToAdd,
  prompt,
  triggerWordsToMaintain,
}: {
  textToAdd: string;
  prompt: string;
  triggerWordsToMaintain: string[];
}) {
  return `Given a prompt and some text describing a product (clothing, accessories, etc), append or replace the product description in the prompt. Follow these specific rules:

1) CLOTHING / ACCESSORIES
If the text describes a new article of clothing or accessory:
- If a human can only reasonably wear one of a certain item at a time (e.g., one shirt, one pair of pants, one jacket, one pair of shoes, one hat), REPLACE the old item with the new one.
- If a human can reasonably wear multiples (e.g., two earrings, two watches, two purses), then ADD the new item in addition to the old one.

Example single-wear items: shirt, jacket, sweater, blouse, pants, shorts, pair of gloves, coat, hat, pair of shoes, scarf, backpack, pair of sunglasses, tie, socks.
Example multi-wear items: Hand bags, accessories, jewelry, rings, necklaces.

2) PRODUCT DESCRIPTIONS
Always condense the text to be added. For example, if it says "white puffy down cropped [trigger1] woman's winter coat with a red lining," condense it "white [trigger1] winter coat." If it says "A [trigger2] swivel chair with a rounded shape, dusty rose velvet upholstery, a tufted design, and a solid wood frame," condense it "a [trigger2] swivel chair."

3) TRIGGER HANDLING
If the prompt or the text contains a [trigger] token, do not alter it. For example, if the new text says "light blue [trigger0] pleated skirt," keep "[trigger0]" unchanged. No changes to spelling, casing, or bracket notation are allowed. Treat triggers with extreme caution; if "[trigger4]" is not mentioned in the prompt or the text to append, do not create it. Each trigger represents a single noun (human, fashion item, product, or style).

4) FINAL OUTPUT
Return the updated, combined prompt as a single coherent description in JSON format. Maintain details from the original prompt that do not conflict with or get replaced by the new text. Ensure all [trigger]s are preserved.

Return a JSON object:
json { "modifiedPrompt": string }
"modifiedPrompt" is the new prompt if the original was invalid, otherwise the same as input.

EXAMPLES:

Example #1: Adding an accessory.
Prompt: "A photo of a woman white tight bun hair pulled back wearing a jacket [trigger0]..."
Text: "chic [trigger1] handbag..."
Output: "A photo of a woman white tight bun hair pulled back wearing a jacket [trigger0] and a chic [trigger1] handbag..."
The woman can wear both a jacket and handbag, so append.

Example #2: Replacing the same clothing.
Prompt: "Photo of a woman...wearing light blue [trigger0] pleated skirt..."
Text: "A [trigger1] red and white one piece activewear jumpsuit..."
Output: "Photo of a woman...wearing a [trigger1] red and white one piece activewear jumpsuit..."
Replace the skirt with the one-piece.

INPUTS
-------------------------------------------------------------------------------------------
Prompt: ${prompt}
Text to be added: ${textToAdd}
Trigger words to maintain: ${triggerWordsToMaintain}
-------------------------------------------------------------------------------------------`.trim();
}

export function appendBackgroundPromptInstructions({
  textToAdd,
  prompt,
  triggerWordsToMaintain,
}: {
  textToAdd: string;
  prompt: string;
  triggerWordsToMaintain: string[];
}) {
  return `Given a prompt and some text describing a background or setting, append or replace the background description in the prompt. Follow these specific rules:

1) BACKGROUND DESCRIPTIONS
If the text describes a background or setting (e.g., "in a studio", "on a beach", "against a white wall", "posing against a beige fabric"), REPLACE any existing background description with the new one. Ensure that the new background description is maintained word for word.

2) FURNITURE
If the text describes furniture such as a chair or couch, add a sentence to say that any human mentioned in the prompt is sitting or lying on it if such a human exists.
If the text to be added does not describe any furniture, remove any relational sentences that mention furniture, i.e. "the woman is sitting on a chair" or "the man is lying on a couch".

3) JEWELRY BACKGROUNDS
If the text to add doesn't describe a human body part, e.g. a hand, a face, a neck, an ear, etc. then remove any parts of the prompt that describe a human body part.
If the text to add describes a human body part, then ensure that the prompt contains a human body part description that makes sense with the prompt. I.e. so a ring is placed on a hand, an earring is placed on an ear, etc.

3) TRIGGER HANDLING
If the prompt or the text contains a [trigger] token, do not alter it. For example, if the new text says "light blue [trigger0] pleated skirt," keep "[trigger0]" unchanged. No changes to spelling, casing, or bracket notation are allowed. Treat triggers with extreme caution; if "[trigger4]" is not mentioned in the prompt or the text to append, do not create it.

4) FINAL OUTPUT
Return the updated, combined prompt as a single coherent description in JSON format. Maintain details from the original prompt that do not conflict with or get replaced by the new text. Ensure all [trigger]s are preserved. Do not alter the text to be added. However ensure proper punctuation and spacing.

5) EXAMPLES
Prompt: "A photo of a woman [trigger0] wearing a red dress [trigger1]..."
Text: "The background is a beige fabric and a wooden stool"
Output: {"modifiedPrompt": "A photo of a woman [trigger0] wearing a red dress [trigger1]. The woman is sitting on a wooden stool."}
Explanation: The text describes furniture, so add a sentence to say that the woman is sitting on it.

Prompt: "A photo of a woman [trigger0] wearing a red dress [trigger1]. The woman is sitting on a wooden stool. The background is a beige fabric and wooden stool."
Text: "The background is the beach"
Output: {"modifiedPrompt": "A photo of a woman [trigger0] wearing a red dress [trigger1]. The background is the beach."}
Explanation: The text describes a background, so replace the existing background description with the new one".


Return a JSON object:
json { "modifiedPrompt": string }
"modifiedPrompt" is the new prompt if the original was invalid, otherwise the same as input.

INPUTS
-------------------------------------------------------------------------------------------
Prompt: ${prompt}
Text to be added: ${textToAdd}
Trigger words to maintain: ${triggerWordsToMaintain}
-------------------------------------------------------------------------------------------`.trim();
}

export function appendHumanAnglePromptInstructions({
  textToAdd,
  prompt,
  triggerWordsToMaintain,
}: {
  textToAdd: string;
  prompt: string;
  triggerWordsToMaintain: string[];
}) {
  return `Given a prompt and some text describing a camera angle or view, append or replace the camera angle description in the prompt. Follow these specific rules:

1) CAMERA ANGLE DESCRIPTIONS
If the text describes a camera angle or view (e.g., "front view", "from behind", "from the side"), REPLACE any existing camera angle description with the new one.

2) TRIGGER HANDLING
If the prompt or the text contains a [trigger] token, do not alter it. For example, if the new text says "light blue [trigger0] pleated skirt," keep "[trigger0]" unchanged. No changes to spelling, casing, or bracket notation are allowed. Treat triggers with extreme caution; if "[trigger4]" is not mentioned in the prompt or the text to append, do not create it.

3) FINAL OUTPUT
Return the updated, combined prompt as a single coherent description in JSON format. Maintain details from the original prompt that do not conflict with or get replaced by the new text. Ensure all [trigger]s are preserved.

Return a JSON object:
json { "modifiedPrompt": string }
"modifiedPrompt" is the new prompt if the original was invalid, otherwise the same as input.

INPUTS
-------------------------------------------------------------------------------------------
Prompt: ${prompt}
Text to be added: ${textToAdd}
Trigger words to maintain: ${triggerWordsToMaintain}
-------------------------------------------------------------------------------------------`.trim();
}

export function removeHumanPromptInstructions({
  textToRemove,
  prompt,
  triggerWordsToRemove,
  triggerWordsToMaintain,
}: {
  textToRemove: string;
  prompt: string;
  triggerWordsToRemove?: string[];
  triggerWordsToMaintain: string[];
}) {
  return `
# These instructions define how to remove a human description from an existing prompt context.

Given a prompt and some text describing a human, remove the human description from the prompt if there
is a meaningful match. Otherwise, do nothing. Follow these specific rules:

1) HUMAN REMOVAL
   # If the text describes a human that is present in the prompt:
   #   - If there is exactly one human in the prompt, remove the details of that human and keep the rest of the scene if relevant.
   #   - If there are multiple humans in the prompt, remove only the matching human. The other
   #     humans remain unchanged.

2) TRIGGER HANDLING
   # Do not preserve triggers if they are a part of the text to remove. Treat triggers with extreme caution. If a trigger is not mentioned in the prompt or the text to remove,
   # do not create it. For example if [trigger0] is not mentioned in the prompt or the text to be removed,
   # ensure that the output does not contain [trigger0].

3) TRIGGER WORDS TO MAINTAIN
   # If the prompt or the text contains a [trigger] token, do not alter it. For example, if the new text says "light blue [trigger0] pleated skirt," keep "[trigger0]" unchanged. No changes to spelling, casing, or bracket notation are allowed. Treat triggers with extreme caution; if "[trigger4]" is not mentioned in the prompt or the text to append, do not create it. Each trigger represents a single noun (human, fashion item, product, or style).

4) TRIGGER WORDS TO REMOVE
   # Ensure that the trigger words to remove are not present in the final output.

5) FINAL OUTPUT
 Return a JSON object:
json { "modifiedPrompt": string }
"modifiedPrompt" is the new prompt if the original was invalid, otherwise the same as input.

# -------------------------------------------------------------------------------------------
# EXAMPLES
# -------------------------------------------------------------------------------------------

# Example #1: Remove a human that exists in the prompt when there are multiple humans
# ----------------------------------------------------------------------------
# Prompt:
# A photo of a middle-aged [trigger0] man with short blond hair wearing a casual gray t-shirt and blue jeans
# next to a woman [trigger1] with long dark hair wearing a polka dot dress, leaning against a brick wall in
# an urban setting.
#
# Text to be removed:
# a woman [trigger1] with long dark hair wearing a polka dot dress
#
# Explanation:
# The prompt has multiple humans. We remove only the matching woman. The man remains. We maintain [trigger0] and do not create new triggers.
#
# Output:
# { "modifiedPrompt": "A photo of a middle-aged man [trigger0] with short blond hair wearing a casual gray t-shirt and blue jeans,
# leaning against a brick wall in an urban setting." }

# Example #2: Remove a human when there is one human in the prompt
# ----------------------------------------------------------------------------
# Prompt:
# A photo of a single mother with curly black hair wearing a yellow sweater, smiling at the camera.
#
# Text to be removed:
# a single mother with curly black hair wearing a yellow sweater
#
# Explanation:
# There's only one person. We remove that description entirely and return an empty prompt.
#
# Output:
# { "modifiedPrompt": "" }

# -------------------------------------------------------------------------------------------
# INPUTS
# -------------------------------------------------------------------------------------------
# - The original prompt: ${prompt}
# - The text to be removed: ${textToRemove}
# - Trigger words to remove: ${triggerWordsToRemove}
# - Trigger words to maintain: ${triggerWordsToMaintain}
# -------------------------------------------------------------------------------------------
`;
}

export function removeProductPromptInstructions({
  textToRemove,
  prompt,
  triggerWordsToRemove,
  triggerWordsToMaintain,
}: {
  textToRemove: string;
  prompt: string;
  triggerWordsToRemove?: string[];
  triggerWordsToMaintain: string[];
}) {
  return `
# These instructions define how to remove product descriptions (clothing, accessories) from an existing prompt context.

Given a prompt and some text describing a product, remove the product description from the prompt if there
is a meaningful match. Otherwise, do nothing. Follow these specific rules:

1) CLOTHING REMOVAL
   # If the text describes a piece of clothing (e.g., a jacket, skirt, etc.) that matches something
   # the existing human(s) is wearing, remove that clothing from the prompt. If the text describes
   # clothing that is not found in the prompt, do nothing.

2) ACCESSORY REMOVAL
   # If the text describes an accessory (e.g., handbag, watch, goggles) that matches something
   # already in the prompt, remove that accessory. If it's not found, do nothing.

3) TRIGGER HANDLING
   # Do not preserve triggers if they are a part of the text to remove. Treat triggers with extreme caution. If a trigger is not mentioned in the prompt or the text to remove,
   # do not create it. For example if [trigger0] is not mentioned in the prompt or the text to be removed,
   # ensure that the output does not contain [trigger0].

4) TRIGGER WORDS TO MAINTAIN
   # If the prompt or the text contains a [trigger] token, do not alter it. For example, if the new text says "light blue [trigger0] pleated skirt," keep "[trigger0]" unchanged. No changes to spelling, casing, or bracket notation are allowed. Treat triggers with extreme caution; if "[trigger4]" is not mentioned in the prompt or the text to append, do not create it. Each trigger represents a single noun (human, fashion item, product, or style).

5) TRIGGER WORDS TO REMOVE
   # Ensure that the trigger words to remove are not present in the final output.

6) FINAL OUTPUT
 Return a JSON object:
json { "modifiedPrompt": string }
"modifiedPrompt" is the new prompt if the original was invalid, otherwise the same as input.

# -------------------------------------------------------------------------------------------
# EXAMPLES
# -------------------------------------------------------------------------------------------

# Example #1: Remove clothing
# ----------------------------------------------------------------------------
# Prompt:
# A photo of a young woman wearing a black t-shirt [trigger0] and a denim jacket [trigger1]
# with a patch on the left arm, standing on a city sidewalk, 35mm lens, professional fashion photography.
#
# Text to be removed:
# a denim jacket [trigger1] with a patch on the left arm
#
# Explanation:
# That exact piece of clothing exists. Remove it from the prompt, leaving the rest. We maintain [trigger0] and do not create new triggers.
#
# Output:
# { "modifiedPrompt": "A photo of a young woman wearing a black t-shirt [trigger0], standing on a city sidewalk, 35mm lens, professional fashion photography." }

# Example #2: Remove an accessory
# ----------------------------------------------------------------------------
# Prompt:
# A photo of a woman wearing a classic [trigger0] black cocktail dress, standing in front of a shimmering
# city skyline at night, facing the camera with a poised expression, holding a small glass perfume
# bottle with a crystal stopper in her right hand.
#
# Text to be removed:
# a small glass perfume bottle with a crystal stopper
#
# Explanation:
# That accessory exists. Remove it from the prompt. We maintain [trigger0] and do not create new triggers.
#
# Output:
# { "modifiedPrompt": "A photo of a woman wearing a classic [trigger0] black cocktail dress, standing in front of a shimmering
# city skyline at night, facing the camera with a poised expression." }

# -------------------------------------------------------------------------------------------
# INPUTS
# -------------------------------------------------------------------------------------------
# - The original prompt: ${prompt}
# - The text to be removed: ${textToRemove}
# - Trigger words to remove: ${triggerWordsToRemove}
# - Trigger words to maintain: ${triggerWordsToMaintain}
# -------------------------------------------------------------------------------------------
`;
}

export function removeHumanAnglePromptInstructions({
  prompt,
  triggerWordsToRemove,
  triggerWordsToMaintain,
}: {
  prompt: string;
  triggerWordsToRemove?: string[];
  triggerWordsToMaintain: string[];
}) {
  return `
# These instructions define how to remove camera angle descriptions from an existing prompt context.

Given a prompt, remove any camera angle or view descriptions if present. Follow these specific rules:

1) CAMERA ANGLE REMOVAL
   # Remove any of these camera angle/view descriptions if found in the prompt:
   # - View angle descriptions (e.g., "front view", "back view", "side view", "left view", "right view", "3/4 view")
   # - Camera angle descriptions (e.g., "from the front", "from behind", "from the side", "from a 45-degree angle")
   # Do NOT remove pose descriptions (e.g., "hand touching face", "jumping", "sitting", "standing")
   # If no camera angle descriptions are found, return the original prompt unchanged.

2) TRIGGER HANDLING
   # Do not preserve triggers if they are a part of the text to remove. Treat triggers with extreme caution.
   # If a trigger is not mentioned in the prompt, do not create it.

3) TRIGGER WORDS TO MAINTAIN
   # If the prompt contains a [trigger] token, do not alter it. No changes to spelling, casing, or bracket notation are allowed.
   # Each trigger represents a single noun (human, fashion item, product, or style).

4) FINAL OUTPUT
Return a JSON object:
json { "modifiedPrompt": string }
"modifiedPrompt" is the new prompt if camera angle was removed, otherwise the same as input.

# -------------------------------------------------------------------------------------------
# EXAMPLES
# -------------------------------------------------------------------------------------------

# Example #1: Remove camera angle when present
# ----------------------------------------------------------------------------
# Prompt:
# A front view photo of a woman wearing a [trigger0] dress with her hand touching her face
#
# Explanation:
# Camera angle description exists ("front view"). Remove it but keep the pose description.
#
# Output:
# { "modifiedPrompt": "A photo of a woman wearing a [trigger0] dress with her hand touching her face" }

# Example #2: Keep pose description
# ----------------------------------------------------------------------------
# Prompt:
# A side view of a woman in a [trigger0] jacket jumping with arms raised
#
# Explanation:
# Remove "side view" but keep the pose description "jumping with arms raised"
#
# Output:
# { "modifiedPrompt": "A photo of a woman in a [trigger0] jacket jumping with arms raised" }

# -------------------------------------------------------------------------------------------
# INPUTS
# -------------------------------------------------------------------------------------------
# - The original prompt: ${prompt}
# - Trigger words to remove: ${triggerWordsToRemove}
# - Trigger words to maintain: ${triggerWordsToMaintain}
# -------------------------------------------------------------------------------------------
`;
}

export function removeBackgroundPromptInstructions({
  prompt,
  triggerWordsToRemove,
  triggerWordsToMaintain,
}: {
  prompt: string;
  triggerWordsToRemove?: string[];
  triggerWordsToMaintain: string[];
}) {
  return `
# These instructions define how to remove background descriptions from an existing prompt context.

Given a prompt, remove any background or setting descriptions if present. Follow these specific rules:

1) BACKGROUND REMOVAL
   # Remove any of these background/setting descriptions if found in the prompt:
   # - Location descriptions (e.g., "in a studio", "on a beach", "in front of a wall")
   # - Setting descriptions (e.g., "against a white backdrop", "in an urban setting", "on a city street")
   # - Environmental descriptions (e.g., "surrounded by trees", "with mountains in the background")
   # If no background descriptions are found, return the original prompt unchanged.

2) TRIGGER HANDLING
   # Do not preserve triggers if they are a part of the text to remove. Treat triggers with extreme caution.
   # If a trigger is not mentioned in the prompt, do not create it.

3) TRIGGER WORDS TO MAINTAIN
   # If the prompt contains a [trigger] token, do not alter it. No changes to spelling, casing, or bracket notation are allowed.
   # Each trigger represents a single noun (human, fashion item, product, or style).

4) FINAL OUTPUT
Return a JSON object:
json { "modifiedPrompt": string }
"modifiedPrompt" is the new prompt if background was removed, otherwise the same as input.

# -------------------------------------------------------------------------------------------
# EXAMPLES
# -------------------------------------------------------------------------------------------

# Example #1: Remove background when present
# ----------------------------------------------------------------------------
# Prompt:
# A photo of a woman wearing a [trigger0] dress standing in a modern art gallery with white walls and track lighting
#
# Explanation:
# Background description exists ("in a modern art gallery with white walls and track lighting"). Remove it.
#
# Output:
# { "modifiedPrompt": "A photo of a woman wearing a [trigger0] dress" }

# Example #2: No background to remove
# ----------------------------------------------------------------------------
# Prompt:
# A photo of a man wearing a [trigger0] suit looking confident
#
# Explanation:
# No background descriptions exist. Return the original prompt unchanged.
#
# Output:
# { "modifiedPrompt": "A photo of a man wearing a [trigger0] suit looking confident" }

# -------------------------------------------------------------------------------------------
# INPUTS
# -------------------------------------------------------------------------------------------
# - The original prompt: ${prompt}
# - Trigger words to remove: ${triggerWordsToRemove}
# - Trigger words to maintain: ${triggerWordsToMaintain}
# -------------------------------------------------------------------------------------------
`;
}
