import { Timestamp } from "firebase/firestore";
import { DocVisibility } from "./doc-visibility";
import { PublicTeamId } from "./public-team-id";

export interface ImageEditorProject {
  id: string;
  displayName: string;
  isDeleted: boolean;
  tags: string[];
  thumbnailStoragePath?: string;
  timeCreated: Timestamp;
  timeModified: Timestamp;
  publicTeamId: PublicTeamId;
  visibility: DocVisibility;
  sourceImageStoragePath: string;
  usedAsset: Record<string, boolean>;
}

export function isImageEditorProject(obj: unknown): obj is ImageEditorProject {
  if (typeof obj !== "object" || !obj) {
    return false;
  }
  const doc = obj as Partial<ImageEditorProject>;
  return (
    typeof doc.id === "string" &&
    typeof doc.publicTeamId === "string" &&
    typeof doc.displayName === "string" &&
    typeof doc.isDeleted === "boolean" &&
    Array.isArray(doc.tags) &&
    doc.timeCreated instanceof Timestamp &&
    doc.timeModified instanceof Timestamp &&
    typeof doc.visibility === "string"
  );
}

export enum ImageEditorPastGenerationType {
  StorageAsset = "StorageAsset",
  CustomModel = "CustomModel",
}

export interface BaseImageEditorPastGeneration {
  id: string;
  imageEditorProjectId: string;
  timeCreated: Timestamp;
  timeModified: Timestamp;
  isDeleted: boolean;
  promptJson?: string;
}

export interface StorageAssetImageEditorPastGeneration extends BaseImageEditorPastGeneration {
  type: ImageEditorPastGenerationType.StorageAsset;
  storagePath: string;
}

export interface CustomModelImageEditorPastGeneration extends BaseImageEditorPastGeneration {
  type: ImageEditorPastGenerationType.CustomModel;
  predictionId: string;
}

export type ImageEditorPastGeneration =
  | StorageAssetImageEditorPastGeneration
  | CustomModelImageEditorPastGeneration;

export enum ImageEditorEntrypointAction {
  CreateNewImageEditorProject = "CreateNewImageEditorProject",
  SuggestImageEdits = "SuggestImageEdits",
}

export function isImageEditorPastGeneration(obj: unknown): obj is ImageEditorPastGeneration {
  if (typeof obj !== "object" || !obj) {
    return false;
  }
  const doc = obj as Partial<ImageEditorPastGeneration>;
  return (
    typeof doc.id === "string" &&
    typeof doc.imageEditorProjectId === "string" &&
    doc.timeCreated instanceof Timestamp &&
    doc.timeModified instanceof Timestamp &&
    typeof doc.isDeleted === "boolean"
  );
}
