// Frontend types for demo environment
import { PublicTeamId } from "./team";

export enum DemoEnvironmentStatus {
  Enabled = "enabled",
  Disabled = "disabled",
}

export type TestUserEmail = string;

export interface DemoEnvironment {
  id: string;
  testUserEmails: Record<TestUserEmail, boolean>;
  displayName?: string;
  status: DemoEnvironmentStatus;
  publicTeamId: PublicTeamId;
}

export interface GetDemoLoginLinkArgs {
  demoEnvironmentId: string;
}

export interface GetDemoLoginLinkResponse {
  loginLink: string;
  publicTeamId: PublicTeamId;
  testUserEmail: TestUserEmail;
  message: string;
}
