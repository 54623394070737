import type { Timestamp } from "firebase/firestore";
import type { PublicTeamId } from "./public-team-id";

export interface ImageEditSuggestionPointRatio {
  type: "ratio";
  x: number;
  y: number;
}

export interface ImageEditSuggestionPoint {
  point: ImageEditSuggestionPointRatio;
  type: string;
  maskIndex: number;
  maskChannel: "R" | "G" | "B" | "A";
}

export enum ImageEditSuggestionImageType {
  Human = "human",
  StillLife = "still-life",
}

export function isImageEditSuggestionImageType(value: any): value is ImageEditSuggestionImageType {
  return Object.values(ImageEditSuggestionImageType).includes(value);
}

export interface ImageEditSuggestion {
  imageType: ImageEditSuggestionImageType;
  caption: string;
  maskStoragePath: string;
  editPoints: ImageEditSuggestionPoint[];
}

export interface ImageEditSuggestionDoc extends ImageEditSuggestion {
  publicTeamId: PublicTeamId;
  timeCreated: Timestamp;
  timeModified: Timestamp;
}

/**
 * Type guard for ImageEditSuggestionPointRatio.
 * Checks that the object has type "ratio" and numeric x and y properties.
 *
 * @param value - The value to check.
 * @returns True if value is an ImageEditSuggestionPointRatio.
 */
export function isImageEditSuggestionPointRatio(
  value: any,
): value is ImageEditSuggestionPointRatio {
  return (
    typeof value === "object" &&
    value !== null &&
    value.type === "ratio" &&
    typeof value.x === "number" &&
    typeof value.y === "number"
  );
}

/**
 * Type guard for ImageEditSuggestionPoint.
 * Checks that the object has a valid point (ImageEditSuggestionPointRatio),
 * a string type, a numeric maskIndex, and a valid maskChannel.
 *
 * @param value - The value to check.
 * @returns True if value is an ImageEditSuggestionPoint.
 */
export function isImageEditSuggestionPoint(value: any): value is ImageEditSuggestionPoint {
  return (
    typeof value === "object" &&
    value !== null &&
    isImageEditSuggestionPointRatio(value.point) &&
    typeof value.type === "string" &&
    typeof value.maskIndex === "number" &&
    ["R", "G", "B", "A"].includes(value.maskChannel)
  );
}

/**
 * Type guard for ImageEditSuggestion.
 * Checks that the object has a string maskStoragePath and an array of valid editPoints.
 *
 * @param value - The value to check.
 * @returns True if value is an ImageEditSuggestion.
 */
export function isImageEditSuggestion(value: any): value is ImageEditSuggestion {
  return (
    typeof value === "object" &&
    value !== null &&
    typeof value.maskStoragePath === "string" &&
    Array.isArray(value.editPoints) &&
    value.editPoints.every(isImageEditSuggestionPoint)
  );
}

export function isImageEditSuggestionDoc(doc: any): doc is ImageEditSuggestionDoc {
  return isImageEditSuggestion(doc);
}

export enum ImageEditSegmentationType {
  Background = "Background",
  Foreground = "Foreground",
  Hair = "Hair",
  UpperBodyClothing = "UpperBodyClothing",
  LowerBodyClothing = "LowerBodyClothing",
  Accessories = "Accessories",
  HumanBody = "HumanBody",
}

export function getDisplaySuggestionType(type: string) {
  // Return custom display names for specific segmentation types
  if (type === ImageEditSegmentationType.UpperBodyClothing) {
    return "Fashion Top";
  }
  if (type === ImageEditSegmentationType.LowerBodyClothing) {
    return "Fashion Bottom";
  }

  // Default formatting for other types
  return type.replace(/([A-Z])/g, " $1").replace(/^./, (match) => match.toUpperCase());
}

/** Arguments for requesting a new image-edit suggestion. */
export interface GetImageEditSuggestionsArgs {
  /** Firestore storage path of the original image. */
  imageStoragePath: string;
}

/** Cloud-function response shape for SuggestImageEdits. */
export type GetImageEditSuggestionsResponse =
  | {
      ok: true;
      message: string;
      suggestion: ImageEditSuggestion;
    }
  | {
      ok: false;
      message: string;
    };

/**
 * Optional type guard for GetImageEditSuggestionsResponse.
 * @param value - The value to check.
 */
export function isGetImageEditSuggestionsResponse(
  value: any,
): value is GetImageEditSuggestionsResponse {
  if (typeof value !== "object" || value === null) {
    return false;
  }
  if (typeof value.ok !== "boolean" || typeof value.message !== "string") {
    return false;
  }
  if (value.ok === true) {
    return isImageEditSuggestion(value.suggestion);
  }
  // If ok is false, we just need "message" to be a string (already checked).
  return true;
}
