// Enums

import type { CustomModelScaleConfigs, ImageSizeFalType } from "@/core/common/types";
import { CustomModelPredictionType } from "@/core/common/types/custom-model-prediction-type";
import {
  TryOnGarmentCategory,
  TryOnGarmentPhotoType,
} from "@/core/common/types/custom-model-tryon";
import { PublicTeamId } from "@/core/common/types/public-team-id";

export enum CustomModelPostProcessActionType {
  GetMaskFromPrompt = "GetMaskFromPrompt",
  FixProductDetails = "FixProductDetails",
  GenerateFromPose = "GenerateFromPose",
  RegenerateHuman = "RegenerateHuman",
  GetHumanMask = "GetHumanMask",
  UpscaleFace = "UpscaleFace",
  UpscaleCreative = "UpscaleCreative",
  ClarityUpscale = "UpscaleCreativeV2",
  InContextVariations = "ICV",
  MultiStageGeneration = "MultiStageGeneration",
  TryOn = "TryOn",
  MagicErase = "MagicErase",
  IdInpainting = "IdInpainting",
  IdTextToImage = "IdTextToImage",
  IdRemix = "IdRemix",
  ChangeColor = "ChangeColor",
  FluxControlnet = "FC",
  DepthControlnet = "DepthControlnet",
  ChangeCameraAngle = "ChangeCameraAngle",
  GenericImageEditing = "GenericImageEditing",
  ChangeHumanPose = "ChangeHumanPose",
}

export enum PoseImageType {
  OpenPose = "OpenPose",
  Image = "Image",
}

export enum OutputFormatFalType {
  JPEG = "jpeg",
  PNG = "png",
}

// Interfaces

export interface ImageSize {
  width: number;
  height: number;
}

export interface PoseCondition {
  poseImageType: PoseImageType;
  poseImageUrl: string;
  poseMaskImageUrl?: string | null;
}

export interface ModelTrainingPair {
  modelId: string;
  trainingId: string;
  scale?: number | null;
}

export interface FixDetailsFalArgsOverride {
  num_inference_steps?: number | null;
  guidance_scale?: number | null;
  num_images?: number | null;
  enable_safety_checker?: boolean | null;
  output_format?: string | null;
  strength?: number | null;
  seed?: number | null;
}

interface GenericBaseInput {
  predictionId?: string;
}

export interface GetMaskFromPromptInput extends GenericBaseInput {
  type: CustomModelPostProcessActionType.GetMaskFromPrompt;
  prompt: string;
  imageStoragePath: string;
}

export type GetMaskFromPromptResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      maskImageUrl: string;
    };

export interface GenerateFromPoseInput extends GenericBaseInput {
  type: CustomModelPostProcessActionType.GenerateFromPose;
  scaleConfigs: { [key: string]: ModelTrainingPair };
  prompt: string;
  promptJson?: string | null;
  promptEditorState?: string | null;
  seed?: number | null;
  sync_mode: boolean;
  image_size?: ImageSize | null;
  guidance_scale?: number | null;
  num_inference_steps?: number | null;
  poseConditions: PoseCondition[];
}

export interface GenerateFromPoseResponse {
  ok: boolean;
  message: string;
  outputImageUrl: string;
}

export interface FixProductDetailsInput extends GenericBaseInput {
  type: CustomModelPostProcessActionType.FixProductDetails;
  inputImageUrl: string;
  inputMaskImageUrl?: string | null;
  customModelScaleConfigs: { [key: string]: ModelTrainingPair };
  shortPrompt: string;
  fullPrompt: string;
  promptJson?: string | null;
  extraPredictionArgs: FixDetailsFalArgsOverride;
  ignoreMaskBbox?: boolean;
}

export type FixProductDetailsResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      message: string;
      prediction_id?: string | null;
    };

export interface SwapFaceInput extends GenericBaseInput {
  type: CustomModelPostProcessActionType.RegenerateHuman;
  inputImageUrl: string;
  faceImageUrl?: string;
  faceMaskImageUrl?: string;
  bodyMaskImageUrl?: string;
  scaleConfigs?: CustomModelScaleConfigs;
  prompt: string;
  promptJson?: string;
  promptEditorState?: string;
  seed?: number;
  imageSize?: ImageSizeFalType;
  guidanceScale?: number;
  numInferenceSteps?: number;
  inpaintStrength?: number;
  inpaintEndPercentage?: number;
  img2imgStrength?: number;
}

export type SwapFaceResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      message: string;
      predictionId?: string;
    };

export interface GetHumanMaskInput extends GenericBaseInput {
  type: CustomModelPostProcessActionType.GetHumanMask;
  imageStoragePath: string;
}

export type GetHumanMaskResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      message: string;
      predictionId?: string;
    };

export interface ClarityUpscaleInput extends GenericBaseInput {
  type: CustomModelPostProcessActionType.ClarityUpscale;
  inputImageUrl: string;
  preserveMaskImageUrl?: string;
  upscale_factor?: number;
  prompt?: string;
  promptJson?: string;
  promptEditorState?: string;
  customModelScaleConfigs?: CustomModelScaleConfigs;
  negative_prompt?: string;
  creativity?: number;
  resemblance?: number;
  guidance_scale?: number;
  num_inference_steps?: number;
  enable_safety_checker?: boolean;
}

export type ClarityUpscaleResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      message: string;
      predictionId?: string;
    };

// InContextVariations Interfaces

export interface InContextVariationsInput extends GenericBaseInput {
  type: CustomModelPostProcessActionType.InContextVariations;
  prompt?: string | null;
  promptJson?: string | null;
  promptEditorState?: string | null;
  modelScaleConfigs: CustomModelScaleConfigs;
  triggerWords?: string[] | null;
  sourceImageUrl: string;
  sourceImagePrompt: string;
  firstPassInpaintConditioningScale?: number | null;
  firstPassInpaintEndPercentage?: number | null;
  firstPassSkipLoras?: boolean | null;
  firstPassLoraScale?: number | null;
  cropTolerance?: number | null;
  cropOutlierPercent?: number | null;
  cropMaxBorderLengthRatio?: number | null;
  secondPassInputImageSize?: number | null;
  secondPassConditioningScale?: number | null;
  secondPassEndPercentage?: number | null;
  secondPassSkipLoras?: boolean | null;
  secondPassLoraScale?: number | null;
  poseCondition?: PoseCondition | null;
}

export type InContextVariationsResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      message: string;
      predictionId?: string;
    };

// Upscale face interfaces

export interface FaceUpscalerInput extends GenericBaseInput {
  type: CustomModelPostProcessActionType.UpscaleFace;
  prompt?: string;
  promptJson?: string | null;
  promptEditorState?: string | null;
  usedModels?: Record<string, boolean>;
  inputImageUrl: string;
  upscale?: number;
  faceUpsample?: boolean;
  backgroundEnhance?: boolean;
  codeformerFidelity?: number;
}

export type FaceUpscalerResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      message: string;
      predictionId?: string;
    };

// Multi-step generation interfaces

// The image size interface
export interface MultiStageGenerationInputImageSize {
  width: number;
  height: number;
}

// The main input interface
export interface MultiStageGenerationInput extends GenericBaseInput {
  type: CustomModelPostProcessActionType.MultiStageGeneration;
  prompt?: string | null;
  promptJson?: string | null;
  customModelScaleConfigs?: CustomModelScaleConfigs | null;
  imageSize?: MultiStageGenerationInputImageSize | null;
  maskDilationSize?: number | null;
  secondPassStrength?: number | null;
  maskImageUrl?: string | null;
  firstPassOutputImageUrl?: string | null;
  secondPassControlNetConditioningScale?: number | null;
  secondPassControlNetEndPercentage?: number | null;
  numImages?: number | null;
  publicTeamId?: PublicTeamId;
}

// The response interface
export interface MultiStageGenerationResponse {
  ok: boolean;
  message?: string;
  predictionId?: string;
}

// Tryon

export interface TryOnInput extends GenericBaseInput {
  type: CustomModelPostProcessActionType.TryOn;
  clothImageUrl: string;
  personImageUrl: string;
  garmentCategory?: TryOnGarmentCategory;
  garmentPhotoType?: TryOnGarmentPhotoType;
  seed?: number;
  prompt?: string;
  promptJson?: string | null;
  customModelScaleConfigs?: CustomModelScaleConfigs;
  runPostprocessUpscale?: boolean;
  upscaleNumInferenceSteps?: number;
  upscaleResizeLength?: number;
  upscaleConditioningScale?: number;
  upscaleEndPercentage?: number;
}

export type TryOnResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      message: string;
      predictionId?: string;
    };

// Depth Controlnet
export interface DepthControlnetInput {
  type: CustomModelPostProcessActionType.DepthControlnet;
  image: string;
  mask?: string;
  prompt?: string;
  promptJson?: string | null;
  numInferenceSteps?: number;
  numImages?: number;
  strength?: number;
  guidanceScale?: number;
  seed?: number;
  conditioningScale?: number;
  startPercentage?: number;
  endPercentage?: number;
  modelScaleConfigs?: CustomModelScaleConfigs;
  publicTeamId?: string | null;
}

export type DepthControlnetResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      message: string;
      predictionId?: string;
    };

// Magic erase

export enum FalEraserMaskType {
  MANUAL = "manual",
  AUTOMATIC = "automatic",
}

export interface MagicEraseInput extends GenericBaseInput {
  type: CustomModelPostProcessActionType.MagicErase;
  imageUrl?: string;
  maskImageUrl?: string;
  maskType?: FalEraserMaskType | null;
  publicTeamId?: PublicTeamId;
}
export type MagicEraseResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      message: string;
      predictionId?: string;
    };

export enum IdeogramResolution {
  NONE = "None",
  RES_512x1536 = "512x1536",
  RES_576x1408 = "576x1408",
  RES_576x1472 = "576x1472",
  RES_576x1536 = "576x1536",
  RES_640x1024 = "640x1024",
  RES_640x1344 = "640x1344",
  RES_640x1408 = "640x1408",
  RES_640x1472 = "640x1472",
  RES_640x1536 = "640x1536",
  RES_704x1152 = "704x1152",
  RES_704x1216 = "704x1216",
  RES_704x1280 = "704x1280",
  RES_704x1344 = "704x1344",
  RES_704x1408 = "704x1408",
  RES_704x1472 = "704x1472",
  RES_720x1280 = "720x1280",
  RES_736x1312 = "736x1312",
  RES_768x1024 = "768x1024",
  RES_768x1088 = "768x1088",
  RES_768x1152 = "768x1152",
  RES_768x1216 = "768x1216",
  RES_768x1232 = "768x1232",
  RES_768x1280 = "768x1280",
  RES_768x1344 = "768x1344",
  RES_832x960 = "832x960",
  RES_832x1024 = "832x1024",
  RES_832x1088 = "832x1088",
  RES_832x1152 = "832x1152",
  RES_832x1216 = "832x1216",
  RES_832x1248 = "832x1248",
  RES_864x1152 = "864x1152",
  RES_896x960 = "896x960",
  RES_896x1024 = "896x1024",
  RES_896x1088 = "896x1088",
  RES_896x1120 = "896x1120",
  RES_896x1152 = "896x1152",
  RES_960x832 = "960x832",
  RES_960x896 = "960x896",
  RES_960x1024 = "960x1024",
  RES_960x1088 = "960x1088",
  RES_1024x640 = "1024x640",
  RES_1024x768 = "1024x768",
  RES_1024x832 = "1024x832",
  RES_1024x896 = "1024x896",
  RES_1024x960 = "1024x960",
  RES_1024x1024 = "1024x1024",
  RES_1088x768 = "1088x768",
  RES_1088x832 = "1088x832",
  RES_1088x896 = "1088x896",
  RES_1088x960 = "1088x960",
  RES_1120x896 = "1120x896",
  RES_1152x704 = "1152x704",
  RES_1152x768 = "1152x768",
  RES_1152x832 = "1152x832",
  RES_1152x864 = "1152x864",
  RES_1152x896 = "1152x896",
  RES_1216x704 = "1216x704",
  RES_1216x768 = "1216x768",
  RES_1216x832 = "1216x832",
  RES_1232x768 = "1232x768",
  RES_1248x832 = "1248x832",
  RES_1280x704 = "1280x704",
  RES_1280x720 = "1280x720",
  RES_1280x768 = "1280x768",
  RES_1280x800 = "1280x800",
  RES_1312x736 = "1312x736",
  RES_1344x640 = "1344x640",
  RES_1344x704 = "1344x704",
  RES_1344x768 = "1344x768",
  RES_1408x576 = "1408x576",
  RES_1408x640 = "1408x640",
  RES_1408x704 = "1408x704",
  RES_1472x576 = "1472x576",
  RES_1472x640 = "1472x640",
  RES_1472x704 = "1472x704",
  RES_1536x512 = "1536x512",
  RES_1536x576 = "1536x576",
  RES_1536x640 = "1536x640",
}

export function getIdeogramResolutionFromWidthHeight({
  width,
  height,
}: {
  width: number;
  height: number;
}) {
  // Common exact matches
  const exactMatches: Record<string, IdeogramResolution> = {
    "1024x1024": IdeogramResolution.RES_1024x1024,
    "768x1344": IdeogramResolution.RES_768x1344,
    "832x1216": IdeogramResolution.RES_832x1216,
  };

  const dimensionKey = `${width}x${height}`;
  if (exactMatches[dimensionKey]) {
    return exactMatches[dimensionKey];
  }

  // Find closest match by comparing area and aspect ratio
  const targetArea = width * height;
  const targetRatio = width / height;

  const resolutions = Object.values(IdeogramResolution)
    .filter((res) => res !== IdeogramResolution.NONE)
    .map((res) => {
      const [w, h] = res.replace("RES_", "").split("x").map(Number);
      return { resolution: res, width: w, height: h, area: w * h, ratio: w / h };
    });

  return resolutions.sort((a, b) => {
    // Weight aspect ratio more heavily than area
    const aRatioDiff = Math.abs(a.ratio - targetRatio);
    const bRatioDiff = Math.abs(b.ratio - targetRatio);
    const aAreaDiff = Math.abs(a.area - targetArea) / targetArea;
    const bAreaDiff = Math.abs(b.area - targetArea) / targetArea;

    const aScore = aRatioDiff * 2 + aAreaDiff;
    const bScore = bRatioDiff * 2 + bAreaDiff;

    return aScore - bScore;
  })[0].resolution;
}

export enum IdeogramStyleType {
  NONE = "None",
  AUTO = "Auto",
  GENERAL = "General",
  REALISTIC = "Realistic",
  DESIGN = "Design",
  RENDER_3D = "Render 3D",
  ANIME = "Anime",
}

export enum IdeogramAspectRatio {
  ONE_ONE = "1:1",
  SIXTEEN_NINE = "16:9",
  NINE_SIXTEEN = "9:16",
  FOUR_THREE = "4:3",
  THREE_FOUR = "3:4",
  THREE_TWO = "3:2",
  TWO_THREE = "2:3",
  SIXTEEN_TEN = "16:10",
  TEN_SIXTEEN = "10:16",
  THREE_ONE = "3:1",
  ONE_THREE = "1:3",
}

export const getIdeogramAspectRatioFromWidthHeight = ({
  width,
  height,
}: {
  width: number;
  height: number;
}): IdeogramAspectRatio => {
  const ratio = width / height;
  const ratioMapping: [number, IdeogramAspectRatio][] = [
    [1, IdeogramAspectRatio.ONE_ONE],
    [16 / 9, IdeogramAspectRatio.SIXTEEN_NINE],
    [9 / 16, IdeogramAspectRatio.NINE_SIXTEEN],
    [4 / 3, IdeogramAspectRatio.FOUR_THREE],
    [3 / 4, IdeogramAspectRatio.THREE_FOUR],
    [3 / 2, IdeogramAspectRatio.THREE_TWO],
    [2 / 3, IdeogramAspectRatio.TWO_THREE],
    [16 / 10, IdeogramAspectRatio.SIXTEEN_TEN],
    [10 / 16, IdeogramAspectRatio.TEN_SIXTEEN],
    [3 / 1, IdeogramAspectRatio.THREE_ONE],
    [1 / 3, IdeogramAspectRatio.ONE_THREE],
  ];

  return ratioMapping.reduce(
    (closest, [value, aspect]) =>
      Math.abs(ratio - value) < Math.abs(ratio - closest[0]) ? [value, aspect] : closest,
    [0, IdeogramAspectRatio.ONE_ONE],
  )[1];
};

export enum IdeogramMagicPromptOption {
  AUTO = "Auto",
  ON = "On",
  OFF = "Off",
}

export interface IdeogramText2ImageRequest extends GenericBaseInput {
  type: CustomModelPostProcessActionType.IdTextToImage;
  prompt: string;
  promptJson?: string | null;
  negativePrompt?: string | null;
  seed?: number | null;
  styleType?: IdeogramStyleType;
  resolution?: IdeogramResolution;
  aspectRatio?: IdeogramAspectRatio;
  magicPromptOption?: IdeogramMagicPromptOption;
  publicTeamId?: string | null;
  numOutputs?: number | null;
}

export interface IdeogramText2ImageResponse {
  ok: boolean;
  message: string;
  predictionId?: string;
}

export interface IdeogramInpaintingRequest extends GenericBaseInput {
  type: CustomModelPostProcessActionType.IdInpainting;
  prompt?: string | null;
  promptJson?: string | null;
  imageUrl: string;
  maskUrl?: string | null;
  negativePrompt?: string | null;
  seed?: number | null;
  styleType?: IdeogramStyleType;
  resolution?: IdeogramResolution;
  magicPromptOption?: IdeogramMagicPromptOption;
  publicTeamId?: string | null;
  maskDilationPixels: number | null;
  numOutputs?: number;
}

export interface IdeogramInpaintingResponse {
  ok: boolean;
  message: string;
  predictionId?: string;
}

export enum IdeogramRemixAspectRatio {
  R_10_16 = "10:16",
  R_16_10 = "16:10",
  R_9_16 = "9:16",
  R_16_9 = "16:9",
  R_4_3 = "4:3",
  R_3_4 = "3:4",
  R_1_1 = "1:1",
  R_1_3 = "1:3",
  R_3_1 = "3:1",
  R_3_2 = "3:2",
  R_2_3 = "2:3",
}

function computeNumericRatio(ratioStr: string): number {
  const [w, h] = ratioStr.split(":").map(Number);
  return w / h;
}

const numericAspectRatios: Record<IdeogramRemixAspectRatio, number> = Object.values(
  IdeogramRemixAspectRatio,
).reduce(
  (acc, ratioStr) => {
    acc[ratioStr as IdeogramRemixAspectRatio] = computeNumericRatio(ratioStr);
    return acc;
  },
  {} as Record<IdeogramRemixAspectRatio, number>,
);

export function findClosestAspectRatio({
  width,
  height,
}: {
  width: number;
  height: number;
}): IdeogramRemixAspectRatio {
  const target = width / height;
  return Object.entries(numericAspectRatios).reduce(
    (closest, [key, value]) => {
      const diff = Math.abs(value - target);
      return diff < closest.diff ? { ratio: key as IdeogramRemixAspectRatio, diff } : closest;
    },
    { ratio: IdeogramRemixAspectRatio.R_1_1, diff: Infinity },
  ).ratio;
}

export enum IdeogramRemixStyle {
  AUTO = "auto",
  GENERAL = "general",
  REALISTIC = "realistic",
  DESIGN = "design",
  RENDER_3D = "render_3D",
  ANIME = "anime",
}

export interface IdeogramRemixRequest extends GenericBaseInput {
  type: CustomModelPostProcessActionType.IdRemix;
  prompt: string;
  promptJson?: string;
  imageUrl: string;
  aspectRatio?: IdeogramRemixAspectRatio;
  strength?: number;
  expandPrompt?: boolean;
  seed?: number;
  style?: IdeogramRemixStyle;
  publicTeamId?: string | null;
  numOutputs?: number;
}

export interface IdeogramRemixResponse {
  ok: boolean;
  message: string;
  predictionId?: string;
}

export interface ChangeColorRequest extends GenericBaseInput {
  // Required fields
  image: string;
  mask?: string | null;
  changeBackgroundColorOnly?: boolean | null;
  targetColor: [number, number, number] | number[]; // RGB tuple or array

  // Basic options
  justTint?: boolean; // only tint, don't run through AI
  prompt?: string;
  promptJson?: string;
  promptPrefix?: string;

  // custom model stuff
  modelScaleConfigs?: CustomModelScaleConfigs;

  // Generation parameters
  numInferenceSteps?: number; // default 25, range 1-35
  guidanceScale?: number; // default 3.5, range 0-20
  strength?: number; // default 0.8, range 0-1

  maskMaxFilterSize?: number;
  maskBlurFilterSize?: number;

  // Controlnet parameters
  controlnetMaskThreshold?: number; // default 0.5, range 0-1
  controlnetConditioningScale?: number; // default 0.8, range 0-1
  controlnetStartPercentage?: number; // default 0.0, range 0-1
  controlnetStopPercentage?: number; // default 1.0, range 0-1

  // Output options
  outputFormat?: "png" | "jpeg"; // default "png"

  type: CustomModelPostProcessActionType.ChangeColor;
  publicTeamId?: string | null;
  runPostProcess?: boolean;
}

export interface ChangeColorResponse {
  ok: boolean;
  message: string;
  predictionId?: string;
}

export enum ControlnetModel {
  CANNY = "CANNY",
  DEPTH = "DEPTH",
  INPAINT = "INPAINT",
  UPSCALING = "UPSCALING",
}

export enum FluxControlnetOperation {
  Text2Img = "text2img",
  Image2Img = "image2image",
  Inpainting = "inpainting",
}

// Base interfaces

export interface BaseFluxControlNetItem {
  control_image_url: string;
  mask_image_url?: string | null;
  mask_threshold?: number;
  conditioning_scale?: number;
  start_percentage?: number;
  end_percentage?: number;
}

// Specific controlnet item interfaces

export interface FluxControlNetItemCanny extends BaseFluxControlNetItem {
  model: ControlnetModel.CANNY;
  lowThreshold?: null;
  highThreshold?: null;
}

export interface FluxControlNetItemDepth extends BaseFluxControlNetItem {
  model: ControlnetModel.DEPTH;
}

export interface FluxControlNetItemInpaint extends BaseFluxControlNetItem {
  model: ControlnetModel.INPAINT;
}

export interface FluxControlNetItemUpscaling extends BaseFluxControlNetItem {
  model: ControlnetModel.UPSCALING;
  downsampleLength?: number;
}

// Union type for all controlnet items
export type FluxControlNetItem =
  | FluxControlNetItemCanny
  | FluxControlNetItemDepth
  | FluxControlNetItemInpaint
  | FluxControlNetItemUpscaling;

// Main input interface
export interface FluxControlnetInput {
  type: CustomModelPostProcessActionType.FluxControlnet;
  operation: FluxControlnetOperation;
  prompt: string;
  promptJson?: string | null;
  numInferenceSteps?: number;
  guidanceScale?: number;
  seed?: number | null;
  strength?: number; // for img2img or inpainting
  imageUrl?: string; // for img2img or inpainting
  maskUrl?: string; // for inpainting
  syncMode?: boolean;
  numImages?: number;
  enableSafetyChecker?: boolean;
  outputFormat?: string;
  // For loading LoRAs
  modelScaleConfigs?: Record<string, Record<string, any>>;
  publicTeamId?: string | null;
  predictionId?: string | null;

  // Possibly multiple controlnets:
  controlnets?: FluxControlNetItem[];
}

// Response interface
export interface FluxControlnetResponse {
  ok: boolean;
  message?: string;
  predictionId?: string;
}

interface IdeogramInContextReferenceImage {
  imageStoragePath: string;
  shouldRemoveBackground: boolean | null;
  marginRatio: number | null;
}

interface IdeogramInContextInput extends GenericBaseInput {
  prompt: string;
  promptJson?: string;
  negativePrompt?: string;
  publicTeamId?: string;
  maskImageUrl?: string;
  referenceImages: IdeogramInContextReferenceImage[];
  referenceImageCaption: string;
  styleType: IdeogramStyleType;
  seed: number;
  imageSize?: ImageSize;

  // relevant for upscale
  modelScaleConfigs?: CustomModelScaleConfigs;
  upscale_control_image_resize_factor?: number;
  upscale_prompt?: string;
  upscale_controlnet_conditioning_scale?: number;
  upscale_controlnet_end_percentage?: number;
  upscale_controlnet_start_percentage?: number;
  upscale_img2img_strength?: number;
  upscale_num_inference_steps?: number;
  upscale_seed?: number;
}

interface IdeogramInContextResponse {
  ok: boolean;
  message: string;
  predictionId?: string;
}

// Type Unions

export type CustomModelPostProcessInput =
  | GetMaskFromPromptInput
  | FixProductDetailsInput
  | GenerateFromPoseInput
  | SwapFaceInput
  | ClarityUpscaleInput
  | FaceUpscalerInput
  | InContextVariationsInput
  | MultiStageGenerationInput
  | TryOnInput
  | MagicEraseInput
  | IdeogramInpaintingRequest
  | IdeogramText2ImageRequest
  | IdeogramRemixRequest
  | ChangeColorRequest
  | FluxControlnetInput
  | IdeogramInContextInput
  | DepthControlnetInput;

export type CustomModelPostProcessResponse =
  | GetMaskFromPromptResponse
  | FixProductDetailsResponse
  | GenerateFromPoseResponse
  | SwapFaceResponse
  | ClarityUpscaleResponse
  | FaceUpscalerResponse
  | InContextVariationsResponse
  | MultiStageGenerationResponse
  | TryOnResponse
  | MagicEraseResponse
  | IdeogramInpaintingResponse
  | IdeogramText2ImageResponse
  | IdeogramRemixResponse
  | ChangeColorResponse
  | FluxControlnetResponse
  | IdeogramInContextResponse
  | DepthControlnetResponse;

export interface ChangeCameraAngleInput extends GenericBaseInput {
  type: CustomModelPostProcessActionType.ChangeCameraAngle;
  imageUrl: string; // main image path
  targetCameraAngle: string; // "45-degree side view" etc
  publicTeamId?: string;
  numOutputs?: number;
}

export interface ChangeCameraAngleResponse {
  ok: boolean;
  message: string;
  predictionId?: string;
}

export interface ChangePoseInput extends GenericBaseInput {
  type: CustomModelPostProcessActionType.ChangeHumanPose;
  imageUrl: string;
  targetPoseUrl?: string;
  targetPoseDescription?: string;
  publicTeamId?: string;
  numOutputs?: number;
}

export interface ChangePoseResponse {
  ok: boolean;
  message: string;
  predictionId?: string;
}

export type CustomModelPostProcessPythonInput = ChangePoseInput | ChangeCameraAngleInput;

export type CustomModelPostProcessPythonResponse = ChangePoseResponse | ChangeCameraAngleResponse;

export interface ExecuteMultiStepNodeWorkflowArgs {
  uid: string;
  publicTeamId: PublicTeamId;
  graphPayloadJson: string;
  promptJson: string;
  predictionId: string;
  predictionType: CustomModelPredictionType;
  usedModels: Record<string, boolean>;
  numExecutions: number;
  useBulkGeneration?: boolean;
}

export interface ExecuteMultiStepNodeWorkflowResponseOk {
  ok: true;
  message: string;
  predictionId: string;
  bulkGenerationId?: string;
  workflowIds: string[];
}

export interface ExecuteMultiStepNodeWorkflowResponseError {
  ok: false;
  message: string;
}

export type ExecuteMultiStepNodeWorkflowResponse =
  | ExecuteMultiStepNodeWorkflowResponseOk
  | ExecuteMultiStepNodeWorkflowResponseError;
