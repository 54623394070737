import { UsedUserProjectType, UserProjectType } from "@/core/common/types";
import { LeftPanelItemType } from "./editor-options";

export const LEFT_PANEL_WIDTH = 360;

export const DASHBOARD_LEFT_PANEL_WIDTH_PX = 268;

export const LEFT_PANEL_DROPDOWN_WIDTH = LEFT_PANEL_WIDTH - 35;

const StandardProjectPanelList: LeftPanelItemType[] = [
  "Assets",
  "Generate",
  "Elements",
  "Edit",
  "GenerateVideo",
];

export const projectLeftPanelListItems: Record<UsedUserProjectType, LeftPanelItemType[]> = {
  [UserProjectType.ProductPhotography]: StandardProjectPanelList,
  [UserProjectType.Accessories]: StandardProjectPanelList,
  [UserProjectType.Beauty]: StandardProjectPanelList,
  [UserProjectType.CPG]: StandardProjectPanelList,
  [UserProjectType.Food]: StandardProjectPanelList,
  [UserProjectType.Furniture]: StandardProjectPanelList,
  [UserProjectType.Homegoods]: StandardProjectPanelList,
  [UserProjectType.Staging]: StandardProjectPanelList,
  [UserProjectType.Humans]: StandardProjectPanelList,
  [UserProjectType.TryOn]: [
    "Assets",
    "Generate",
    "Elements",
    "Edit",
    "TryOnUploadCloth",
    "GenerateVideo",
  ],
  [UserProjectType.Fashion]: [
    "Assets",
    "Generate",
    "Elements",
    "Edit",
    "TryOnUploadCloth",
    "GenerateVideo",
  ],
};
