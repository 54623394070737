import EventEmitter from "events";
import type { EditorEventHandler } from "./types";

export class EditorEventEmitter extends EventEmitter {
  public destroy() {
    super.removeAllListeners();
  }

  public emit<T extends EditorEventHandler>(name: T["type"], ...args: Parameters<T["handler"]>) {
    return super.emit(name, ...args);
  }

  public on<T extends EditorEventHandler>(name: T["type"], handler: T["handler"]) {
    return super.on(name, handler);
  }

  public once<T extends EditorEventHandler>(name: T["type"], handler: T["handler"]) {
    return super.once(name, handler);
  }

  public off<T extends EditorEventHandler>(name: T["type"], handler: T["handler"]) {
    return super.off(name, handler);
  }
}
