import { classNames } from "@/core/utils/classname-utils";
import React from "react";

export function ContentDividerHorizontal({
  className = "",
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div className={classNames("w-full flex flex-row items-center", className)}>
      <FlexDividerHorizontal />
      {children}
      <FlexDividerHorizontal />
    </div>
  );
}

export function OrDividerHorizontal({ className = "" }: { className?: string }) {
  return (
    <ContentDividerHorizontal className={className}>
      <span className="mx-6">or</span>
    </ContentDividerHorizontal>
  );
}

export function FlexDividerHorizontal() {
  return <div className="flex-1 h-px bg-zinc-800"></div>;
}

export function BaseDivider() {
  return (
    <div className="w-full py-2">
      <div className="h-px bg-zinc-800" />
    </div>
  );
}
