import { UserAssetTag, UserAssetType } from "@/core/common/types/user-asset-type";
import { isDataURL, isValidHttpsUrl } from "@/core/utils/string-utils";
import { Functions, HttpsCallable, httpsCallable } from "firebase/functions";

import { PublicTeamId } from "@/core/common/types/team";
import { convertImageToPNG } from "@/core/utils/image-utils";
import { debugError } from "@/core/utils/print-utilts";
import * as dJSON from "dirty-json";

export type UserAssetStorageExtensionHeaders = {
  "x-goog-meta-owner_id": string;
  "x-goog-meta-asset_id"?: string;
  "x-goog-meta-project_id"?: string;
  "x-goog-meta-asset_type": UserAssetType;
  "x-goog-meta-tags": string;
  "x-goog-meta-public_team_id"?: PublicTeamId;
};

export type GenerateUserAssetUploadUrlArgs = {
  contentType: string;
  extensionHeaders?: Record<string, string>;
  assetType: UserAssetType;
  tags?: UserAssetTag[];
  publicTeamId: PublicTeamId;
  projectId?: string;
};

export type GenerateUserAssetUploadUrlResponse = {
  signedUrl?: string;
  assetId?: string;
  extensionHeaders?: Partial<UserAssetStorageExtensionHeaders>;
};

export type GenerateUserAssetUploadUrlFunction = HttpsCallable<
  GenerateUserAssetUploadUrlArgs,
  GenerateUserAssetUploadUrlResponse
>;

export function createGenerateUserAssetUploadUrlFunction({
  firebaseFunctions,
}: {
  firebaseFunctions: Functions;
}) {
  return httpsCallable<GenerateUserAssetUploadUrlArgs, GenerateUserAssetUploadUrlResponse>(
    firebaseFunctions,
    "generateUserAssetUploadUrlColabJuly24V2_v2",
  );
}

/**
 * Uploads a string to cloud storage using a signed URL and sets custom metadata.
 *
 * @param signedUrl The signed URL for the upload.
 * @param content The string content to upload.
 * @param metadata An object containing metadata key-value pairs.
 * @returns A promise that resolves with the HTTP response.
 */
async function uploadDataWithMetadata(
  signedUrl: string,
  body: string | File | Blob,
  contentType: string,
  metadata: Record<string, string>,
): Promise<Response> {
  // Convert data URI to Blob if the body is a string
  body = typeof body === "string" ? await getUploadDataFromString(body) : body;

  try {
    const response = await fetch(signedUrl, {
      method: "PUT",
      headers: {
        ...metadata,
      },
      body,
    });

    if (!response.ok) {
      throw new Error(`Failed to upload content. Status: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error("Error uploading string with metadata:", error);
    throw error;
  }
}

export async function uploadUserAssetFileToStorage({
  data,
  generateAssetUploadUrl,
  assetType,
  tags,
  publicTeamId,
}: Omit<GenerateUserAssetUploadUrlArgs, "contentType"> & {
  data: File | Blob;
  generateAssetUploadUrl: GenerateUserAssetUploadUrlFunction;
}) {
  try {
    // Convert the image to PNG and resize
    const convertedData = await convertImageToPNG({
      image: data,
      targetMaxLength: 1024,
    });
    const contentType = "image/png";

    const {
      assetId,
      signedUrl,
      extensionHeaders = {},
    } = (
      await generateAssetUploadUrl({
        assetType,
        contentType,
        tags,
        publicTeamId,
      })
    ).data;

    if (!signedUrl || !assetId) {
      return;
    }

    const storagePath = getStoragePathFromSignedUrl(signedUrl);

    if (!storagePath) {
      return undefined;
    }

    const headers = Object.fromEntries(
      Object.entries(extensionHeaders).filter(([, value]) => value != null),
    ) as Record<string, string>;

    await uploadDataWithMetadata(signedUrl, convertedData, contentType, headers);

    return storagePath;
  } catch (error) {
    return undefined;
  }
}

export function getStoragePathFromSignedUrl(signedUrl: string): string {
  try {
    const url = new URL(signedUrl);
    url.search = "";
    const prefix = `/${import.meta.env.VITE_FIREBASE_STORAGE_BUCKET}/`;
    const pathname = url.pathname;

    if (pathname.startsWith(prefix)) {
      return pathname.slice(prefix.length);
    }
    return pathname;
  } catch (error) {
    console.error("Invalid URL:", error);
    return ""; // Return an empty string in case of an invalid URL
  }
}

export async function getUploadDataFromString(body: string) {
  if (isDataURL(body) || isValidHttpsUrl(body)) {
    const response = await fetch(body);
    if (!response.ok) {
      throw new Error(`Failed to fetch data from URI. Status: ${response.status}`);
    }
    return await response.blob();
  }
  return body;
}

export interface UploadAssetDataWithMetadataArgs {
  signedUrl: string;
  data: string | File | Blob;
  contentType: string;
  metadata: Record<string, string>;
}

export async function uploadAssetDataWithMetadata({
  signedUrl,
  data: body,
  contentType,
  metadata,
}: UploadAssetDataWithMetadataArgs) {
  if (!body) {
    throw new Error("Upload body is empty or undefined");
  }

  try {
    let uploadBody = body;
    if (typeof body === "string" && !isDataURL(body) && !isValidHttpsUrl(body)) {
      uploadBody = new Blob([body], { type: contentType });
    }

    const response = await fetch(signedUrl, {
      method: "PUT",
      headers: {
        ...metadata,
      },
      body: uploadBody,
      credentials: "omit",
      mode: "cors",
    });

    if (!response.ok) {
      const errorText = await response.text();
      debugError(`Upload failed with status: ${response.status} - ${errorText}`);
      throw new Error(`Upload failed with status: ${response.status} - ${errorText}`);
    }

    return response;
  } catch (error) {
    console.error("Error uploading data with metadata:", error);
    throw error;
  }
}

function cleanupJsonTicks(input: string): string {
  return input
    .replace(/```json\s*|```/g, "") // Remove ```json and ```
    .trim(); // Trim any surrounding whitespace and newlines
}

export function cleanupQuotes(input: string): string {
  return input.replace(/["'“”‘’]/g, "");
}

export function parseDirtyJSON(jsonString: string) {
  try {
    jsonString = cleanupJsonTicks(jsonString);

    return dJSON.parse(jsonString);
  } catch (error) {
    console.error("Error parsing dirty json string: ", error);
  }
  return null;
}
