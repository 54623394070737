import { displayUiMessage } from "@/components/utils/display-message";
import {
  GenerateSamEmbeddingInput,
  GenerateSamEmbeddingResponse,
  GetSegmentationMaskTypeInput,
  GetSegmentationMaskTypeResponse,
  isSegmentationMaskType,
  SegmentationMaskType,
} from "@/core/common/types/segmentation";
import { debugError } from "@/core/utils/print-utilts";
import { SchemaType } from "@google/generative-ai";
import { Firestore } from "firebase/firestore";
import { Functions, httpsCallable, HttpsCallable } from "firebase/functions";
import { chatWithImages } from "./chat-with-images-utils";
import { GENERATE_SAM_EMBEDDING } from "./firebase-function-name";

export class SegmentationManager {
  private firestore: Firestore;
  private firebaseFunctions: Functions;

  private generateSamEmbeddingCallable: HttpsCallable<
    GenerateSamEmbeddingInput,
    GenerateSamEmbeddingResponse
  >;

  constructor({
    firestore,
    firebaseFunctions,
  }: {
    firestore: Firestore;
    firebaseFunctions: Functions;
  }) {
    this.firestore = firestore;
    this.firebaseFunctions = firebaseFunctions;
    this.generateSamEmbeddingCallable = httpsCallable(firebaseFunctions, GENERATE_SAM_EMBEDDING);
  }

  async generateSamEmbedding(
    input: GenerateSamEmbeddingInput,
  ): Promise<GenerateSamEmbeddingResponse> {
    try {
      const response = await this.generateSamEmbeddingCallable(input);
      return response.data;
    } catch (error) {
      displayUiMessage(`Error running segmentation: ${error}`, "error");
      return {
        ok: false,
        message: "Error running segmentation.",
      };
    }
  }

  async getSegmentationMaskType({
    imageDataUrl,
  }: GetSegmentationMaskTypeInput): Promise<GetSegmentationMaskTypeResponse> {
    try {
      const response = await chatWithImages({
        firebaseFunctions: this.firebaseFunctions,
        request: {
          imageUrls: [imageDataUrl],
          llmPrompt: `Categorize the masked green area in this image. Find the closest category maskType in this list: ${Object.values(SegmentationMaskType)}. Then provide a 3-5 words caption for the maksed green area. DO NOT mention the word green or describe the color.`,
          generationConfig: {
            responseMimeType: "application/json",
            responseSchema: {
              description: "Mask type response.",
              type: "object" as SchemaType,
              properties: {
                maskType: {
                  type: "string" as SchemaType,
                  description: `The mask type. Optional values are: ${Object.values(SegmentationMaskType)}`,
                  nullable: false,
                },
                caption: {
                  type: "string" as SchemaType,
                  description: `The caption for the masked area.`,
                  nullable: false,
                },
              },
              required: ["maskType", "caption"],
            },
          },
        },
      });

      if (!response) {
        return {
          ok: false,
          maskType: SegmentationMaskType.Unknown,
          caption: "",
        };
      }

      const responseBody = JSON.parse(response);
      const maskType = responseBody?.maskType;

      if (isSegmentationMaskType(maskType)) {
        return {
          ok: false,
          maskType,
          caption: String(responseBody?.caption ?? ""),
        };
      }

      return {
        ok: true,
        maskType: SegmentationMaskType.Unknown,
      };
    } catch (error) {
      debugError("Error getting segmentation mask type: ", error);
      return {
        ok: false,
        maskType: SegmentationMaskType.Unknown,
      };
    }
  }
}
