import { editorContextStore } from "@/contexts/editor-context";
import { EditorAssetContentType, UserAssetType } from "@/core/common/types";
import { StaticImageElementColorDisplayType } from "@/core/common/types/elements";
import { ChannelRGBA, ImageFormat } from "@/core/common/types/image";
import { isDataURL } from "@/core/utils/string-utils";
import { fabric } from "fabric";
import mime from "mime";
import Pica from "pica";
import { AddAssetExtraArgs } from "../controllers/assets";
import { loadImageElementFromBlob, loadImageElementFromURL } from "./image-loader";
import { debugError } from "./print-utilts";
import { isStaticImageObjectHed } from "./type-guards";

const pica = new Pica();

async function resizeImageTraditional(
  from: HTMLCanvasElement | HTMLImageElement | ImageBitmap | File | Blob,
  to: HTMLCanvasElement,
) {
  try {
    const ctx = to.getContext("2d");
    if (!ctx) {
      return to;
    }
    if (from instanceof Blob) {
      from = await loadImageElementFromBlob(from);
    }
    ctx.drawImage(from, 0, 0, from.width, from.height, 0, 0, to.width, to.height);
  } catch (error) {
    console.error(error);
  }
  return to;
}

async function resizeImageInternal(
  from: HTMLCanvasElement | HTMLImageElement | ImageBitmap | File | Blob,
  to: HTMLCanvasElement,
) {
  try {
    // @ts-expect-error - pica from does not accept Blob type
    return await pica.resize(from, to);
  } catch (error) {
    console.error(error);
  }
  return resizeImageTraditional(from, to);
}

export async function resizeImageCanvasElement({
  from,
  to,
  width,
  height,
}: {
  from: HTMLCanvasElement | HTMLImageElement | ImageBitmap | File | Blob;
  to?: HTMLCanvasElement;
  width?: number;
  height?: number;
}) {
  if (!to) {
    if (!width || !height) {
      return;
    }
    to = document.createElement("canvas");
    to.width = width;
    to.height = height;
  }
  return await resizeImageInternal(from, to);
}

function getCanvasFromImageElement(
  imageElement: HTMLImageElement,
  canvas?: HTMLCanvasElement,
): HTMLCanvasElement | undefined {
  // Create a canvas element
  canvas = canvas || document.createElement("canvas");
  canvas.width = imageElement.naturalWidth;
  canvas.height = imageElement.naturalHeight;

  // Draw the image onto the canvas
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    return undefined;
  }
  ctx.drawImage(imageElement, 0, 0);

  return canvas;
}

function getDataUrlFromImageElementInternal({
  from,
  to,
}: {
  from: HTMLCanvasElement | HTMLImageElement;
  to: HTMLCanvasElement;
}) {
  const ctx = to.getContext("2d");
  if (!ctx) {
    return undefined;
  }
  to.width = from.width;
  to.height = from.height;
  ctx.drawImage(from, 0, 0);
  return to.toDataURL("image/png");
}

export async function getDataUrlFromImageElement({
  from,
  to,
  width,
  height,
}: {
  from: HTMLCanvasElement | HTMLImageElement;
  to?: HTMLCanvasElement;
  width?: number;
  height?: number;
}) {
  width = width || from.width;
  height = height || from.height;

  // Check if the source of the image element is already a valid url
  if (
    from instanceof HTMLImageElement &&
    width === from.width &&
    height === from.height &&
    isDataURL(from.src)
  ) {
    return from.src;
  }

  to = to || document.createElement("canvas");
  if (!width || !height || (width === from.width && height === from.height)) {
    return getDataUrlFromImageElementInternal({
      from,
      to,
    });
  }
  to.width = width;
  to.height = height;
  return (await resizeImageInternal(from, to)).toDataURL("image/png");
}

export async function getDataUrlFromImageElementResized({
  image,
  targetLength,
}: {
  image: HTMLCanvasElement | HTMLImageElement;
  targetLength: number;
}) {
  const width = image.width;
  const height = image.height;
  const scale = targetLength / Math.min(width, height);
  return await getDataUrlFromImageElement({
    from: image,
    width: Math.round(scale * width),
    height: Math.round(scale * height),
  });
}

export async function getRawDataUrlFromImageObject({
  object: imageObject,
  width,
  height,
}: {
  object: fabric.Image | fabric.StaticImage;
  width?: number;
  height?: number;
}) {
  const imageElement = imageObject?.getElement();
  if (!(imageElement instanceof HTMLImageElement)) {
    return;
  }
  return await getDataUrlFromImageElement({
    from: imageElement,
    width,
    height,
  });
}

export function getImageDataUrlSize(url: string) {
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    if (!isDataURL(url)) {
      return reject("No valid data url");
    }
    const i = new Image();
    i.onload = function () {
      resolve({
        width: i.width,
        height: i.height,
      });
    };
    i.onerror = reject;
    i.src = url;
  });
}

function setImageObjectSrcInternal(object: fabric.StaticImage, imageUrl: string) {
  return new Promise((resolve) => {
    object.setSrc(imageUrl, resolve);
  });
}

export async function setImageObjectSrc({
  object,
  imageUrl,
  onError,
  ...addAssetArgs
}: AddAssetExtraArgs & {
  assetType: UserAssetType;
  object: fabric.StaticImage;
  imageUrl?: string | null;
  onError?: (error: Error) => void;
}) {
  if (!object || !imageUrl) {
    onError?.(new Error("Image url is invalid."));
    return;
  }
  const { editor, backend } = editorContextStore.getState();
  if (!editor || !backend) {
    return;
  }

  object.setSrc(imageUrl, () => {
    editor.canvas.requestRenderAll();
  });
  editor.assets
    .addAsset({
      ...addAssetArgs,
      data: imageUrl,
    })
    .then((path) => {
      if (path) {
        editor.assets.setObjectAsset(object.id, {
          type: "image-storage",
          path,
          contentType: EditorAssetContentType.png,
        });
      } else {
        onError?.(new Error("Cannot upload image to the storage."));
      }
    });
}

export function canHedImageBeColored(object: fabric.Object | fabric.StaticImage) {
  if (!isStaticImageObjectHed(object)) {
    return false;
  }
  const colorDisplayType = object.metadata?.colorDisplayType;
  if (colorDisplayType === StaticImageElementColorDisplayType.Alpha) {
    return object.filters && object.filters.length > 0;
  }
  if (colorDisplayType === StaticImageElementColorDisplayType.RGB) {
    return true;
  }
  if (colorDisplayType === StaticImageElementColorDisplayType.ShapeOnly) {
    return true;
  }
  if (colorDisplayType === StaticImageElementColorDisplayType.ColorAndShape) {
    return true;
  }
  return false;
}

export function canHedImageColorBeUsed(object: fabric.Object | fabric.StaticImage) {
  if (!isStaticImageObjectHed(object)) {
    return false;
  }
  const colorDisplayType = object.metadata?.colorDisplayType;
  if (colorDisplayType === StaticImageElementColorDisplayType.Alpha) {
    return object.filters && object.filters.length > 0;
  }
  if (colorDisplayType === StaticImageElementColorDisplayType.RGB) {
    return true;
  }
  if (colorDisplayType === StaticImageElementColorDisplayType.ShapeOnly) {
    return false;
  }
  if (colorDisplayType === StaticImageElementColorDisplayType.ColorAndShape) {
    return true;
  }
  return false;
}

export function doesHedImageHaveHedUrl(object: fabric.Object | fabric.StaticImage) {
  if (!isStaticImageObjectHed(object)) {
    return false;
  }

  if (
    object.metadata.colorDisplayType === StaticImageElementColorDisplayType.ShapeOnly ||
    object.metadata.colorDisplayType === StaticImageElementColorDisplayType.ColorAndShape
  ) {
    // By definition, shape only image does not have valid hed url; the shape control must be generated.
    return false;
  }

  return Boolean(object.metadata && object.metadata.hedUrl);
}

export function getHtmlImageElementFromUrlAsync(src: string) {
  return loadImageElementFromURL(src);
}

export function getImageElementFromFileAsync(file: File) {
  return new Promise<HTMLImageElement | undefined>((resolve, reject) => {
    try {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        console.log("load image");

        if (fileReader.result && typeof fileReader.result === "string") {
          return getHtmlImageElementFromUrlAsync(fileReader.result).then(resolve).catch(reject);
        } else {
          return reject("File cannot be read as a valid data url.");
        }
      };

      fileReader.onerror = reject;

      fileReader.onabort = () => resolve(undefined);

      fileReader.readAsDataURL(file);
    } catch (error) {
      console.error(error);

      reject(error);
    }
  });
}

export async function getImageElementFromFilesAsync(files: FileList) {
  for (let i = 0; i < files.length; ++i) {
    const imageElement = await getImageElementFromFileAsync(files[i]);

    if (imageElement) {
      return imageElement;
    }
  }
}

export function getImageUrlFromImageId({
  imageId,
  imageSize = "public",
}: {
  imageId: string;
  imageSize?: "64" | "128" | "256" | "public";
}) {
  return `https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/${imageId}/${imageSize}`;
}

export async function updateImageSubjectCaption(
  image: fabric.Object | fabric.StaticImage,
  value: string,
) {
  try {
    if (!image) {
      return;
    }

    image.metadata = {
      ...image.metadata,
      subject: value,
    };

    const { editor } = editorContextStore.getState();

    await editor?.assets.updateObjectAssetMetadata({
      object: image,
      caption: value || "",
    });
  } catch (error) {
    debugError("Error updating image subject caption: ", error);
  }
}

export function getImageFormatFromImageArray(uint: Uint8Array): ImageFormat | null {
  if (uint[0] === 0xff && uint[1] === 0xd8 && uint[2] === 0xff) {
    return ImageFormat.JPEG;
  } else if (uint[0] === 0x89 && uint[1] === 0x50 && uint[2] === 0x4e && uint[3] === 0x47) {
    return ImageFormat.PNG;
  } else if (
    uint[0] === 0x52 &&
    uint[1] === 0x49 &&
    uint[2] === 0x46 &&
    uint[3] === 0x46 &&
    uint[8] === 0x57 &&
    uint[9] === 0x45 &&
    uint[10] === 0x42 &&
    uint[11] === 0x50
  ) {
    return ImageFormat.WEBP;
  } else {
    return null;
  }
}

export function createSolidColorImageDataURL(
  width: number,
  height: number,
  color = "#000000",
): string {
  // Create a canvas element
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;

  // Get the 2D context of the canvas
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    return "";
  }

  // Set the fill color and fill the canvas
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, width, height);

  // Convert the canvas to a data URL and return it
  return canvas.toDataURL("image/png");
}

export function getImageData(imageElement: HTMLImageElement | HTMLCanvasElement) {
  const canvas =
    imageElement instanceof HTMLCanvasElement
      ? imageElement
      : getCanvasFromImageElement(imageElement);

  if (!canvas) {
    return;
  }

  const ctx = canvas?.getContext?.("2d");

  if (!ctx) {
    return;
  }

  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  return imageData;
}

export function isImageBlack(imageElement: HTMLImageElement | HTMLCanvasElement) {
  const canvas =
    imageElement instanceof HTMLCanvasElement
      ? imageElement
      : getCanvasFromImageElement(imageElement);

  if (!canvas) {
    return;
  }

  const ctx = canvas?.getContext?.("2d");

  if (!ctx) {
    return;
  }

  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  // Check each pixel to see if it is black
  for (let i = 0; i < imageData.data.length; i += 4) {
    const r = imageData.data[i];
    const g = imageData.data[i + 1];
    const b = imageData.data[i + 2];
    // Alpha is imageData.data[i + 3], not needed for black check

    // If any channel is not 0, the image is not completely black
    if (r !== 0 || g !== 0 || b !== 0) {
      return false;
    }
  }

  // If all pixels are black, return true
  return true;
}

export function isImageChannelBlack(imageDataArray: Uint8ClampedArray, channel: ChannelRGBA) {
  // Iterate through the imageDataArray by 4s, starting at the 3rd index, which is the first alpha value
  for (let i = channel; i < imageDataArray.length; i += 4) {
    // Check if the alpha value is not 0 (indicating the pixel is not completely transparent)
    if (imageDataArray[i] !== 0) {
      // If any pixel is not completely transparent, return false
      return false;
    }
  }
  // If all alpha values are 0, return true
  return true;
}

/**
 * Determines if the given file is an image.
 *
 * It first checks the file's MIME type using the `type` property.
 * If unavailable or inconclusive, it falls back to using the `mime` package based on the file extension.
 *
 * @param file - The File object to check.
 * @returns `true` if the file is an image; otherwise, `false`.
 */
export function isImageFile(file: File) {
  // First, attempt to use the file's type property
  if (file.type && file.type.startsWith("image/")) {
    return true;
  }

  // If file.type is unavailable or not an image, use the mime package as a fallback
  const mimeType = mime.getType(file.name);

  // Check if the MIME type exists and starts with 'image/'
  return mimeType != null && mimeType?.startsWith("image/");
}

export function getEditorAssetContentTypeFromFile(file: File): EditorAssetContentType {
  // First, try matching the exact MIME type if it's recognized
  const recognizedType = Object.values(EditorAssetContentType).find(
    (enumType) => enumType === file.type,
  );
  if (recognizedType) {
    return recognizedType;
  }

  // If MIME type not recognized, check file extension
  const extension = file.name.toLowerCase().split(".").pop() || "";

  switch (extension) {
    case "png":
      return EditorAssetContentType.png;
    case "jpg":
    case "jpeg":
      return EditorAssetContentType.jpeg;
    case "webp":
      return EditorAssetContentType.webp;
    case "mp4":
      return EditorAssetContentType.mp4;
    case "heic":
      return EditorAssetContentType.heic;
    case "json":
      return EditorAssetContentType.json;
    default:
      // Return a default type if nothing else matches
      return EditorAssetContentType.png;
  }
}

/**
 * Converts an image to PNG format and uniformly resizes it if necessary.
 *
 * @param args - The arguments object.
 * @param args.image - The source image as a File or Blob.
 * @param args.targetMaxLength - The maximum length for the largest dimension (width or height).
 * @returns A Promise that resolves to a PNG Blob.
 */
export async function convertImageToPNG({
  image,
  targetMaxLength,
}: {
  image: File | Blob;
  targetMaxLength: number;
}): Promise<Blob> {
  // Load the image into an HTMLImageElement
  const imgElement = await loadImageElementFromBlob(image);

  // Determine if resizing is necessary
  const { naturalWidth: originalWidth, naturalHeight: originalHeight } = imgElement;
  const maxDimension = Math.max(originalWidth, originalHeight);

  let targetWidth = originalWidth;
  let targetHeight = originalHeight;

  if (maxDimension > targetMaxLength) {
    const scale = targetMaxLength / maxDimension;
    targetWidth = Math.round(originalWidth * scale);
    targetHeight = Math.round(originalHeight * scale);
  }

  // Resize the image if necessary and get a canvas
  const resizedCanvas = await resizeImageCanvasElement({
    from: imgElement,
    width: targetWidth,
    height: targetHeight,
  });

  if (!resizedCanvas) {
    throw new Error("Failed to resize image");
  }

  // Convert the canvas to a Blob in PNG format
  return new Promise<Blob>((resolve, reject) => {
    resizedCanvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Canvas toBlob failed"));
        }
      },
      "image/png",
      1, // Quality parameter for PNG (ignored but required)
    );
  });
}

/**
 * Returns a point roughly at the center of an image's non-transparent region (alpha>0).
 * If the shape is donut-like, it won't return the empty middle.
 * Optimized with downsampling and a quick radial check.
 */
export function getImageRoughCentroid(
  imageElement: HTMLImageElement | HTMLCanvasElement,
): { x: number; y: number } | null {
  // Create or retrieve canvas
  const fullCanvas =
    imageElement instanceof HTMLCanvasElement
      ? imageElement
      : getCanvasFromImageElement(imageElement);
  if (!fullCanvas) {
    return null;
  }

  const fullW = fullCanvas.width;
  const fullH = fullCanvas.height;
  if (!fullW || !fullH) {
    return null;
  }

  // Downsample large images for faster alpha scanning
  const MAX_DIM = 100;
  const scale = Math.max(fullW, fullH) > MAX_DIM ? MAX_DIM / Math.max(fullW, fullH) : 1;
  const wW = Math.round(fullW * scale);
  const wH = Math.round(fullH * scale);

  const workingCanvas = document.createElement("canvas");
  workingCanvas.width = wW;
  workingCanvas.height = wH;
  const wCtx = workingCanvas.getContext("2d");
  if (!wCtx) {
    return null;
  }
  wCtx.drawImage(fullCanvas, 0, 0, fullW, fullH, 0, 0, wW, wH);

  const wData = wCtx.getImageData(0, 0, wW, wH).data;
  let sumX = 0,
    sumY = 0,
    count = 0;

  // Collect alpha>0 pixels for naive centroid
  for (let y = 0; y < wH; y++) {
    for (let x = 0; x < wW; x++) {
      if (wData[(y * wW + x) * 4 + 3] > 0) {
        sumX += x;
        sumY += y;
        count++;
      }
    }
  }
  if (!count) {
    return null;
  }

  // Scale centroid back to original
  const roughX = Math.round(sumX / count / scale);
  const roughY = Math.round(sumY / count / scale);

  // Get original image data for final checks
  const fCtx = fullCanvas.getContext("2d");
  if (!fCtx) {
    return null;
  }
  const fData = fCtx.getImageData(0, 0, fullW, fullH).data;

  // Helper to check alpha>0 in original
  const alphaNonZero = (xx: number, yy: number) => {
    if (xx < 0 || yy < 0 || xx >= fullW || yy >= fullH) {
      return false;
    }
    return fData[(yy * fullW + xx) * 4 + 3] > 0;
  };

  // If our rough centroid is valid, return it
  if (alphaNonZero(roughX, roughY)) {
    return { x: roughX, y: roughY };
  }

  // Otherwise, do a radial search around that point
  const maxRadius = Math.min(100, Math.max(fullW, fullH));
  for (let r = 1; r <= maxRadius; r++) {
    for (let angle = 0; angle < 360; angle += 15) {
      const rad = (angle * Math.PI) / 180;
      const cx = Math.round(roughX + r * Math.cos(rad));
      const cy = Math.round(roughY + r * Math.sin(rad));
      if (alphaNonZero(cx, cy)) {
        return { x: cx, y: cy };
      }
    }
  }
  return null;
}

export function getCompositeImageResized(args: {
  imageElement: HTMLImageElement | HTMLCanvasElement;
  maskElement: HTMLImageElement | HTMLCanvasElement;
  alpha?: number;
  targetImageLength?: number;
}): HTMLCanvasElement | null {
  const { imageElement, maskElement, alpha = 1, targetImageLength } = args;

  const width = imageElement.width;
  const height = imageElement.height;

  // Compute final dimensions based on targetImageLength
  let finalWidth = width;
  let finalHeight = height;
  if (targetImageLength) {
    const aspect = width / height;
    if (width >= height) {
      finalWidth = targetImageLength;
      finalHeight = Math.round(finalWidth / aspect);
    } else {
      finalHeight = targetImageLength;
      finalWidth = Math.round(finalHeight * aspect);
    }
  }

  // Create canvas for final composite
  const canvas = document.createElement("canvas");
  canvas.width = finalWidth;
  canvas.height = finalHeight;

  const ctx = canvas.getContext("2d");
  if (!ctx) {
    return null;
  }

  // Draw base image
  ctx.drawImage(imageElement, 0, 0, finalWidth, finalHeight);

  // Overlay mask with given alpha
  ctx.globalAlpha = alpha;
  ctx.drawImage(maskElement, 0, 0, finalWidth, finalHeight);
  ctx.globalAlpha = 1;

  return canvas;
}

export function getImageFilesFromFileList(fileList: FileList) {
  const outputFiles: File[] = [];

  for (let i = 0; i < fileList.length; ++i) {
    const file = fileList[i];
    if (!file) {
      continue;
    }

    if (isImageFile(file)) {
      outputFiles.push(file);
    }
  }

  return outputFiles;
}
