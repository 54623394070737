import { debugError } from "@/core/utils/print-utilts";
import { CustomModelPredictionType } from "./custom-model-prediction-type";
import { CustomModelScaleConfigs } from "./custom-model-scale-configs";
import { PublicTeamId } from "./public-team-id";

export interface CustomModelPredictionInputInstant {
  type: CustomModelPredictionType.InstantGenerateImage;
  prompt: string;
  promptJson: string;
  scaleConfigs?: CustomModelScaleConfigs;
  numOutputs?: number;
  predictionId?: string;
  width?: number;
  height?: number;
  publicTeamId: PublicTeamId;
}

export function isCustomModelPredictionInputInstant(
  obj: any,
): obj is CustomModelPredictionInputInstant {
  try {
    // Check if object is defined and is an object
    if (!obj || typeof obj !== "object") {
      return false;
    }

    // Check backendType
    if (obj.type !== CustomModelPredictionType.InstantGenerateImage) {
      return false;
    }

    // Check required string fields
    if (typeof obj.prompt !== "string" || !obj.prompt.trim()) {
      return false;
    }

    if (typeof obj.promptJson !== "string") {
      return false;
    }

    // All checks passed
    return true;
  } catch (error) {
    debugError("Error in isCustomModelPredictionInputInstant:", error);
    return false;
  }
}

export type InstantCustomModelGenerationArgs = CustomModelPredictionInputInstant;

export interface InstantCustomModelGenerationResponse {
  ok: boolean;
  message?: string;
  predictionId?: string;
}
