import { editorContextStore } from "@/contexts/editor-context";
import { classNames } from "@/core/utils/classname-utils";
import { useEffect, useState } from "react";

import { InputBoxClassName } from "@/components/constants/class-names";
import { debugError } from "@/core/utils/print-utilts";
import { Turnstile } from "@marsidev/react-turnstile";
import { Mail } from "lucide-react";
import isEmail from "validator/lib/isEmail";

const buttonClassNameBase =
  "w-full rounded-md border-solid border border-zinc-700 transition-colors my-4 px-2 py-2 flex flex-row justify-center items-center shadow-md box-border";

const buttonClassName = classNames(
  buttonClassNameBase,
  "text-zinc-300 hover:text-lime-500 cursor-pointer hover:border-lime-500",
);

const buttonClassNameLoading = classNames(buttonClassNameBase, "text-zinc-500 cursor-wait");

const buttonClassNameError = classNames(buttonClassNameBase, "text-zinc-500 cursor-not-allowed");

function isEmailLoginDisabled() {
  try {
    return import.meta.env.VITE_EMAIL_LOGIN_DISABLED?.toLowerCase() === "true";
  } catch (error) {
    console.error("Error checking is email login disabled: ", error);
    return false;
  }
}

export function LoginWithEmail({
  isLoading,
  setLoading,
  onEmailSent,
  disabled = false,
}: {
  disabled?: boolean;
  isLoading: boolean;
  setLoading: (value: boolean) => void;
  onEmailSent?: (email: string) => void;
}) {
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [cooldownSeconds, setCooldownSeconds] = useState(0);
  const backend = editorContextStore((state) => state.backend);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (cooldownSeconds > 0) {
      const timer = setTimeout(() => {
        setCooldownSeconds((seconds) => seconds - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [cooldownSeconds]);

  useEffect(() => {
    setIsEmailValid(isEmail(email));
  }, [email]);

  const handleEmailSend = async () => {
    try {
      if (isEmailLoginDisabled()) {
        setErrorMessage("Email login is temporarily down. Working on a fix ...");
        return;
      }

      setErrorMessage("");
      if (!isEmailValid || isLoading || !turnstileToken || cooldownSeconds > 0) {
        return;
      }
      setLoading(true);
      const result = await backend.sendEmailLoginLink({
        email,
        token: turnstileToken,
      });
      console.log(result);
      if (!result.success) {
        setLoading(false);
        setErrorMessage(result.message || "Failed to send email");
        return;
      }
      onEmailSent?.(email);
      setLoading(false);
      setIsEmailSent(true);
      setCooldownSeconds(45);
    } catch (error) {
      debugError("Error loading email. ", error);
    }
  };

  return (
    <>
      {errorMessage && <div className="text-red-500 text-xs">{errorMessage}</div>}
      {isEmailSent && !errorMessage && (
        <div className="text-green-500 text-xs">Email sent - check your inbox</div>
      )}
      <label htmlFor="login-email-input" className="w-full mb-2">
        Email
      </label>
      <input
        id="login-email-input"
        type="email"
        className={classNames(InputBoxClassName, "py-3")}
        value={email}
        onChange={(e) => {
          setEmail(e.currentTarget.value);
        }}
        placeholder="Your email address"
      />
      <button
        type="button"
        className={classNames(
          isEmailLoginDisabled()
            ? buttonClassNameError
            : isEmailValid
              ? isLoading || (isEmailSent && cooldownSeconds > 0)
                ? buttonClassNameLoading
                : buttonClassName
              : buttonClassNameError,
        )}
        onClick={handleEmailSend}
        disabled={cooldownSeconds > 0}
      >
        <Mail size={18} className="mr-5 my-2" />
        {isEmailValid
          ? isLoading
            ? "Sending login link ..."
            : isEmailSent
              ? cooldownSeconds > 0
                ? `Resend available in ${cooldownSeconds}s`
                : "Resend login email"
              : "Continue with email"
          : isEmailSent
            ? cooldownSeconds > 0
              ? `Resend available in ${cooldownSeconds}s`
              : "Resend login email"
            : "Continue with email"}
      </button>
      <Turnstile
        options={{
          size: "flexible",
          theme: "dark",
        }}
        siteKey={import.meta.env.VITE_TURNSTILE_SITE_KEY}
        onSuccess={(token) => setTurnstileToken(token)}
      />
    </>
  );
}
