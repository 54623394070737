export interface GenerateSamEmbeddingInput {
  imageStoragePath: string;
}

export interface GenerateSamEmbeddingResponse {
  ok: boolean;
  message: string;
  samEmbeddingUrl?: string;
}

export enum SegmentationMaskType {
  Face = "face",
  HumanBody = "human-body",
  Clothing = "clothing",
  Product = "product",
  Surrounding = "surrounding",
  Background = "background",
  Unknown = "unknown",
}
export interface GetSegmentationMaskTypeInput {
  imageDataUrl: string;
}

export interface GetSegmentationMaskTypeResponse {
  ok: boolean;
  maskType?: SegmentationMaskType;
  caption?: string;
}

export function isSegmentationMaskType(value: unknown): value is SegmentationMaskType {
  return (
    typeof value === "string" &&
    Object.values(SegmentationMaskType).includes(value as SegmentationMaskType)
  );
}
