import {
  DemoEnvironment,
  DemoEnvironmentStatus,
  GetDemoLoginLinkArgs,
  GetDemoLoginLinkResponse,
  TestUserEmail,
} from "@/core/common/types/demo-env";
import { PublicTeamId } from "@/core/common/types/team";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import {
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { Functions, HttpsCallable, httpsCallable } from "firebase/functions";

// Constants
const demoEnvironmentsCollectionName = "demoEnvironments";

export type GenericDemoEnvironmentDocPath =
  `${typeof demoEnvironmentsCollectionName}/{demoEnvironmentId}`;

export function getGenericDemoEnvironmentDocPath(): GenericDemoEnvironmentDocPath {
  return `${demoEnvironmentsCollectionName}/{demoEnvironmentId}`;
}

export function getDemoEnvironmentCollectionRef(firestore: Firestore) {
  return collection(firestore, demoEnvironmentsCollectionName);
}

export function getDemoEnvironmentDocRef({
  firestore,
  demoEnvironmentId,
}: {
  demoEnvironmentId: string;
  firestore: Firestore;
}) {
  return doc(getDemoEnvironmentCollectionRef(firestore), demoEnvironmentId);
}

// Type guard for DemoEnvironment
export function isDemoEnvironment(data: any): data is DemoEnvironment {
  return (
    data != null &&
    typeof data.id === "string" &&
    data.testUserEmails != null &&
    typeof data.testUserEmails === "object" &&
    typeof data.publicTeamId === "string" &&
    Object.values(DemoEnvironmentStatus).includes(data.status)
  );
}

export class DemoEnvironmentManager {
  private firestore: Firestore;
  private getDemoLoginLinkFunction: HttpsCallable<GetDemoLoginLinkArgs, GetDemoLoginLinkResponse>;

  constructor({
    firestore,
    firebaseFunctions,
  }: {
    firestore: Firestore;
    firebaseFunctions: Functions;
  }) {
    this.firestore = firestore;
    this.getDemoLoginLinkFunction = httpsCallable(
      firebaseFunctions,
      "getDemoLoginLinkColabJuly24_v2",
    );
  }

  private getDemoEnvironmentCollectionRef() {
    return getDemoEnvironmentCollectionRef(this.firestore);
  }

  private getDemoEnvironmentDocRef(demoEnvironmentId: string) {
    return getDemoEnvironmentDocRef({
      firestore: this.firestore,
      demoEnvironmentId,
    });
  }

  async getDemoEnvironmentDoc(demoEnvironmentId: string): Promise<DemoEnvironment | undefined> {
    try {
      debugLog(`Getting demo environment with ID: ${demoEnvironmentId}`);
      const snapshot = await getDoc(this.getDemoEnvironmentDocRef(demoEnvironmentId));
      const data = snapshot.data();

      if (!isDemoEnvironment(data)) {
        debugLog(`Invalid or missing demo environment data for ID: ${demoEnvironmentId}`);
        return undefined;
      }

      return data;
    } catch (error) {
      debugError(`Cannot get demo environment ${demoEnvironmentId}:`, error);
      return undefined;
    }
  }

  private getActiveDemoEnvironmentsQuery() {
    return query(
      this.getDemoEnvironmentCollectionRef(),
      where("status", "==", DemoEnvironmentStatus.Enabled),
    );
  }

  async getActiveDemoEnvironments(): Promise<Record<string, DemoEnvironment>> {
    try {
      debugLog("Getting all active demo environments");
      const q = this.getActiveDemoEnvironmentsQuery();
      const snapshot = await getDocs(q);
      const environments: Record<string, DemoEnvironment> = {};

      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        if (isDemoEnvironment(data)) {
          environments[doc.id] = data;
        }
      });

      debugLog(`Found ${Object.keys(environments).length} active demo environments`);
      return environments;
    } catch (error) {
      debugError("Cannot get active demo environments:", error);
      return {};
    }
  }

  onDemoEnvironmentDocUpdate({
    demoEnvironmentId,
    callback,
  }: {
    demoEnvironmentId: string;
    callback: (environment: DemoEnvironment | undefined) => void;
  }) {
    debugLog(`Setting up listener for demo environment: ${demoEnvironmentId}`);
    return onSnapshot(this.getDemoEnvironmentDocRef(demoEnvironmentId), (snapshot) => {
      const data = snapshot.data();

      if (isDemoEnvironment(data)) {
        callback(data);
      } else {
        callback(undefined);
      }
    });
  }

  async getDemoLoginLink(args: GetDemoLoginLinkArgs): Promise<GetDemoLoginLinkResponse | null> {
    try {
      debugLog(`Getting demo login link for environment ID: ${args.demoEnvironmentId}`);
      const response = await this.getDemoLoginLinkFunction(args);

      debugLog(`Successfully received login link for demo environment: ${args.demoEnvironmentId}`);
      return response.data;
    } catch (error) {
      debugError("Error getting demo login link:", error);
      return null;
    }
  }

  // Get demo environments for a test user email
  async getDemoEnvironmentsForTestUser(
    testUserEmail: TestUserEmail,
  ): Promise<Record<string, DemoEnvironment>> {
    try {
      debugLog(`Getting demo environments for test user: ${testUserEmail}`);
      const q = query(
        this.getDemoEnvironmentCollectionRef(),
        where(`testUserEmails.${testUserEmail}`, "==", true),
      );

      const snapshot = await getDocs(q);
      const environments: Record<string, DemoEnvironment> = {};

      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        if (isDemoEnvironment(data)) {
          environments[doc.id] = data;
        }
      });

      debugLog(
        `Found ${Object.keys(environments).length} demo environments for test user: ${testUserEmail}`,
      );
      return environments;
    } catch (error) {
      debugError(`Cannot get demo environments for test user ${testUserEmail}:`, error);
      return {};
    }
  }

  // Get demo environments for a specific team
  async getDemoEnvironmentsForTeam(
    publicTeamId: PublicTeamId,
  ): Promise<Record<string, DemoEnvironment>> {
    try {
      debugLog(`Getting demo environments for team: ${publicTeamId}`);
      const q = query(
        this.getDemoEnvironmentCollectionRef(),
        where("publicTeamId", "==", publicTeamId),
      );

      const snapshot = await getDocs(q);
      const environments: Record<string, DemoEnvironment> = {};

      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        if (isDemoEnvironment(data)) {
          environments[doc.id] = data;
        }
      });

      debugLog(
        `Found ${Object.keys(environments).length} demo environments for team: ${publicTeamId}`,
      );
      return environments;
    } catch (error) {
      debugError(`Cannot get demo environments for team ${publicTeamId}:`, error);
      return {};
    }
  }

  // Watch for changes to demo environments for a team
  onTeamDemoEnvironmentsUpdate({
    publicTeamId,
    callback,
  }: {
    publicTeamId: PublicTeamId;
    callback: (environments: Record<string, DemoEnvironment>) => void;
  }) {
    debugLog(`Setting up listener for demo environments of team: ${publicTeamId}`);
    const q = query(
      this.getDemoEnvironmentCollectionRef(),
      where("publicTeamId", "==", publicTeamId),
    );

    return onSnapshot(q, (snapshot) => {
      const environments: Record<string, DemoEnvironment> = {};

      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        if (isDemoEnvironment(data)) {
          environments[doc.id] = data;
        }
      });

      callback(environments);
    });
  }
}
