import { Backend } from "@/backend/base";
import { editorContextStore } from "@/contexts/editor-context";
import { EditorEventEmitter } from "@/core/common/editor-event-emitter";
import {
  customModelCaptionTrigger,
  CustomModelInfo,
  CustomModelPlaygroundPromptEditorState,
  CustomModelScaleConfigs,
  CustomModelSetPromptEditorStateEventHandler,
  CustomModelTrainingItem,
  CustomModelType,
  getModelTrainingMentionName,
  getModelTriggerWord,
  isCustomModelTypeProduct,
  UiDisplayMessageEventHandler,
} from "@/core/common/types";
import {
  CustomModelBackgroundTemplate,
  CustomModelPlaygroundPromptEditorUpdatePromptType,
  CustomModelPromptSubject,
  CustomModelScaleConfig,
  isCustomModelTypeHuman,
} from "@/core/common/types/custom-model-types";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { getObjectEntries } from "@/core/utils/type-utils";
import { CustomModelHumanAngle, humanAngleOptions } from "../constants/custom-model-human-angles";

import { ModelEnum } from "@/core/common/types/any-llm";
import { countOccurrences, getNumWords } from "@/core/utils/string-utils";
import { SerializedEditorState } from "lexical";
import { backgroundTemplates } from "../constants/custom-model-background-templates";
import {
  forEachPromptEditorMentionNode,
  getSerializedPromptEditorStateFromPromptJson,
  getSerializedPromptEditorStateFromText,
  SerializedMentionNode,
} from "./custom-model-mention-plugin";
import {
  CustomModelPlaygroundEditorState,
  UserModifiedCaption,
} from "./custom-model-playground-context";

export function shouldAutoCorrectCustomModelPrompt(text: string) {
  if (!text) {
    return false;
  }
  const numWords = getNumWords(text);
  return numWords > 1 && numWords < 500;
}

/**
 * Escapes special regex characters in the given string.
 * For example, "foo.bar" becomes "foo\\.bar", making it safe to use in a RegExp.
 */
function escapeRegExp(str: string): string {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

/**
 * Replaces all occurrences of the keys in `wordMap` with their corresponding value.
 *
 * @param text - The original text in which to replace trigger words.
 * @param wordMap - An object whose keys are trigger words and values are what to replace them with.
 * @returns The transformed text with trigger words replaced.
 */
function replaceTriggerWords({
  text,
  wordMap,
}: {
  text: string;
  wordMap: Record<string, string>;
}): string {
  if (!text || !wordMap) {
    debugLog("Text or wordMap is invalid, returning original text.");
    return text;
  }

  let newText = text;

  for (const [sourceWord, targetWord] of Object.entries(wordMap)) {
    const escapedSourceWord = escapeRegExp(sourceWord);

    // Remove the \b boundary so bracketed triggers like [trigger0] can match
    const regex = new RegExp(escapedSourceWord, "g");

    debugLog(`Replace "${sourceWord}" with "${targetWord}"`);
    newText = newText.replace(regex, targetWord);
  }

  return newText;
}

async function createAllMentionNodesFromCustomModel(
  customModel: CustomModelInfo,
  training: CustomModelTrainingItem,
  scale: number,
  numberOfPromptTriggerWords: number,
): Promise<MentionNodeMap> {
  const node: SerializedMentionNode = {
    caption: training.caption,
    detail: 1,
    format: 0,
    mode: "segmented",
    style: "",
    text: getModelTrainingMentionName({
      modelDisplayName: customModel.displayName,
      training,
    }),
    type: "mention",
    version: 1,
    trainingId: training.id,
    trainingDisplayName: training.displayName,
    modelId: customModel.id,
    modelDisplayName: customModel.displayName,
    scale: 1,
  };
  const mentionNodeMap: MentionNodeMap = {
    [getModelTriggerWord({ modelId: customModel.id })]: {
      node,
      placeholder: `[trigger${numberOfPromptTriggerWords}]`,
    },
  };
  return mentionNodeMap;
}

async function getCustomModelTypeFromMentionNode({
  backend,
  node,
}: {
  backend: Backend;
  node: SerializedMentionNode;
}) {
  const { modelId } = node;

  const { customModelInfo } = editorContextStore.getState();

  if (customModelInfo && customModelInfo?.id === modelId) {
    return customModelInfo.customModelType;
  }

  const model = await backend.getCustomModelInfo(modelId);
  return model?.customModelType || CustomModelType.Custom;
}

async function getSubjectCaptionFromMentionNode({
  backend,
  node,
  isShortened = false,
}: {
  backend: Backend;
  node: SerializedMentionNode;
  isShortened?: boolean;
}) {
  const { caption, modelId, trainingId } = node;

  if (!isShortened && caption) {
    debugLog("Using cached caption");
    return caption;
  }

  const { customModelTrainings } = editorContextStore.getState();

  const training =
    customModelTrainings?.[trainingId] ||
    (await backend?.getCustomModelTraining({
      modelId,
      trainingId,
    }));

  if (isShortened) {
    if (training?.captionShortened) {
      debugLog("Using cached captionShortened");
      return training.captionShortened;
    }

    const response = await backend?.getTrainingCaptionShortened({
      modelId: modelId ?? "",
      trainingId: trainingId ?? "",
    });
    if (response?.ok) {
      debugLog("Using fetched captionShortened");
      return response.captionShortened;
    }
  }

  debugLog("Using cached caption 2");

  return training?.caption || "";
}

export interface HandleAutoCorrectCustomModelPromptArgs {
  backend: Backend | null;
  promptEditorState: CustomModelPlaygroundPromptEditorState;
  modelId: string;
}

export interface HandleAutoCorrectCustomModelPromptResponse {
  ok: boolean;
  message: string;
  data?: {
    message: string;
    modifiedCaption: string;
    isValid: boolean;
    modifiedEditorState?: SerializedEditorState;
  };
}

type MentionNodeMap = Record<
  string,
  {
    node: SerializedMentionNode;
    placeholder: `[trigger${number}]`;
  }
>;

async function getAllMentionNodesFromPromptEditorState(
  promptEditorState: CustomModelPlaygroundPromptEditorState,
): Promise<MentionNodeMap> {
  const { text, json } = promptEditorState;
  const promptState = getSerializedPromptEditorStateFromPromptJson(json);

  // 1) Gather all mention nodes (and placeholders) from the current EditorState
  //    so we can map their corresponding trigger words => placeholders => mention nodes.
  const modelTriggerWords: Record<
    string,
    {
      node: SerializedMentionNode;
      placeholder: `[trigger${number}]`;
    }
  > = {};

  forEachPromptEditorMentionNode(promptState.root, (node) => {
    const trimmedText = getModelTriggerWord({ modelId: node.modelId });

    // Only create a new placeholder if we haven't seen this trigger word yet
    if (!modelTriggerWords[trimmedText]) {
      modelTriggerWords[trimmedText] = {
        node: { ...node },
        placeholder: `[trigger${Object.keys(modelTriggerWords).length}]`,
      };
    }
  });

  if (Object.keys(modelTriggerWords).length <= 0) {
    return {};
  }

  return modelTriggerWords;
}

async function replaceTriggerWordsInText(text: string, modelTriggerWords: MentionNodeMap) {
  // 2) Replace all trigger words in the text with [trigger{index}]
  const inputCaption = replaceTriggerWords({
    text,
    wordMap: Object.fromEntries(
      getObjectEntries(modelTriggerWords).map(([triggerWord, { placeholder }]) => {
        return [triggerWord, placeholder];
      }),
    ),
  });

  return inputCaption;
}

async function getCustomModelType({
  backend,
  modelTriggerWords,
  modelId,
}: {
  backend: Backend;
  modelTriggerWords: MentionNodeMap;
  modelId: string;
}) {
  const word =
    modelTriggerWords[getModelTriggerWord({ modelId })] ||
    Object.values(modelTriggerWords).find(({ node }) => node != null);

  if (!word) {
    return {
      ok: false,
      message: "Tag the model to generate accurate images.",
    };
  }

  const customModelType = await getCustomModelTypeFromMentionNode({
    backend,
    node: word?.node,
  });

  return customModelType;
}

async function getSubjectCaption({
  backend,
  modelTriggerWords,
  modelId,
  isShortened = false,
}: {
  backend: Backend;
  modelTriggerWords: MentionNodeMap;
  modelId: string;
  isShortened?: boolean;
}) {
  const word =
    modelTriggerWords[getModelTriggerWord({ modelId })] ||
    Object.values(modelTriggerWords).find(({ node }) => node != null);

  if (!word) {
    return "";
  }

  const subjectCaption = await getSubjectCaptionFromMentionNode({
    backend,
    node: word?.node,
    isShortened,
  });

  const placeholder = word?.placeholder ?? "";

  // If subjectCaption originally contained "[trigger]", also replace that with the placeholder
  const subjectCaptionModifed = replaceTriggerWords({
    text: subjectCaption,
    wordMap: {
      "[trigger]": placeholder,
    },
  });

  return subjectCaptionModifed;
}

async function replacePlaceholdersInPromptEditorState(
  data: any,
  modifiedCaption: string,
  modelTriggerWords: MentionNodeMap,
) {
  // 5) We want to show the user the final text, but also reconstitute mention nodes
  //    in the final text. Hence we first do a "placeholder -> original mention text" for display.
  //    (So if `[trigger0]` => "myModelName", etc.)
  const displayWordMap = Object.fromEntries(
    getObjectEntries(modelTriggerWords).map(([triggerWord, { node, placeholder }]) => {
      return [placeholder, node.text];
    }),
  );

  const outputMessage = data.message
    ? replaceTriggerWords({
        text: data.message,
        wordMap: displayWordMap,
      })
    : null;

  const outputText = replaceTriggerWords({
    text: modifiedCaption,
    wordMap: displayWordMap,
  });

  // 6) Now we want to build a new EditorState that has real mention nodes for placeholders.
  //    We'll invert the map to go from placeholder -> original mention node
  //    Then we can use `getSerializedPromptEditorStateFromText(...)` to produce a brand new Lexical state.
  const placeholderToMentionNode = Object.fromEntries(
    getObjectEntries(modelTriggerWords).map(([_, { node, placeholder }]) => {
      // Key = e.g. "[trigger0]"
      // Value = the mention node itself
      return [placeholder, node];
    }),
  );

  return {
    placeholderToMentionNode,
    outputMessage,
    outputText,
  };
}

export async function handleAutoCorrectCustomModelPrompt({
  backend,
  promptEditorState,
  modelId,
}: HandleAutoCorrectCustomModelPromptArgs): Promise<HandleAutoCorrectCustomModelPromptResponse> {
  try {
    if (!backend) {
      return {
        ok: false,
        message: "Backend is invalid.",
      };
    }

    const { text } = promptEditorState;
    if (!shouldAutoCorrectCustomModelPrompt(text)) {
      return {
        ok: false,
        message: "Cannot handle auto correct because the text is invalid.",
      };
    }

    const modelTriggerWords = await getAllMentionNodesFromPromptEditorState(promptEditorState);
    const inputCaption = await replaceTriggerWordsInText(text, modelTriggerWords);
    const customModelType = await getCustomModelType({
      backend,
      modelTriggerWords,
      modelId,
    });
    const subjectCaption = await getSubjectCaption({
      backend,
      modelTriggerWords,
      modelId,
    });

    debugLog("Input prompt: ", inputCaption);

    const validCustomModelType =
      typeof customModelType === "string" ? customModelType : CustomModelType.Custom;

    const inputCaptionString = typeof inputCaption === "string" ? inputCaption : "";
    const subjectCaptionString = typeof subjectCaption === "string" ? subjectCaption : "";

    const autocorrectCaptionResponse = await backend.autoCorrectCustomModelCaption({
      customModelType: validCustomModelType,
      inputCaption: inputCaptionString,
      subjectCaption: subjectCaptionString,
    });

    debugLog("Autocorrect caption response: ", autocorrectCaptionResponse);

    const data = autocorrectCaptionResponse?.data;

    if (!autocorrectCaptionResponse?.ok || !data) {
      return {
        ok: false,
        message: "Please provide a valid prompt.",
      };
    }

    const modifiedCaption = data.modifiedCaption;

    const { placeholderToMentionNode, outputMessage, outputText } =
      await replacePlaceholdersInPromptEditorState(data, modifiedCaption, modelTriggerWords);

    // Generate the new editor state based on the final output text
    const modifiedEditorState = getSerializedPromptEditorStateFromText({
      text: modifiedCaption,
      triggerWordToMentionNode: placeholderToMentionNode,
    });

    const isValid = data.isValid
      ? modifiedCaption.trim().toLowerCase() == inputCaption.trim().toLowerCase()
      : false;

    return {
      ok: true,
      message: "OK.",
      data: {
        ...data,
        isValid,
        message:
          (outputMessage ?? "").toLowerCase() === "ok"
            ? "Looks good, use the suggested prompt to improve it further"
            : (outputMessage ?? ""),
        modifiedCaption: outputText,
        modifiedEditorState,
      },
    };
  } catch (error) {
    debugError("Error handling auto-correct custom model prompt: ", error);
    return {
      ok: false,
      message: "Internal server error",
    };
  }
}

export async function removeTextFromPrompt({
  backend,
  textToRemove,
  subjectToRemove,
  promptEditorState,
}: {
  backend: Backend;
  textToRemove?: string;
  subjectToRemove?: CustomModelPromptSubject;
  promptEditorState: CustomModelPlaygroundPromptEditorState;
}) {
  try {
    const modelTriggerWords = await getAllMentionNodesFromPromptEditorState(promptEditorState);

    const promptCaption = await replaceTriggerWordsInText(
      promptEditorState.text,
      modelTriggerWords,
    );

    const response = await removeTextWithValidation({
      backend,
      prompt: promptCaption,
      textToRemove,
      subjectToRemove,
      placeholdersToMaintain: Object.keys(modelTriggerWords),
    });

    const data = response?.data;

    if (!response?.ok || !data) {
      return {
        ok: false,
        message: "Please provide a valid prompt.",
      };
    }

    const modifiedCaption = data.modifiedPrompt;

    const { placeholderToMentionNode, outputText } = await replacePlaceholdersInPromptEditorState(
      data,
      modifiedCaption,
      modelTriggerWords,
    );

    // Generate the new editor state based on the final output text
    const modifiedEditorState = getSerializedPromptEditorStateFromText({
      text: modifiedCaption,
      triggerWordToMentionNode: placeholderToMentionNode,
    });

    return {
      ok: true,
      message: "OK.",
      data: {
        ...data,
        modifiedCaption: outputText,
        modifiedEditorState,
      },
    };
  } catch (error) {
    debugError("Error removing text from prompt: ", error);
    return {
      ok: false,
      message: "Internal server error",
    };
  }
}

export async function handleRemoveBackgroundTemplateFromPrompt({
  promptEditorState,
  eventEmitter,
  backgroundTemplate,
}: {
  promptEditorState: CustomModelPlaygroundPromptEditorState;
  eventEmitter: EditorEventEmitter;
  backgroundTemplate: CustomModelBackgroundTemplate;
}) {
  const prompt = promptEditorState.text;

  const modifiedPrompt = prompt
    .replace(new RegExp(escapeRegExp(backgroundTemplate.shortCaption), "g"), "")
    .trim();

  const promptTriggerWords = await getAllMentionNodesFromPromptEditorState(promptEditorState);

  const modifiedEditorState = getSerializedPromptEditorStateFromText({
    text: modifiedPrompt,
    triggerWordToMentionNode: convertToTriggerWordMentionNodeMap(promptTriggerWords),
  });

  eventEmitter.emit<CustomModelSetPromptEditorStateEventHandler>(
    "custom-model:set-prompt-editor-state",
    {
      promptEditorStateJson: JSON.stringify(modifiedEditorState),
    },
  );
}

async function removeTextWithValidation({
  backend,
  prompt,
  textToRemove,
  subjectToRemove,
  placeholdersToRemove,
  placeholdersToMaintain,
  maxAttempts = 1,
}: {
  backend: Backend;
  prompt: string;
  textToRemove?: string;
  subjectToRemove?: CustomModelPromptSubject;
  placeholdersToRemove?: string[];
  placeholdersToMaintain: string[];
  maxAttempts?: number;
}): Promise<{
  ok: boolean;
  data?: any;
  message?: string;
}> {
  let attempts = 0;

  while (attempts < maxAttempts) {
    attempts += 1;

    if (attempts > 1) {
      debugLog("Failed to remove text from prompt, retrying...");
    }

    const response = await backend.removeTextFromPrompt({
      prompt,
      textToRemove,
      subjectToRemove,
      triggerWordsToRemove: placeholdersToRemove ?? [],
      triggerWordsToMaintain: placeholdersToMaintain,
      languageModel: ModelEnum.AnthropicsClaude3Haiku,
    });

    const data = response?.data;
    if (!response?.ok || !data) {
      continue;
    }

    if (placeholdersToRemove) {
      const hasRemovedPlaceholders = checkPlaceholdersDoNotAppear(
        data.modifiedPrompt,
        placeholdersToRemove,
      );

      const hasMaintainedPlaceholders = checkPlaceholdersAppearOnce(
        data.modifiedPrompt,
        placeholdersToMaintain,
      );

      if (hasRemovedPlaceholders && hasMaintainedPlaceholders) {
        return {
          ok: true,
          data,
        };
      } else {
        debugLog("Placeholders were not correctly handled in the modified prompt");
        const modifiedPrompt = await removeUnwantedPlaceholders({
          prompt: data.modifiedPrompt,
          placeholdersToRemove,
        });

        // If maintained placeholders are missing, append them
        if (!hasMaintainedPlaceholders) {
          const { data: appendedData } = await appendMissingPlaceholders({
            prompt: modifiedPrompt,
            placeholdersToMaintain,
          });
          return {
            ok: true,
            data: appendedData,
          };
        }

        return {
          ok: true,
          data: { modifiedPrompt },
        };
      }
    }

    return {
      ok: true,
      data,
    };
  }

  return {
    ok: false,
    message: "Unable to remove text from prompt. Please try again.",
  };
}

export async function handleAppendTextToPrompt({
  backend,
  textToAdd,
  subjectToAdd,
  promptEditorState,
}: {
  backend: Backend;
  textToAdd: string;
  subjectToAdd: CustomModelPromptSubject;
  promptEditorState: CustomModelPlaygroundPromptEditorState;
}) {
  try {
    const modelTriggerWords = await getAllMentionNodesFromPromptEditorState(promptEditorState);
    const inputCaption = await replaceTriggerWordsInText(promptEditorState.text, modelTriggerWords);
    const promptPlaceholders = Object.values(modelTriggerWords).map((p) => p.placeholder);

    const response = await appendTextWithValidation({
      backend,
      prompt: inputCaption,
      textToAdd,
      placeholdersToMaintain: promptPlaceholders,
      subjectToAdd,
    });

    const data = response?.data;

    if (!response?.ok || !data) {
      return {
        ok: false,
        message: "Unable to append text to prompt.",
      };
    }

    const modifiedCaption = data.modifiedPrompt;
    const { placeholderToMentionNode, outputText } = await replacePlaceholdersInPromptEditorState(
      data,
      modifiedCaption,
      modelTriggerWords,
    );

    // Generate the new editor state based on the final output text
    const modifiedEditorState = getSerializedPromptEditorStateFromText({
      text: modifiedCaption,
      triggerWordToMentionNode: placeholderToMentionNode,
    });

    return {
      ok: true,
      message: "OK.",
      data: {
        ...data,
        modifiedCaption: outputText,
        modifiedEditorState,
      },
    };
  } catch (error) {
    debugError("Error appending text to prompt: ", error);
    return {
      ok: false,
      message: "Internal server error",
    };
  }
}

function checkPlaceholdersAppearOnce(prompt: string, placeholders: string[]): boolean {
  return placeholders.every((ph) => countOccurrences(prompt, ph) === 1);
}

function checkPlaceholdersDoNotAppear(prompt: string, placeholders: string[]): boolean {
  return placeholders.every((ph) => countOccurrences(prompt, ph) === 0);
}

async function getModifiedPromptEditorState({
  modifiedPrompt,
  placeholderTriggerWords,
}: {
  modifiedPrompt: string;
  placeholderTriggerWords: MentionNodeMap;
}) {
  const { placeholderToMentionNode, outputText } = await replacePlaceholdersInPromptEditorState(
    {},
    modifiedPrompt,
    placeholderTriggerWords,
  );

  const modifiedEditorState = getSerializedPromptEditorStateFromText({
    text: modifiedPrompt,
    triggerWordToMentionNode: placeholderToMentionNode,
  });

  return { placeholderToMentionNode, outputText, modifiedEditorState };
}

async function removeUnwantedPlaceholders({
  prompt,
  placeholdersToRemove,
}: {
  prompt: string;
  placeholdersToRemove: string[];
}) {
  return prompt.replace(placeholdersToRemove.join(" "), "").trim();
}

async function appendMissingPlaceholders({
  prompt,
  placeholdersToMaintain,
}: {
  prompt: string;
  placeholdersToMaintain: string[];
}) {
  const placeholdersToAppend = placeholdersToMaintain.filter(
    (placeholder) => !prompt.includes(placeholder),
  );

  // Add missing placeholders at the beginning, separated by spaces
  const placeholdersToAdd = placeholdersToAppend.join(" ");
  const modifiedPrompt = `${placeholdersToAdd} ${prompt}`.trim();

  return {
    ok: true,
    data: { modifiedPrompt },
  };
}

async function appendTextWithValidation({
  backend,
  prompt,
  textToAdd,
  placeholdersToMaintain,
  maxAttempts = 1,
  subjectToAdd,
}: {
  backend: Backend;
  prompt: string;
  textToAdd: string;
  placeholdersToMaintain: string[];
  maxAttempts?: number;
  subjectToAdd: CustomModelPromptSubject;
}): Promise<{
  ok: boolean;
  data?: any;
  message?: string;
}> {
  let attempts = 0;

  while (attempts < maxAttempts) {
    attempts += 1;
    if (attempts > 1) {
      debugLog("Failed to append text to prompt, retrying...");
    }

    const response = await backend.appendTextToPrompt({
      prompt,
      textToAdd,
      subjectToAdd,
      triggerWordsToMaintain: placeholdersToMaintain,
      languageModel: ModelEnum.AnthropicsClaude3Haiku,
    });

    if (!response?.ok) {
      continue;
    }

    const hasAllPlaceholders = checkPlaceholdersAppearOnce(
      response?.data?.modifiedPrompt,
      placeholdersToMaintain,
    );

    if (hasAllPlaceholders) {
      debugLog("All placeholders were found in the modified prompt");
      return {
        ok: true,
        data: response?.data,
      };
    } else {
      debugLog("All placeholders were not found in the modified prompt");

      const modifiedResponse = await appendMissingPlaceholders({
        prompt: response?.data?.modifiedPrompt,
        placeholdersToMaintain,
      });

      if (modifiedResponse.ok) {
        return {
          ok: true,
          data: modifiedResponse.data,
        };
      }
    }
  }

  return {
    ok: false,
    message: "Unable to append text to prompt. Please try again.",
  };
}

export async function removeCustomModelFromPrompt({
  backend,
  customModel,
  training,
  subjectToRemove,
  promptEditorState,
}: {
  backend: Backend;
  customModel: CustomModelInfo;
  training: CustomModelTrainingItem;
  subjectToRemove: CustomModelPromptSubject;
  promptEditorState: CustomModelPlaygroundPromptEditorState;
}) {
  try {
    const promptTriggerWords = await getAllMentionNodesFromPromptEditorState(promptEditorState);
    const promptCaption = await replaceTriggerWordsInText(
      promptEditorState.text,
      promptTriggerWords,
    );

    const customModelToRemove = getModelTriggerWord({ modelId: customModel.id });

    const customModelTriggerWordsToRemove = {
      [customModelToRemove]: promptTriggerWords[customModelToRemove],
    };
    const trainingCaption = await getSubjectCaption({
      backend,
      modelTriggerWords: customModelTriggerWordsToRemove,
      modelId: customModel.id,
    });

    const customModelCaptionToRemove = await replaceTriggerWordsInText(
      trainingCaption,
      customModelTriggerWordsToRemove,
    );

    const promptTriggerWordsToMaintain = Object.keys(promptTriggerWords).reduce((acc, key) => {
      if (!customModelTriggerWordsToRemove[key]) {
        acc[key] = promptTriggerWords[key];
      }
      return acc;
    }, {} as MentionNodeMap);

    const placeholdersToRemove = Object.values(customModelTriggerWordsToRemove).map(
      (p) => p.placeholder,
    );

    const placeholdersToMaintain = Object.values(promptTriggerWordsToMaintain).map(
      (p) => p.placeholder,
    );

    const result = await removeTextWithValidation({
      backend,
      prompt: promptCaption,
      textToRemove: customModelCaptionToRemove,
      placeholdersToRemove,
      placeholdersToMaintain,
      subjectToRemove,
    });

    if (!result.ok || !result.data) {
      return { ok: false, message: result.message || "Unable to remove custom model from prompt." };
    }

    const { data } = result;
    const finalPrompt = data.modifiedPrompt;

    const { outputText, modifiedEditorState } = await getModifiedPromptEditorState({
      modifiedPrompt: finalPrompt,
      placeholderTriggerWords: promptTriggerWordsToMaintain,
    });

    return {
      ok: true,
      message: "OK.",
      data: {
        ...data,
        modifiedCaption: outputText,
        modifiedEditorState,
      },
    };
  } catch (error) {
    debugError("Error removing text from prompt: ", error);
    return {
      ok: false,
      message: "Internal server error",
    };
  }
}
export async function handleAppendBackgroundTemplateToPrompt({
  backend,
  backgroundTemplate,
  promptEditorState,
  eventEmitter,
  setApiState,
  apiState,
}: {
  backend: Backend;
  backgroundTemplate: CustomModelBackgroundTemplate;
  promptEditorState: CustomModelPlaygroundPromptEditorState;
  eventEmitter: EditorEventEmitter;
  setApiState: (state: CustomModelPlaygroundEditorState) => void;
  apiState: CustomModelPlaygroundEditorState;
}) {
  setApiState({
    ...apiState,
    promptEditorState: {
      ...apiState.promptEditorState,
      isUpdatingPrompt: true,
      isUpdatingPromptType: CustomModelPlaygroundPromptEditorUpdatePromptType.BackgroundTemplate,
    },
  });

  // First, check if any background templates already exist in the prompt and remove them
  const promptWithoutBackgroundTemplates = backgroundTemplates.reduce(
    (currentPrompt, template) =>
      currentPrompt.replace(new RegExp(escapeRegExp(template.shortCaption), "g"), "").trim(),
    promptEditorState.text,
  );

  // Create a new promptEditorState with background removed
  const promptTriggerWords = await getAllMentionNodesFromPromptEditorState(promptEditorState);
  const cleanedEditorState = getSerializedPromptEditorStateFromText({
    text: promptWithoutBackgroundTemplates,
    triggerWordToMentionNode: convertToTriggerWordMentionNodeMap(promptTriggerWords),
  });

  const cleanedPromptEditorState = {
    ...promptEditorState,
    text: promptWithoutBackgroundTemplates,
    json: JSON.stringify(cleanedEditorState),
  };

  // Now append the background template to the cleaned prompt
  const result = await handleAppendTextToPrompt({
    backend,
    textToAdd: backgroundTemplate.shortCaption,
    promptEditorState: cleanedPromptEditorState,
    subjectToAdd: CustomModelPromptSubject.Background,
  });

  if (result.ok && result.data) {
    eventEmitter.emit<CustomModelSetPromptEditorStateEventHandler>(
      "custom-model:set-prompt-editor-state",
      {
        promptEditorStateJson: JSON.stringify(result.data.modifiedEditorState),
      },
    );
  }
  setApiState({
    ...apiState,
    promptEditorState: {
      ...apiState.promptEditorState,
      isUpdatingPrompt: false,
      isUpdatingPromptType: null,
    },
  });
}

export async function handleAppendHumanAngleToPrompt({
  humanAngle,
  promptEditorState,
  eventEmitter,
}: {
  humanAngle: CustomModelHumanAngle;
  promptEditorState: CustomModelPlaygroundPromptEditorState;
  eventEmitter: EditorEventEmitter;
}) {
  const prompt = promptEditorState.text;
  const humanAngleText = humanAngle.shortenedPrompt;

  const promptWithoutHumanAngle = humanAngleOptions.reduce(
    (currentPrompt, angle) =>
      currentPrompt.replace(new RegExp(escapeRegExp(angle.shortenedPrompt), "g"), "").trim(),
    prompt,
  );

  const modifiedPrompt = `${promptWithoutHumanAngle} ${humanAngleText}`;
  const promptTriggerWords = await getAllMentionNodesFromPromptEditorState(promptEditorState);

  const modifiedEditorState = getSerializedPromptEditorStateFromText({
    text: modifiedPrompt,
    triggerWordToMentionNode: convertToTriggerWordMentionNodeMap(promptTriggerWords),
  });

  eventEmitter.emit<CustomModelSetPromptEditorStateEventHandler>(
    "custom-model:set-prompt-editor-state",
    {
      promptEditorStateJson: JSON.stringify(modifiedEditorState),
    },
  );
}

export async function handleRemoveHumanAngleFromPrompt({
  humanAngle,
  promptEditorState,
  eventEmitter,
}: {
  humanAngle: CustomModelHumanAngle;
  promptEditorState: CustomModelPlaygroundPromptEditorState;
  eventEmitter: EditorEventEmitter;
}) {
  const prompt = promptEditorState.text;
  const humanAngleText = humanAngle.shortenedPrompt;

  const modifiedPrompt = prompt.replace(humanAngleText, "");
  const promptTriggerWords = await getAllMentionNodesFromPromptEditorState(promptEditorState);

  const modifiedEditorState = getSerializedPromptEditorStateFromText({
    text: modifiedPrompt,
    triggerWordToMentionNode: convertToTriggerWordMentionNodeMap(promptTriggerWords),
  });

  eventEmitter.emit<CustomModelSetPromptEditorStateEventHandler>(
    "custom-model:set-prompt-editor-state",
    {
      promptEditorStateJson: JSON.stringify(modifiedEditorState),
    },
  );
}

export async function appendCustomModelToPrompt({
  backend,
  customModel,
  training,
  promptEditorState,
}: {
  backend: Backend;
  customModel: CustomModelInfo;
  training: CustomModelTrainingItem;
  promptEditorState: CustomModelPlaygroundPromptEditorState;
}) {
  try {
    const newCustomModelTriggerWords = await createAllMentionNodesFromCustomModel(
      customModel,
      training,
      1,
      1,
    );
    const newCustomModelPlaceholders = Object.values(newCustomModelTriggerWords).map(
      (p) => p.placeholder,
    );
    const promptTriggerWords = await getAllMentionNodesFromPromptEditorState(promptEditorState);
    const promptPlaceholders = Object.values(promptTriggerWords).map((p) => p.placeholder);
    const promptCaption = await replaceTriggerWordsInText(
      promptEditorState.text,
      promptTriggerWords,
    );

    if (promptEditorState.text.length === 0) {
      const caption = await getSubjectCaption({
        backend,
        modelTriggerWords: newCustomModelTriggerWords,
        modelId: customModel.id,
        isShortened: true,
      });

      const { outputText, modifiedEditorState } = await getModifiedPromptEditorState({
        modifiedPrompt: caption,
        placeholderTriggerWords: newCustomModelTriggerWords,
      });

      return {
        ok: true,
        message: "OK",
        data: {
          modifiedCaption: outputText,
          modifiedEditorState,
        },
      };
    }

    const allPlaceholders = [...promptPlaceholders, ...newCustomModelPlaceholders];

    const result = await appendTextWithValidation({
      backend,
      prompt: promptCaption,
      textToAdd: await getSubjectCaption({
        backend,
        modelTriggerWords: newCustomModelTriggerWords,
        modelId: customModel.id,
        isShortened: true,
      }),
      placeholdersToMaintain: allPlaceholders,
      subjectToAdd: CustomModelPromptSubject.Product,
    });

    if (!result.ok || !result.data) {
      return { ok: false, message: result.message || "Unable to append text to prompt." };
    }

    const { data } = result;
    const finalPrompt = data.modifiedPrompt;

    const allTriggerWords = { ...promptTriggerWords, ...newCustomModelTriggerWords };
    const { outputText, modifiedEditorState } = await getModifiedPromptEditorState({
      modifiedPrompt: finalPrompt,
      placeholderTriggerWords: allTriggerWords,
    });

    return {
      ok: true,
      message: "OK",
      data: {
        ...data,
        modifiedCaption: outputText,
        modifiedEditorState,
      },
    };
  } catch (error) {
    debugError("Error appending custom model to prompt: ", error);
    return {
      ok: false,
      message: "Internal server error",
    };
  }
}

export async function handleAppendCustomModelToPrompt({
  customModel,
  training,
  promptEditorState,
  backend,
  eventEmitter,
  setApiState,
  apiState,
  subjectToAdd,
}: {
  customModel: CustomModelInfo;
  training: CustomModelTrainingItem;
  promptEditorState: CustomModelPlaygroundPromptEditorState;
  backend: Backend;
  eventEmitter: EditorEventEmitter;
  setApiState: (state: CustomModelPlaygroundEditorState) => void;
  apiState: CustomModelPlaygroundEditorState;
  subjectToAdd: CustomModelPromptSubject;
}) {
  setApiState({
    ...apiState,
    promptEditorState: {
      ...apiState.promptEditorState,
      isUpdatingPrompt: true,
      isUpdatingPromptType:
        subjectToAdd === CustomModelPromptSubject.Human
          ? CustomModelPlaygroundPromptEditorUpdatePromptType.HumanModel
          : CustomModelPlaygroundPromptEditorUpdatePromptType.Product,
    },
  });

  const result = await appendCustomModelToPrompt({
    backend,
    customModel,
    training,
    promptEditorState,
  });
  if (result.ok && result.data) {
    eventEmitter.emit<CustomModelSetPromptEditorStateEventHandler>(
      "custom-model:set-prompt-editor-state",
      {
        promptEditorStateJson: JSON.stringify(result.data.modifiedEditorState),
      },
    );
  } else {
    eventEmitter.emit<UiDisplayMessageEventHandler>("ui:display-message", "error", result.message);
  }
  setApiState({
    ...apiState,
    promptEditorState: {
      ...apiState.promptEditorState,
      isUpdatingPrompt: false,
      isUpdatingPromptType: null,
    },
  });
}

export async function handleRemoveCustomModelFromPrompt({
  customModel,
  training,
  subjectToRemove,
  promptEditorState,
  backend,
  eventEmitter,
  setApiState,
  apiState,
}: {
  customModel: CustomModelInfo;
  training: CustomModelTrainingItem;
  subjectToRemove: CustomModelPromptSubject;
  promptEditorState: CustomModelPlaygroundPromptEditorState;
  backend: Backend;
  eventEmitter: EditorEventEmitter;
  setApiState: (state: CustomModelPlaygroundEditorState) => void;
  apiState: CustomModelPlaygroundEditorState;
}) {
  setApiState({
    ...apiState,
    promptEditorState: {
      ...apiState.promptEditorState,
      isUpdatingPrompt: true,
      isUpdatingPromptType:
        subjectToRemove === CustomModelPromptSubject.Human
          ? CustomModelPlaygroundPromptEditorUpdatePromptType.HumanModel
          : CustomModelPlaygroundPromptEditorUpdatePromptType.Product,
    },
  });

  const result = await removeCustomModelFromPrompt({
    backend,
    customModel,
    training,
    promptEditorState,
    subjectToRemove,
  });

  if (result.ok && result.data) {
    eventEmitter.emit<CustomModelSetPromptEditorStateEventHandler>(
      "custom-model:set-prompt-editor-state",
      {
        promptEditorStateJson: JSON.stringify(result.data.modifiedEditorState),
      },
    );
  } else {
    eventEmitter.emit<UiDisplayMessageEventHandler>("ui:display-message", "error", result.message);
  }
  setApiState({
    ...apiState,
    promptEditorState: {
      ...apiState.promptEditorState,
      isUpdatingPrompt: false,
      isUpdatingPromptType: null,
    },
  });
}

export function extractModifiedPrompt(text: string): string {
  const regex = /"modifiedPrompt":\s*"(.*?)"/s;
  const match = text.match(regex);

  if (match && match[1]) {
    return match[1];
  }

  return "";
}

function getSuffixNameFromCustomModelType(type: CustomModelType) {
  if (type === CustomModelType.VirtualModel) {
    return "HUMAN-DESCRIPTION: ";
  } else if (type === CustomModelType.Style) {
    return "STYLE-DESCRIPTION: ";
  } else {
    return "PRODUCT-DESCRIPTION: ";
  }
}

function convertToTriggerWordMentionNodeMap(
  mentionNodeMap: MentionNodeMap,
): Record<string, SerializedMentionNode> {
  return Object.fromEntries(Object.entries(mentionNodeMap).map(([key, { node }]) => [key, node]));
}

/**
 * Returns the priority value for a given CustomModelType.
 * Higher values represent higher priority (will appear first in prompt)
 */
function getCustomModelTypePriority(modelType?: CustomModelType): number {
  if (!modelType) {
    return 0;
  }

  // Priority map for model types (higher = higher priority)
  const priorityMap: Record<CustomModelType, number> = {
    // Product and product-related types have highest priority
    [CustomModelType.Product]: 100,
    [CustomModelType.Fashion]: 90,
    [CustomModelType.Furniture]: 80,
    [CustomModelType.Tech]: 70,
    [CustomModelType.Food]: 60,
    [CustomModelType.Vase]: 50,
    [CustomModelType.Jewelry]: 40,
    [CustomModelType.Bags]: 30,
    [CustomModelType.Footwear]: 25,

    // Brand-specific types
    [CustomModelType.BrandA]: 20,
    [CustomModelType.BrandB]: 15,

    // Human-related types
    [CustomModelType.VirtualModel]: 10,
    [CustomModelType.Face]: 5,

    // Style and custom types have lowest priority
    [CustomModelType.Style]: 2,
    [CustomModelType.Custom]: 1,
  };

  return priorityMap[modelType] || 0;
}

/**
 * Sorts config entries by their CustomModelType priority
 */
function sortConfigsByModelTypePriority(
  configs: Array<CustomModelScaleConfig>,
  customModels: Record<string, CustomModelInfo>,
): Array<CustomModelScaleConfig> {
  return [...configs].sort((a, b) => {
    const typeA = customModels[a.modelId]?.customModelType;
    const typeB = customModels[b.modelId]?.customModelType;
    return getCustomModelTypePriority(typeB) - getCustomModelTypePriority(typeA);
  });
}

/**
 * Builds a suffix of training captions for all relevant models.
 * If any Product model exists in scaleConfigs, all human (VirtualModel) and style (Style) models are skipped.
 *
 * Example usage:
 *   const suffix = await getPromptSuffix({ backend, scaleConfigs, customModels, customModelTrainings, humanAngle, backgroundTemplate });
 */
export async function getPromptSuffix({
  backend,
  scaleConfigs,
  customModels,
  customModelTrainings,
  prompt,
  userModifiedCaption,
}: {
  backend: Backend;
  scaleConfigs: CustomModelScaleConfigs;
  customModels: Record<string, CustomModelInfo>;
  customModelTrainings: Record<string, CustomModelTrainingItem>;
  prompt: string;
  userModifiedCaption: UserModifiedCaption;
}) {
  try {
    const configs = Object.values(scaleConfigs);
    const hasProduct = configs.some(({ modelId }) =>
      isCustomModelTypeProduct(customModels[modelId]?.customModelType),
    );

    const hasHuman = configs.some(({ modelId }) =>
      isCustomModelTypeHuman(customModels[modelId]?.customModelType),
    );

    const humanAngleInPrompt = humanAngleOptions.find((humanAngle) =>
      prompt.includes(humanAngle.shortenedPrompt),
    );

    const backgroundTemplateInPrompt = backgroundTemplates.find((backgroundTemplate) =>
      prompt.includes(backgroundTemplate.shortCaption),
    );

    const trainingCaptions = await Promise.all(
      sortConfigsByModelTypePriority(
        configs.filter(({ modelId }) => {
          const modelType = customModels[modelId]?.customModelType;
          // If a Product model exists in the configs (hasProduct is true), we want to skip:
          // - Human models (represented by VirtualModel)
          // - Style models (represented by Style)
          // Otherwise, if no Product exists, we include all models.
          return !hasProduct || !hasHuman || modelType !== CustomModelType.Style;
        }),
        customModels,
      ).map(async ({ modelId, trainingId }) => {
        const model = customModels[modelId];
        if (!model) {
          debugLog(`[getPromptSuffix] Cannot find model ${modelId}`);
          return undefined;
        }
        const modelTriggerWord = getModelTriggerWord({ modelId });
        const training =
          customModelTrainings[trainingId] ??
          (await backend.getCustomModelTraining({ modelId, trainingId }));
        const caption: string = userModifiedCaption[modelId]
          ? userModifiedCaption[modelId]
          : training.caption?.trim().replaceAll(customModelCaptionTrigger, modelTriggerWord);
        return `${getSuffixNameFromCustomModelType(model?.customModelType)}${caption}`;
      }),
    );

    let suffix = trainingCaptions.filter(Boolean).join("\n");

    if (humanAngleInPrompt) {
      suffix += `\nHUMAN MODEL ANGLE: ${humanAngleInPrompt.prompt}`;
    }

    if (backgroundTemplateInPrompt) {
      suffix += `\nBACKGROUND DESCRIPTION: ${backgroundTemplateInPrompt.caption}`;
    }

    return suffix ? "\n" + suffix : "";
  } catch (error) {
    debugError(
      "[getPromptSuffix] Error getting prompt suffix from scale configs",
      scaleConfigs,
      "\n",
      error,
    );
    return "";
  }
}
