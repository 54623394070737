import { editorContextStore } from "@/contexts/editor-context";
import { UiDisplayMessageDialogEventHandler } from "@/core/common/types";
import { canUserStartPrediction } from "@/core/utils/custom-model-utils";
import { getUserQuotasFromEditorContext } from "@/hooks/use-user-quotas";
import { Firestore } from "firebase/firestore";
import { Functions, HttpsCallable, httpsCallable } from "firebase/functions";

/**
 * A config describing human traits for generation.
 * All fields are optional, and we use strings with example in comment.
 */
export interface HumanConfig {
  // Basic Identity & Presentation

  gender?: string; // e.g. "male", "female", "androgynous"
  approximateAge?: string; // e.g. "20", "40", "60" - will be formatted as "in their twenties" etc.

  // Body Build & Shape
  build?: string; // e.g. "athletic", "slender", "muscular"
  bodyShape?: string; // e.g. "hourglass", "pear", "rectangular"
  heightDescriptor?: string; // e.g. "tall", "short", "average"
  muscleDefinition?: string; // e.g. "defined", "subtle", "toned"
  proportions?: string; // e.g. "broad", "narrow", "long"

  // Face Structure
  faceShape?: string; // e.g. "oval", "round", "square"
  jawlineDefinition?: string; // e.g. "sharp", "soft", "angular"
  chinShape?: string; // e.g. "dainty", "pointed", "cleft"
  cheekbones?: string; // e.g. "high", "prominent", "subtle"

  // Eyes & Brows
  eyeShape?: string; // e.g. "almond", "round", "hooded", "monolid"
  eyeSize?: string; // e.g. "large", "small"
  eyeColor?: string; // e.g. "brown", "green", "blue"
  eyeDetails?: string; // e.g. "dark-circles", "crow-feet", "long-lashes"
  eyebrowShape?: string; // e.g. "thick", "thin", "natural"

  // Nose Features
  noseShape?: string; // e.g. "straight", "button", "hooked", "upturned"
  noseBridge?: string; // e.g. "high", "low", "prominent"

  // Mouth & Lips
  lipFullness?: string; // e.g. "full", "medium", "thin"
  mouthShape?: string; // e.g. "wide", "narrow", "upturned"
  smile?: string; // e.g. "slight", "broad", "neutral"

  // Skin Characteristics
  skinTone?: string; // e.g. "fair", "light", "medium", "tan", "olive", "deep"
  skinUndertone?: string; // e.g. "warm", "cool", "neutral"
  skinTexture?: string; // e.g. "smooth", "textured", "clear"
  skinFeatures?: string; // e.g. "freckled", "moles", "birthmarked"

  // Hair Attributes
  hairLength?: string; // e.g. "short", "medium", "long", "pixie"
  hairType?: string; // e.g. "wavy", "curly", "straight", "coily"
  hairTexture?: string; // e.g. "fine", "thick", "coarse"
  hairColor?: string; // e.g. "black", "brown", "blonde", "red"
  hairStyle?: string; // e.g. "loose", "braided", "updo"

  // Facial Hair
  facialHair?: string; // e.g. "clean", "stubble", "full-beard", "goatee"

  // Distinctive Features
  tattoos?: string; // e.g. "arm", "neck", "none"
  uniqueFeatures?: string; // e.g. "scar", "mark", "dimples"

  // Expression & Overall Appearance
  expression?: string; // e.g. "neutral", "warm", "serious"
  extraDescription?: string; // e.g. "holding a basketball", "in front of a white background", "in a blue room"
}

export interface BuildAHumanArgs {
  /** The same `HumanConfig` for all four panels */
  humanConfig: HumanConfig;

  /** Whether to assemble the human caption by tossing the humanConfig into Gemini instead of using string concatenation. Default false, using string concatenation. */
  assembleHumanCaptionWithGemini?: boolean;

  /** Optional panel-specific backgrounds */
  panelBackgrounds?: [string, string, string, string];

  // the below fields correspond to captions that actually get sent in either for human generation or later training a VirtualModel on the generated result
  /** The full prompt for the model. comes in as blank, but we can fill it in later */
  prompt?: string;

  /** person-specific caption
   *
   * So if full prompt is "four-panel image of the same human: A female model with red hair. PANEL TOP-LEFT: The female model plain white background. PANEL TOP-RIGHT: The female model plain light-tan background. PANEL BOTTOM-LEFT: The female model plain beige background. PANEL BOTTOM-RIGHT: The female model plain light-gray background " then this personSpecificCaption is "A female model with red hair"
   *
   * We end up using this caption along with the panel backgrounds to train the virtual model, so we don't re-caption things.
   */
  personSpecificCaption?: string;

  /** and these are captions for each panel.  So if full prompt is "four-panel image of the same human: A female model with red hair. PANEL TOP-LEFT: The female model plain white background. PANEL TOP-RIGHT: The female model plain light-tan background. PANEL BOTTOM-LEFT: The female model plain beige background. PANEL BOTTOM-RIGHT: The female model plain light-gray background " then this personSpecificCaption is "A female model with red hair"
   *
   * then we cache the first item in the list panel top-left caption: "The female model plain white background", then panel top-right caption: "The female model plain light-tan background", ... etc
   */
  panelSpecificCaptions?: [string, string, string, string];

  /** And this is going to merge the training caption specifically for the person along with the panel specific caption. So you're going to merge them. This way we preserve the captions when doing training instead of potentially recapturing and messing things up.
   *
   * For example:
   * "four-panel image of the same human: A female model with red hair. PANEL TOP-LEFT: The female model on a  plain white background. PANEL TOP-RIGHT: The female model plain light-tan background. PANEL BOTTOM-LEFT: The female model plain beige background. PANEL BOTTOM-RIGHT: The female model plain light-gray background " then the second caption in here (top-right) will be a mix of the person specific caption and the panel specific caption: "The female [trigger] model with red hair on a light-tan background"
   */
  trainingDataSpecificCaptions?: [string, string, string, string];
}

export type BuildAHumanResponse =
  | {
      ok: boolean;
      docId: string;
      message: string;
      finalCaption: string;
      trainingDataSpecificCaptions: [string, string, string, string];
    }
  | {
      // for quota issues
      ok: false;
      message: string;
    };

export class BuildAHumanManager {
  // private firebaseFunctions: Functions;
  private firestore: Firestore;
  private generateHuman2x2Callable: HttpsCallable<BuildAHumanArgs, BuildAHumanResponse> | null =
    null;

  constructor({
    firestore,
    firebaseFunctions,
  }: {
    firestore: Firestore;
    firebaseFunctions: Functions;
  }) {
    this.firestore = firestore;
    this.generateHuman2x2Callable = httpsCallable(
      firebaseFunctions,
      "generateHuman2x2ColabJuly24_v2",
      { timeout: 600000 }, // 10 minutes
    );
  }

  private get userQuotas() {
    return getUserQuotasFromEditorContext(editorContextStore.getState());
  }

  async buildAHuman(input: BuildAHumanArgs): Promise<BuildAHumanResponse> {
    try {
      if (!this.generateHuman2x2Callable) {
        throw new Error("Generate human callable not initialized");
      }

      const { eventEmitter } = editorContextStore.getState();

      const userQuotas = this.userQuotas;

      if (!canUserStartPrediction({ userQuotas })) {
        eventEmitter.emit<UiDisplayMessageDialogEventHandler>(
          "ui:display-message-dialog",
          "quota-subscribe",
          {
            title: "No custom model generate credits left.",
            header: "You have used all custom model credits.",
          },
        );

        return {
          ok: false,
          message: "No custom model generate quota left.",
        };
      }

      //  todo(Leon): uncomment this
      const response = await this.generateHuman2x2Callable(input);
      return response.data;
    } catch (error) {
      console.error("Error generating human:", error);
      return {
        ok: false,
        docId: "",
        message: "Failed to generate human",
        finalCaption: "",
      };
    }
  }
}
