export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const getObjectEntries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>;

export const getObjectKeys = <T extends object>(obj: T) => Object.keys(obj) as (keyof T)[];

export type NonFunctionKeys<T> = {
  [K in keyof T]: T[K] extends (...args: any[]) => any ? never : K;
}[keyof T];

export type NonFunctionObject<T> = Pick<T, NonFunctionKeys<T>>;
