import { FullScreenAuthContainer } from "@/components/auth/auth-container";
import { SecondaryButton } from "@/components/primitives/button/button";
import useNetworkStatus from "@/hooks/user-network-status";
import { ReactNode } from "react";

interface NetworkStatusWrapperProps {
  children: ReactNode;
}

export function NetworkStatusError() {
  const CONNECTION_ERROR_TITLE = "Internet Connection Error";
  const CONNECTION_ERROR_MESSAGE =
    "Looks like you're experiencing connection issues. Please check your internet connection and try again.";

  return (
    <FullScreenAuthContainer>
      <div className="flex flex-col items-center gap-8 text-center group">
        <div className="flex flex-col items-center gap-2">
          <h1 className="group-hover:text-zinc-300 transition-colors text-2xl font-bold">
            {CONNECTION_ERROR_TITLE}
          </h1>
          <p className="group-hover:text-zinc-300 transition-colors">{CONNECTION_ERROR_MESSAGE}</p>
        </div>
        <SecondaryButton onClick={() => window.location.reload()}>Retry Connection</SecondaryButton>
      </div>
    </FullScreenAuthContainer>
  );
}

export function NetworkStatusWrapper({ children }: NetworkStatusWrapperProps) {
  const isOnline = useNetworkStatus();

  if (!isOnline) {
    return <NetworkStatusError />;
  }

  return <>{children}</>;
}
