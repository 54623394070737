import { IEditorContext } from "@/contexts/editor-context";
import { getUpdaterFunction, SetEditorStateFunction } from "@/contexts/editor-context-utils";
import { noop } from "lodash";
import { AppUserQuotas } from "../types";
import type { PublicTeamId } from "./public-team-id";
import { isPublicUserId, PublicUserId, PublicUserRoles } from "./public-user-id";
import { AppRoleType } from "./user-roles";
import { StateUpdater } from "./utils";
export { isPublicTeamId } from "./public-team-id";
export type { PublicTeamId } from "./public-team-id";

export const appRoleTypeDescriptions: Record<AppRoleType, string> = {
  [AppRoleType.Owner]:
    "Owners have full access to manage team members, billing, and manage the subscription for the team. They can create and modify all custom models, product videos, and drag and drop canvas projects.",
  [AppRoleType.Writer]:
    "Editors have editing access to train and edit custom models. They can use custom models to generate new images. All model training and generations count towards the owner's subscription plan.",
  [AppRoleType.Reader]:
    "Readers have view only access to custom model image generations. They can view and download existing image generations but cannot create new ones.",
  [AppRoleType.Commenter]: "",
};

export type PublicTeamRoles = Record<PublicUserId, AppRoleType>;

export enum TeamSubscriptionTier {
  None = "None",
  Standard = "Standard",
}

export const teamSubscriptionTierRank: Record<TeamSubscriptionTier, number> = {
  [TeamSubscriptionTier.None]: 0,
  [TeamSubscriptionTier.Standard]: 1,
};

export interface PublicTeamQuotas extends Omit<AppUserQuotas, "id"> {
  id: PublicTeamId;
  numTeamUsers: number;
  maxNumTeamUsers: number;
  numCollaborationProjects: number;
  maxNumCollaborationProjects: number;
  teamSubscriptionTier?: TeamSubscriptionTier;
  roles: PublicTeamRoles;
}

export function isPublicTeamQuotas(quotas: any): quotas is PublicTeamQuotas {
  return quotas && quotas.roles && typeof quotas.maxNumTeamUsers === "number";
}

export interface TeamMetadata {
  id: PublicTeamId;
  name: string;
  roles: PublicUserRoles;
}

export function isTeamMetadata(metadata: any): metadata is TeamMetadata {
  return typeof metadata?.id === "string" && (metadata as TeamMetadata).roles != null;
}

export type TeamMetadataCollection = Record<PublicTeamId, TeamMetadata>;

export interface TeamEditorState {
  userTeams: TeamMetadataCollection;
  setUserTeams: (value: StateUpdater<TeamMetadataCollection>) => void;
  teamQuotas: PublicTeamQuotas | null;
  setTeamQuotas: (value: StateUpdater<PublicTeamQuotas | null>) => void;
  currentTeamId: PublicTeamId | null;
  setCurrentTeamId: (value: StateUpdater<PublicTeamId | null>) => void;
}

export function getDefaultUserTeamMetadata(
  editorContextState: IEditorContext,
): TeamMetadata | null {
  const { user, publicUserId, currentTeamId } = editorContextState;

  if (!user || !publicUserId || !isPublicUserId(publicUserId) || !currentTeamId) {
    return null;
  }

  return {
    id: currentTeamId,
    name: `${user.displayName ?? "User"}'s Team`,
    roles: {
      [publicUserId]: AppRoleType.Owner,
    },
  };
}

export function getDummyTeamEditorState(): TeamEditorState {
  return {
    userTeams: {},
    setUserTeams: noop,
    teamQuotas: null,
    setTeamQuotas: noop,
    currentTeamId: null,
    setCurrentTeamId: noop,
  };
}

export function getDefaultTeamEditorState(set: SetEditorStateFunction): TeamEditorState {
  return {
    userTeams: {},
    setUserTeams: getUpdaterFunction(set, "userTeams"),
    teamQuotas: null,
    setTeamQuotas: getUpdaterFunction(set, "teamQuotas"),
    currentTeamId: null,
    setCurrentTeamId: getUpdaterFunction(set, "currentTeamId"),
  };
}
