// ==============================
// 1) A generic helper function
// ==============================

import { DocumentReference, getDoc, onSnapshot } from "firebase/firestore";

/**
 * Subscribes to changes on a Firestore document. If the document does not exist
 * or the user is not allowed to read it yet, this function will poll for its existence
 * every `intervalMs` milliseconds. As soon as the doc can be read, it will attach a live
 * onSnapshot listener, stop the polling, and forward subsequent snapshot data to `onData`.
 *
 * Returns an unsubscribe function that cancels both the polling and, if already attached,
 * the live onSnapshot listener. This makes it a drop-in replacement for standard Firestore
 * onSnapshot calls when you need to handle docs that might not exist yet.
 *
 * Usage Example:
 *
 *   const unsubscribe = onSnapshotWithExistenceCheck({
 *       docRef,
 *       onData: (docData) => {
 *           console.log("Doc data is:", docData);
 *       },
 *       typeGuard: isCustomModelPredictionItem,
 *       intervalMs: 2000, // poll every 2 seconds
 *   });
 *
 *   // Later, when done:
 *   unsubscribe();
 */
export function onSnapshotWithExistenceCheck<T>({
  docRef,
  onData,
  typeGuard,
  intervalMs = 2000,
}: {
  docRef: DocumentReference<T>;
  /**
   * Called whenever a valid snapshot is received (i.e. doc exists
   * and it passes the type guard).
   */
  onData: (data: T) => void;
  /**
   * A type guard that ensures the doc data you receive is the shape you expect.
   * If the type guard fails, you can ignore or log the invalid data.
   */
  typeGuard: (docData: unknown) => docData is T;
  /**
   * How frequently to poll the document until it exists or is readable.
   * Defaults to 2000 milliseconds.
   */
  intervalMs?: number;
}): () => void {
  let unsubscribeOnSnapshot: (() => void) | undefined;
  let pollingTimer: ReturnType<typeof setTimeout> | undefined;
  let stopped = false;

  /**
   * Attempt to get the doc. If it exists and is readable, we'll attach a real-time snapshot.
   * If not, we wait a bit and retry (polling).
   */
  async function trySubscribe(): Promise<void> {
    if (stopped) {
      return;
    }

    try {
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // Document exists, so attach the normal onSnapshot listener and stop polling
        unsubscribeOnSnapshot = onSnapshot(docRef, (snapshot) => {
          if (!snapshot.exists()) {
            return;
          }
          const docData = snapshot.data();

          if (typeGuard(docData)) {
            onData(docData);
          } else {
            // Optionally handle or log unexpected doc shape
            console.warn("onSnapshotWithExistenceCheck: Doc shape failed type guard.");
          }
        });
      } else {
        // Doc still not accessible or doesn't exist - poll again
        pollingTimer = setTimeout(trySubscribe, intervalMs);
      }
    } catch (error) {
      // Usually a permission error. We'll poll again anyway.
      pollingTimer = setTimeout(trySubscribe, intervalMs);
    }
  }

  // Start the initial poll
  void trySubscribe();

  // Return a cleanup function that cancels both onSnapshot and the poll
  return () => {
    stopped = true;
    if (pollingTimer) {
      clearTimeout(pollingTimer);
    }
    if (unsubscribeOnSnapshot) {
      unsubscribeOnSnapshot();
    }
  };
}
