export const REMOVE_BACKGROUND = "callRemoveBackgroundApi";
export const USE_INVITE_CODE = "useInviteCode";
export const USER_HAS_INVITE_CODE = "doesUserHaveInviteCode";
export const SEND_MOBILE_REDIRECT_EMAIL = "sendMobileRedirectEmail";
export const GET_USER_INVOICES = "getUserInvoices";
export const DOWNLOAD_AND_UPLOAD_INVOICE = "downloadAndUploadInvoice";
export const DISCONNECT_REAL_TIME_STATE = "disconnectRealTimeState";

export const CREATE_NEW_PROJECT = "createNewProjectColabJuly24_v2";
export const DELETE_USER_PROJECT = "deleteUserProjectColabJuly24_v2";
export const SEND_EMAIL_LOGIN_LINK = "sendEmailLoginLinkColabJuly24_v2";

export const EXT_FIRESTORE_STRIPE_PAYMENTS_CREATE_PORTAL_LINK =
  "ext-firestore-stripe-payments-createPortalLink";

export const UPDATE_STRIPE_SUBSCRIPTION = "updateStripeSubscriptionColabJuly24_v2";
export const GET_PUBLIC_USER_ID = "getPublicUserIdColabJuly24_v2";
export const INVITE_USERS_TO_TEAM = "teamManagement-inviteUsersToTeamColabJuly24_v2";
export const REMOVE_USER_FROM_TEAM = "teamManagement-removeUserFromTeam_v2";
export const UPDATE_USER_TEAM_ROLE = "teamManagement-updateUserTeamRoleColabJuly24_v2";
export const CREATE_ONE_TIME_PAYMENT_CHECKOUT_SESSION =
  "createOneTimePaymentCheckoutSessionColabJuly24_v2";
export const CREATE_CUSTOM_ONE_TIME_PAYMENT_CHECKOUT_SESSION =
  "createCustomOneTimePaymentCheckoutSessionColabJuly24_v2";
export const CREATE_STRIPE_PORTAL_LINK = "createStripePortalLinkColabJuly24_v2";
export const ON_USER_LOGIN_CONFIRMATION = "onUserLoginColabJuly24V2_v2";
export const REFRESH_CUSTOM_USER_CLAIMS = "refreshCustomUserClaimsColabJuly24_v2";

export const UPLOAD_REFERENCE_HUMAN = "uploadReferenceHumanColabJuly24_v2";
export const SET_SUBSCRIPTION_PRODUCT_ID_QUOTA = "setSubscriptionProductIdQuotaColabJuly24_v2";
export const VIDEO_GENERATION_ENTRYPOINT = "videoGenerationEntrypointColabJuly24_v2";

export const DELETE_ASSET = "deleteAsset_v2";
export const APPEND_PTID_ON_FILE_LOAD = "appendPtidOnFileLoad_v2";

export const CHAT_WITH_IMAGES = "chatWithImagesColabJuly24_v2";

export const CUSTOM_MODEL_ACTION_ENTRYPOINT =
  "customModelActionEntrypointColabJuly24GenericHardware_v2";

export const UPDATE_CUSTOM_MODEL_DATASET_ITEM_THUMBNAIL =
  "updateCustomModelDatasetItemThumbnailColabJuly24_v2";

export const FIX_CUSTOM_MODEL_DETAILS_CORRESPONDENCE =
  "fixCustomModelDetailsCorrespondenceColabJuly24_v2";

export const AUTO_CORRECT_CUSTOM_MODEL_CAPTION = "autoCorrectCustomModelCaptionColabJuly24_v2";

export const GENERATE_SAM_EMBEDDING = "generateSamEmbeddingColabJuly24_v2";

export const IMAGE_EDITOR_ENTRYPOINT = "imageEditorEntrypointColabJuly24_v2";
export const RECAPTION_CUSTOM_MODEL_TRAINING = "updateCustomModelTrainingReCaptionColabJuly24_v2";

export const GET_TRAINING_CAPTION_SHORTENED = "getTrainingCaptionShortenedColabJuly24_v2";

export const EXECUTE_MULTI_STEP_NODE_WORKFLOW = "onCallExecuteMultiStepNodeWorkflowColabJuly24_v2";
export const INSTANT_CUSTOM_MODEL_GENERATION = "instant_custom_model_generation_handler";
export const CUSTOM_MODEL_POST_PROCESS_PYTHON = "custom_model_postprocess_handler";
