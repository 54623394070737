export enum CustomModelPredictionType {
  Default = "Default",
  FixDetails = "FixDetails",
  GenerateFromPose = "GenerateFromPose",
  FixLogoAndText = "FixLogoAndText",
  RegenerateHuman = "RegenerateHuman",
  UpscaleCreative = "UpscaleCreative",
  ClarityUpscale = "ClarityUpscale",
  GenerateVariations = "GenerateVariations",
  InContextVariations = "ICV",
  UpscaleFace = "UpscaleFace",
  MultiStageGeneration = "MultiStageGeneration",
  ICLight = "ICLight",
  TryOn = "TryOn",
  MagicErase = "MagicErase",
  IdeogramInContext = "IdIC",
  ChangeColor = "ChangeColor",
  DepthControlnet = "DepthControlnet",
  IdTextToImage = "IdTextToImage",
  IdInpainting = "IdInpainting",
  IdRemix = "IdRemix",
  FpUpscale = "FpUpscale",
  FluxControlnet = "FC",
  HQMaskFreeEdit = "HQMaskFreeEdit",
  HQOutpaint = "HQOutpaint",
  FluxUltraReduxRemix = "FluxUltraReduxRemix",
  InstantGenerateImage = "InstantGenerateImage",
  GenericImageEditing = "GenericImageEditing",
  ChangeHumanPose = "ChangeHumanPose",
}

export function isCustomModelPredictionType(value: unknown): value is CustomModelPredictionType {
  return (
    typeof value === "string" &&
    Object.values(CustomModelPredictionType).includes(value as CustomModelPredictionType)
  );
}
