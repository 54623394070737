export const DEFAULT_ORIGIN_URL = "https://app.flair.ai";

// Auth
export const EMAIL_LINK_SIGNIN = "emailLinkSignin";
export const LOGIN = "login";
export const INVITE_CODE = "inviteCode";

// Dashboard
export const PROJECTS = "projects";
export const NEW_PROJECT = "newProject";
export const NEW_TRYON_PROJECT = "newTryOnProject";
export const EDITOR_DEBUG = "editorDebug";
export const CUSTOM_MODELS = "models";
export const NEW_CUSTOM_MODEL = "newModel";
export const BUILD_A_HUMAN = "build-a-human";
export const VIDEOS = "videos";
export const GENERATE_VIDEO_DEBUG = "generateVideoDebug";
export const ASSETS = "assets";
export const API = "api";
export const COLLAB_DEBUG = "collabDebug";
export const HUMANS = "humans";
export const EXPLORE = "explore";

// Image editor
export const IMAGE_EDITOR = "imageEditor";
export const NEW_IMAGE_EDITOR = "newImageEditor";

// Debug
export const SEGMENTATION_DEBUG = "segmentationDebug";
export const IMAGE_EDITOR_DEBUG = "imageEditorDebug";

export const ALL_TOOLS = "all-tools";
//Onboarding
export const ONBOARDING = "onboarding";

// Checkout
export const NEW_CHECKOUT_SESSION = "newCheckout";

// Admin
export const ADMIN_SET_PRODUCT_QUOTA = "adminSetProductQuota";

// Mobile
export const MOBILE = "mobile";
export const MOBILE_ERROR = "mobileError";

export const ONE_TIME_PAYMENT = "oneTimePayment";

export const DEMO_LOGIN = "demo";
