import { ColorAttentionMaskMap, PromptState, WordColors } from "@/core/common/types";
import { getStyleObjectFromCSS } from "@lexical/selection";
import {
  $getRoot,
  createEditor,
  CreateEditorArgs,
  SerializedEditorState,
  SerializedTextNode,
} from "lexical";
import { cleanupText, getNumLetters } from "./string-utils";

type InternalSerializedNode = {
  children?: Array<InternalSerializedNode>;
  type?: string;
  version?: number;
  style?: string;
  text?: string;
};

export function getStyleFromCSS(css: string) {
  try {
    return getStyleObjectFromCSS(css);
  } catch (error) {
    console.warn(error);
    return {};
  }
}

function foreachSerializedNodeRecursive(
  serializedNode: InternalSerializedNode,
  callback: (node: InternalSerializedNode) => boolean,
) {
  const children = serializedNode?.children;
  if (children && Array.isArray(children)) {
    for (let i = 0; i < children.length; i++) {
      const serializedJSONChildNode = children[i];
      if (!callback(serializedJSONChildNode)) {
        foreachSerializedNodeRecursive(serializedJSONChildNode, callback);
      }
    }
  }
}

export function getCSSFromStyleObject(styles: Record<string, string>): string {
  let css = "";

  for (const style in styles) {
    if (style) {
      css += `${style}: ${styles[style]};`;
    }
  }

  return css;
}

export function getTextContentFromPromptState(
  promptState: PromptState,
  createEditorArgs: CreateEditorArgs = {},
) {
  if (promptState) {
    const textEditor = createEditor(createEditorArgs);
    const editorState = textEditor.parseEditorState(promptState);
    return editorState.read(() => $getRoot().getTextContent());
  }
  return null;
}

export function getPromptStateFromText(text: string): PromptState {
  return JSON.stringify({
    root: {
      children: [
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text,
              type: "text",
              version: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
          textFormat: 0,
          textStyle: "",
        },
      ],
      direction: "ltr",
      format: "",
      indent: 0,
      type: "root",
      version: 1,
    },
  });
}

export function getPromptFromTextContent(
  text: string,
  props?: Partial<SerializedTextNode>,
): SerializedEditorState {
  return {
    root: {
      children: [
        {
          // @ts-ignore
          children: [
            {
              detail: 0,
              format: 0,
              mode: "normal",
              type: "text",
              version: 1,
              ...(props ?? {}),
              text: cleanupText(text),
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
          tag: "h1",
        },
      ],
      direction: "ltr",
      format: "",
      indent: 0,
      type: "root",
      version: 1,
    },
  };
}

export function getColorAttentionMapFromPromptState(
  promptState: PromptState,
  prevMap: ColorAttentionMaskMap = {},
) {
  if (!promptState) {
    return {};
  }

  try {
    const colorWordMap: ColorAttentionMaskMap = {};

    const serializedState = JSON.parse(promptState);
    const node = serializedState.root;
    if (node) {
      foreachSerializedNodeRecursive(node, (childNode) => {
        if (
          childNode?.type === "text" &&
          typeof childNode.text === "string" &&
          typeof childNode.style === "string" &&
          getNumLetters(childNode.text) > 0
        ) {
          const style = getStyleFromCSS(childNode.style);
          if (style) {
            const backgroundColor = style["background-color"];
            if (typeof backgroundColor === "string" && backgroundColor !== "transparent") {
              const prevImages = prevMap[backgroundColor]?.images || [];
              const prevWords = colorWordMap[backgroundColor]?.words;
              if (prevWords) {
                colorWordMap[backgroundColor] = {
                  images: prevImages,
                  words: prevWords + " " + childNode.text,
                };
              } else {
                colorWordMap[backgroundColor] = {
                  images: prevImages,
                  words: childNode.text,
                };
              }
            }
          }
        }
        return false;
      });
    }

    return colorWordMap;
  } catch (error) {
    console.error(error);
  }

  return {};
}

export function getObjectColorMapFromColorAttentionMaskMap(
  colorAttentionMaskMap: ColorAttentionMaskMap,
) {
  if (!colorAttentionMaskMap) {
    return {};
  }
  const result: Record<string, string> = {};
  Object.entries(colorAttentionMaskMap).forEach(([color, { images }]) => {
    images?.forEach((id) => {
      result[id] = color;
    });
  });
  return result;
}

export function getWordColorsFromColorAttentionMasksMap(
  colorAttentionMaskMap: ColorAttentionMaskMap,
): WordColors {
  if (!colorAttentionMaskMap) {
    return {};
  }
  const result: WordColors = {};

  Object.entries(colorAttentionMaskMap).forEach(([color, { words }]) => {
    result[color] = words;
  });

  return result;
}
