import { debugError, debugLog } from "@/core/utils/print-utilts";
import { extractTripleTickContent } from "@/core/utils/string-utils";
import { GenerationConfig } from "@google/generative-ai";

import debounce from "debounce-promise";
import { Functions, HttpsCallable, httpsCallable } from "firebase/functions";
import { parseDirtyJSON } from "./asset-upload-utils";

// Enums for image length options
export enum ChatWithImagesRequestImageLengthType {
  ExtraSmall = "ExtraSmall",
  Small = "Small",
  Default = "Default",
  Large = "Large",
}

// Corresponding request and response types
export interface ChatWithImagesRequest {
  imageUrls: string[];
  llmPrompt: string;
  imageLength?: ChatWithImagesRequestImageLengthType;
  generationConfig?: GenerationConfig;
}

export interface ChatWithImagesResponse {
  responseText?: string;
}

export type ChatWithImagesFunction = HttpsCallable<ChatWithImagesRequest, ChatWithImagesResponse>;

export function createChatWithImagesFunction({
  firebaseFunctions,
}: {
  firebaseFunctions: Functions;
}): ChatWithImagesFunction {
  return httpsCallable<ChatWithImagesRequest, ChatWithImagesResponse>(
    firebaseFunctions,
    "chatWithImagesColabJuly24_v2",
  );
}

export const chatWithImages = debounce(async function ({
  firebaseFunctions,
  request,
}: {
  firebaseFunctions: Functions;
  request: ChatWithImagesRequest;
}): Promise<string | undefined> {
  const chatWithImagesFunction = createChatWithImagesFunction({
    firebaseFunctions,
  });

  try {
    const result = await chatWithImagesFunction(request);

    debugLog("Chat with images response data: ", result.data);

    if (!result?.data?.responseText) {
      throw new Error("No valid response text returned from chatWithImages.");
    }
    return result.data?.responseText;
  } catch (error) {
    debugError("Error calling chatWithImages request:", error);
    return undefined;
  }
}, 100);

export async function parseChatWithImagesResponse<T>({
  responseText,
  isResponse,
}: {
  responseText?: string;
  isResponse: (response: any) => response is T;
}): Promise<T | undefined> {
  try {
    if (!responseText) {
      return undefined;
    }

    const cleanedText = extractTripleTickContent(responseText) || responseText;

    debugLog("Input text: ", cleanedText);

    const responseParsed = parseDirtyJSON(cleanedText);

    debugLog("Parsed JSON: ", responseParsed);

    if (isResponse(responseParsed)) {
      return responseParsed;
    }

    return undefined;
  } catch (error) {
    debugError(`[parseChatWithImagesResponse] Error parsing chat with images: `, error);
    return undefined;
  }
}
