import {
  CustomModelBackgroundDefaultTemplateCategory,
  CustomModelBackgroundJewelryTemplateCategory,
  CustomModelBackgroundTemplate,
  CustomModelBackgroundTemplateCategory,
  CustomModelType,
  FrontendDisplayTemplateType,
  getCustomModelTypeFromFrontendDisplayTemplateType,
} from "@/core/common/types/custom-model-types";
import { CustomModelPlaygroundGenerationMode } from "../custom-model/custom-model-playground-context";

const backgroundTemplateGenerationModeTypeMap: Record<
  CustomModelBackgroundTemplateCategory,
  CustomModelPlaygroundGenerationMode
> = {
  [CustomModelBackgroundJewelryTemplateCategory.Display]:
    CustomModelPlaygroundGenerationMode.Default,
  [CustomModelBackgroundJewelryTemplateCategory.Face]:
    CustomModelPlaygroundGenerationMode.DepthControlnet,
  [CustomModelBackgroundJewelryTemplateCategory.Hand]:
    CustomModelPlaygroundGenerationMode.DepthControlnet,
  [CustomModelBackgroundJewelryTemplateCategory.Background]:
    CustomModelPlaygroundGenerationMode.Default,
  [CustomModelBackgroundDefaultTemplateCategory.Studio]:
    CustomModelPlaygroundGenerationMode.Default,
  [CustomModelBackgroundDefaultTemplateCategory.Outdoor]:
    CustomModelPlaygroundGenerationMode.Default,
  [CustomModelBackgroundDefaultTemplateCategory.Indoor]:
    CustomModelPlaygroundGenerationMode.Default,
};

export function getBackgroundTemplateGenerationModeType(
  template: CustomModelBackgroundTemplate,
): CustomModelPlaygroundGenerationMode {
  return (
    backgroundTemplateGenerationModeTypeMap[template?.category] ??
    CustomModelPlaygroundGenerationMode.Default
  );
}

export function getCustomModelBackgroundTemplateCategories(
  frontendDisplayTemplateType?: FrontendDisplayTemplateType,
): string[] {
  if (!frontendDisplayTemplateType) {
    return Object.values(CustomModelBackgroundDefaultTemplateCategory);
  }

  const customModelType = getCustomModelTypeFromFrontendDisplayTemplateType(
    frontendDisplayTemplateType,
  );

  switch (customModelType) {
    case CustomModelType.Jewelry:
      return [
        ...Object.values(CustomModelBackgroundJewelryTemplateCategory),
        ...Object.values(CustomModelBackgroundDefaultTemplateCategory),
      ];
    default:
      return [
        ...Object.values(CustomModelBackgroundDefaultTemplateCategory),
        ...Object.values(CustomModelBackgroundJewelryTemplateCategory),
      ];
  }
}

export function isCustomModelBackgroundTemplate(
  background: CustomModelBackgroundTemplate | string,
): background is CustomModelBackgroundTemplate {
  return typeof background === "object" && "category" in background;
}

export const getBackgroundTemplates = (customModelType: CustomModelType) => {
  // First get templates that match the specific customModelType
  const matchingTemplates = backgroundTemplates.filter(
    (template) => template.customModelType === customModelType,
  );

  // Get default templates (those without a specific customModelType)
  const defaultTemplates = backgroundTemplates.filter((template) => !template.customModelType);

  // Get templates for other model types
  const otherTemplates = backgroundTemplates.filter(
    (template) => template.customModelType && template.customModelType !== customModelType,
  );

  // If no customModelType is specified, return all default templates first, then all others
  if (!customModelType) {
    return [...defaultTemplates, ...otherTemplates];
  }

  // Otherwise return matching templates first, then default templates, then other templates
  return [...matchingTemplates, ...defaultTemplates, ...otherTemplates];
};

export const backgroundTemplates: CustomModelBackgroundTemplate[] = [
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Studio,
    shortCaption: "The background is a wooden stool and grey background",
    caption:
      "The background is a solid, light gray surface, providing a neutral and minimalist setting for the central object. A dark walnut wooden stool is positioned in the center of the frame, its simple design and smooth, rounded seat contrasting with the flat, featureless backdrop. The lighting is soft and diffused, illuminating the stool evenly and casting a subtle shadow beneath it, emphasizing its three-dimensional form and creating a sense of depth against the plain background.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d5df5d51-5b7f-4ec7-32b4-eb3bb2cfd600/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Studio,

    shortCaption: "The background is a series of draped, light-colored fabric panels and a rug",
    caption:
      "The background is a series of draped, light-colored fabric panels, creating a sense of depth and a soft, textured backdrop. The fabric cascades down to the floor, forming a pool of material that extends into the foreground, merging with a textured, light-colored rug. The lighting is diffused and even, illuminating the fabric and rug softly, highlighting the folds and textures while casting subtle shadows that add dimension to the scene.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/8c19c92a-ce91-4520-03ee-7b68124f1e00/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Studio,
    shortCaption: "The background is a fluted column and stone steps",
    caption:
      "The background is a textured, light-colored stone wall, providing a neutral backdrop to the architectural elements. A fluted column, positioned on the right, stands prominently in the foreground, casting a shadow that suggests a strong light source from the left. To the left of the ionic column, a set of stone steps ascends diagonally, creating a sense of depth and leading the eye towards the upper left corner of the frame.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b37ccc46-d541-4b84-3891-b72864ab9300/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Studio,
    shortCaption: "The background is a textured, mottled gray surface",
    caption:
      "The background is a textured, mottled gray surface, resembling a painted or plastered wall, creating a sense of depth and a neutral backdrop. The lighting is focused from above, casting a soft glow on the center of the scene while leaving the edges in shadow, emphasizing the texture and creating a vignette effect. The floor mirrors the texture and tone of the background, seamlessly merging with it to form an empty, almost stage-like space ready for a subject.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/66c82a8d-ce17-4276-ab9a-24ea9eb77a00/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Studio,
    shortCaption: "The background is a textured, solid blue fabric",
    caption:
      "The background is a textured, solid blue fabric, draped and folded to create a sense of depth and dimension, resembling a stage backdrop or a studio set. The lighting is diffused and even, washing over the fabric and highlighting its subtle variations in tone and texture, creating a soft, almost painterly effect. The fabric fills the frame, with no other objects present, emphasizing its materiality and the interplay of light and shadow across its surface.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9b6605db-d5a7-4fb1-1ad5-4ee663e45800/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Studio,
    shortCaption: "The background is a soft, dreamy expanse of draped pink fabric",
    caption:
      "The background is a soft, dreamy expanse of draped pink fabric, creating a sense of ethereal space and depth. Delicate floral embellishments are scattered throughout the scene, both attached to and resting amongst the folds of the fabric, adding texture and visual interest. The lighting is gentle and diffused, illuminating the fabric and flowers with a soft glow, highlighting their delicate textures and creating a romantic, almost whimsical atmosphere.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/822f1088-b264-4bfb-e84a-c1ba7e93d300/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Studio,
    shortCaption: "The background is a textured, mottled surface",
    caption:
      "The background is a textured, mottled surface, possibly a painted wall or fabric backdrop, in warm shades of brown and gold, creating a rustic and aged feel. A wooden chair with a slatted backrest is centered in the frame, positioned on a wooden floor that matches the warm tones of the background. The lighting is focused on the chair, highlighting its form and the textures of the wood, while the background is softly illuminated, creating a sense of depth and emphasizing the chair as the focal point.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c74ab7e0-88cf-40fb-583d-c28f7fface00/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Studio,
    shortCaption: "The background is a draped white fabric",
    caption:
      "The background is a draped white fabric, creating soft folds and a sense of depth, serving as a clean and minimalist backdrop. A white wooden stool is centered in the frame, standing on a similarly white fabric-covered surface that blends seamlessly with the backdrop. The lighting is bright and diffused, illuminating the scene evenly and highlighting the textures of the fabric while casting subtle shadows that emphasize the stool's form.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/939ac7a1-dcbc-4251-133c-383e14e9b300/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Studio,
    shortCaption: "The background is a mottled, painterly surface in shades of green",
    caption:
      "The background is a mottled, painterly surface in shades of green, creating a textured and organic backdrop that suggests an outdoor or natural setting. A draped green fabric, possibly velvet, cascades diagonally across the frame from right to left, creating a sense of movement and depth. A small, leafy branch is visible in the upper left corner, adding a touch of botanical detail and reinforcing the natural theme, while the lighting is soft and diffused, highlighting the folds and textures of the fabric and the backdrop.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5c61158a-2ff1-4b5c-be88-97ce2b0fcf00/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Studio,
    shortCaption: "The background is a large, gray concrete wall",
    caption:
      "The background is a large, gray concrete wall, divided into rectangular panels by vertical and horizontal lines, creating a structured and industrial aesthetic. The wall extends down to a tiled floor, also in a light gray tone, with lines mirroring the wall's divisions, seamlessly blending the two surfaces. The lighting is bright and directional, casting a distinct shadow on the lower left side of the wall, highlighting its texture and emphasizing the clean lines and geometric patterns within the scene.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a6fc4014-6905-48d2-0c87-a0cba2ff0a00/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Studio,
    shortCaption: "The background is a light gray fabric",
    caption:
      "The background is a soft sheer light gray, providing a neutral backdrop that highlights the texture and form of the drapery. A large, draped piece of fabric dominates the center, cascading in elegant folds and curves, suggesting a sense of movement and softness. ",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/07a1fb70-91d5-46e8-5a93-a838c854cf00/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Studio,
    shortCaption: "The background is beige with pampas grass",
    caption:
      "The background is a soft, warm beige wall with a subtle texture, casting a gentle shadow that diagonally bisects the frame, highlighting its depth. Fluffy, dried pampas grass-like plumes, in a pale cream color, are arranged in clusters on either side, creating a natural, ethereal frame and adding a tactile, feathery texture against the smooth wall. The overall theme is serene and organic, with a sun-drenched, dreamlike atmosphere enhanced by the soft shadows and the delicate, feathery texture of the plumes against the understated backdrop.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a8b65c62-80c0-4d6d-d4cb-99c98a8dd000/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Display,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "On a white flat disk with beige fabric background",
    caption:
      "The background is a softly draped, beige fabric, providing a textured yet neutral surface for the central composition. A white flat disk sits squarely in the center, catching the gentle light and casting subtle shadows. In the upper half, thin strands of delicate white flowers, queen anne's lace, are slightly blurred, adding a touch of natural elegance and framing the scene with a soft, ethereal touch.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/99394637-77c3-44e3-2f10-be26a7732200/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Hand,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "On a woman's hand angled downward",
    caption: "The background consists of a woman's hand angled downward",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a06fb869-183e-4f5d-d66e-11be13256a00/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Hand,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "On a woman's hand angled upward",
    caption: "The background consists of a woman's hand angled upward",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/56da1a93-b88d-4e8a-9c65-8bc1aa259a00/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Display,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "On a white rock",
    caption:
      "The background is a soft, warm cream tone, creating a serene and minimalist setting for the still life. A smooth, white, pebble-shaped object rests on a flat surface in the foreground, while a blurred, rounded vase and delicate floral sprays occupy the upper left, adding depth and a touch of organic texture. The soft, diffused lighting enhances the gentle curves of the objects and the overall tranquil atmosphere.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/29250260-b4e4-4a7b-f56d-1b51f93a0b00/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Display,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "On a textured, light-colored surface",
    caption:
      "The background is a textured, light-colored surface, possibly a woven mat, providing a natural and warm setting for a single plate. The smooth, off-white plate rests centrally, catching the strong, directional sunlight that casts a sharp shadow beside it, highlighting its simple, elegant form. The interplay of light and shadow creates a dynamic yet minimalist composition, emphasizing the plate's clean lines and subtle sheen.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/68342032-b1c9-4e8d-a3a4-d05ac16dc800/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Display,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "On a white circular platform",
    caption:
      "The background is softly blurred, showcasing dried lavender with its delicate purple hues and textured stems, creating a calming and aromatic atmosphere. A smooth, white, circular platform occupies the foreground, centered and well-lit, providing a clean and minimalist contrast to the natural elements behind it. The overall mood is serene and elegant, with the soft lighting enhancing the textures and creating a visually appealing composition.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e51794bc-57c4-4239-8522-5dc91f153100/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Display,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "On a Carrara marble thin slab",
    caption:
      "The background is softly blurred, showcasing delicate white flowers, Gypsophila, creating a gentle and airy atmosphere. In the foreground, a rectangular Carrara marble thin slab rests on a smooth, reflective surface, its distinct grey veining adding a touch of natural elegance. The soft, diffused lighting highlights the texture of both the marble and the flowers, lending a serene and minimalist aesthetic to the composition.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/43157665-1269-43f1-c628-b407c2ec0000/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Display,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "On a round, off-white plate with white flowers",
    caption:
      "The background is a clean, flat white surface, providing a minimalist and uncluttered setting for the central composition. A round, off-white plate with a rustic, terracotta-edged rim is positioned directly in the center, creating a focal point. Delicate sprigs of tiny white flowers with hints of purple are symmetrically arranged in the upper corners, framing the plate and adding a touch of natural elegance. The bright, even lighting highlights the simple beauty of the objects and creates a serene, airy atmosphere.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1a557f6b-4af3-4219-9517-dfeac2498e00/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Display,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "On a shallow dish",
    caption:
      "The background is a solid, uniform pink, creating a monochromatic and surreal setting that emphasizes the form and color of the central object. A glossy, pink, shallow dish or tray sits in the center of the frame, its reflective surface catching the light and creating a subtle gradient that highlights its three-dimensional shape. The minimalist composition and vibrant color palette evoke a playful and modern aesthetic, with a touch of surrealism.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c198c5d5-8921-40fb-5eef-315ded92c700/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Display,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "On a Shigaraki ware triangle flower vase",
    caption:
      "The background is a soft, light surface, casting a warm shadow that adds depth and texture to the scene. Centered in the frame is a Shigaraki ware triangle flower vase, its smooth, off-white ceramic catching the sunlight and highlighting its unique, geometric form with a circular opening. The strong, directional light emphasizes the vase's simple elegance and the interplay of light and shadow, creating a serene and minimalist aesthetic.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f2c32f56-bf6c-4740-bd42-99e2000abe00/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Display,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "Inside a pale green, ruffled bowl",
    caption:
      "The background is a clean, bright white, providing a stark and minimalist setting that accentuates the delicate form of the ceramic bowl. A pale green, ruffled bowl, reminiscent of a lotus flower, is centered in the frame, its glossy surface reflecting the even lighting and highlighting its organic, undulating edges. The simple composition and soft color palette evoke a sense of tranquility and refined elegance.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1ac6a03e-140c-46ec-f272-33bea53abf00/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Display,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "On a cloud-shaped tray",
    caption:
      "The background is a soft, neutral beige tone, providing a warm and understated setting for the whimsical object. A glossy, white, cloud-shaped tray is positioned centrally, its smooth surface reflecting the even lighting and casting a subtle shadow that accentuates its three-dimensional form. The playful design and clean aesthetic evoke a sense of childlike wonder and modern simplicity.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/17fa80c7-01b0-4006-9db0-dd1575134400/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Display,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "Inside a wooden bowl",
    caption:
      "The background is a clean, bright white, providing a minimalist and uncluttered setting that accentuates the natural beauty of the wooden bowl. A small, round Acacia wood bowl is positioned directly in the center, its warm, rich tones and visible wood grain creating a focal point. The even lighting highlights the smooth curves and simple elegance of the bowl, evoking a sense of rustic charm and understated sophistication.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9881f198-a3c5-4aaf-e6d0-6fff92bdba00/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Background,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "Inside a white, flat circular stone slab",
    caption:
      "The background is a soft, warm cream tone, creating a gentle and inviting canvas that occupies the majority of the frame. Delicate white flowers, clustered in the lower right and upper right corners, appear slightly blurred, adding a dreamy, ethereal quality and framing the open space. The soft, diffused lighting enhances the subtle textures and creates a serene, elegant atmosphere, suggesting a sense of peace and quiet beauty.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/42d7bc48-9dcf-4399-da41-aabf26146300/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Background,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "On a textured, golden surface",
    caption:
      "The background is a textured, golden surface, with shimmering effect, providing a luxurious and opulent backdrop. Centered on this backdrop is a white flat circular stone slab, its round, surface contrasting with the textured background and creating a focal point. The even lighting highlights the disk's subtle variations in tone and texture, while the golden background adds a sense of warmth and grandeur to the composition.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e7665b7a-2c18-48c0-1308-cc01a8c26c00/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Background,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "On a polished pink marble",
    caption:
      "The background is a close-up, textured surface of polished pink marble, showcasing a blend of soft pink hues interwoven with veins of grey, white, and gold. The diagonal lines of the veins create a sense of movement and depth, suggesting a luxurious and elegant material. The even, diffused lighting highlights the natural patterns and subtle variations in the stone, lending a sophisticated and timeless vibe to the image.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f6f66b9e-9058-4ee3-8f0f-8da67ca3aa00/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Background,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "On a polished Calacatta Borghini marble",
    caption:
      "The background is a close-up shot of Calacatta Borghini marble, characterized by its creamy white base and bold veining in grey and gold tones. The diagonal pattern of the veins creates a sense of movement and depth, suggesting a luxurious and elegant surface. The even lighting highlights the natural variations and textures of the stone, lending a sophisticated and timeless vibe to the image.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ea96b945-28aa-4183-d2cf-69fff652f500/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Background,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "On a black obsidian-like stone slab",
    caption:
      "The background is a close-up view of black obsidian-like stone slab. The surface features a mix of deep blacks and grays, with textured patterns that suggest a natural, rugged aesthetic. The even lighting emphasizes the stone's variations and creates a sense of depth and solidity.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/4e5caf04-a6f3-4538-b78a-3d8a16e17400/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Background,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "On a raw concrete slab",
    caption:
      "The background is a close-up, textured surface of raw concrete, displaying a rough, granular texture with visible pores and imperfections. The even, neutral lighting highlights the natural variations and depth of the material, creating a stark, industrial aesthetic. The lack of distinct objects and the focus on the texture evoke a sense of minimalism and raw, unrefined beauty",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/70d1974c-295b-4dc9-3503-59139e7b8600/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Background,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "On a pink satin fabric",
    caption:
      "The background is a close-up shot of pink satin fabric, showcasing its luxurious, smooth texture and soft pink hue. The flowing folds and subtle sheen of the material create a sense of movement and depth, highlighted by the gentle, diffused lighting. The image evokes a feeling of elegance and tactile softness, emphasizing the fabric's rich quality and delicate color.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f186f358-06e9-4a99-3bcc-3dc012f6c300/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Background,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "On a light blue satin fabric",
    caption:
      "The background is a close-up shot of light blue satin fabric, showcasing its smooth, lustrous texture and flowing folds. The soft, diffused lighting highlights the subtle sheen and depth of the material, creating a sense of luxury and fluidity. The intricate draping of the fabric fills the frame, evoking a feeling of gentle movement and serene elegance.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c0ee2c68-4838-4917-721a-7276ccbe8700/1024",
  },
  {
    category: CustomModelBackgroundJewelryTemplateCategory.Background,
    customModelType: CustomModelType.Jewelry,
    shortCaption: "On a pale fabric",
    caption:
      "The background is a close-up, textured surface of pale fabric, showcasing its soft, flowing folds and subtle sheen. The warm, diffused lighting highlights the delicate curves and gentle movement of the material, creating a sense of luxury and tactile softness. The intricate draping of the fabric fills the frame, evoking a feeling of gentle movement and serene elegance.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/648ed9a0-5b5b-4e98-0986-6973cf116000/1024",
  },
  {
    shortCaption: "The background is grey",
    category: CustomModelBackgroundDefaultTemplateCategory.Studio,
    caption:
      "The background is a large, seamless backdrop of a soft, muted gray color. The floor is also a matching gray color, providing a neutral surface for photography or videography. The image suggests a minimalist and modern aesthetic.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/3a619d10-ad5a-4b9e-b397-c02a43cb6f00/1024",
  },
  {
    shortCaption: "The background is white",
    category: CustomModelBackgroundDefaultTemplateCategory.Studio,
    caption:
      "The background is a large white backdrop smooth and unblemished, ready to showcase any subject or product. The overall impression is one of simplicity and versatility.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/8694e714-24e1-44c0-31cf-955c31e61500/1024",
  },
  {
    shortCaption: "The background is a balcony and ocean",
    category: CustomModelBackgroundDefaultTemplateCategory.Outdoor,
    caption:
      "The background is a breathtaking panoramic view of a sparkling sea stretching towards a distant horizon. The water shimmers under a bright blue sky, punctuated by wispy clouds. The scene is framed by a series of elegant arches supported by intricately carved columns, creating a sense of grandeur and tranquility.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e8110fee-499d-4b4d-9019-2b647d349b00/1024",
  },
  {
    shortCaption: "The background is a japanese garden",
    category: CustomModelBackgroundDefaultTemplateCategory.Outdoor,
    caption:
      "The background is a serene Japanese garden, where meticulously manicured shrubs and trees create a sense of harmony and balance. A tranquil pond reflects the lush greenery, while carefully placed rocks add visual interest. The overall atmosphere is one of peace and tranquility.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/3cfc27cb-7e30-42a7-cf05-f6ed1811fa00/1024",
  },
  {
    shortCaption: "The background is a green couch with white background",
    category: CustomModelBackgroundDefaultTemplateCategory.Studio,
    caption:
      "The background is a plain white surface that allows the rich emerald green of the velvet sofa to truly pop. The sofa itself has a simple, modern design with clean lines and tapered wooden legs. The plush cushions and deep color suggest comfort and elegance.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a08e42d4-b6d8-4644-b0aa-8ff803e04200/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Outdoor,
    shortCaption: "The background is a dense forest",
    caption:
      "The background is a dense forest, where tall trees stand closely together, their trunks forming a vertical tapestry of brown and green. Sunlight filters through the canopy, casting dappled shadows on the lush green undergrowth. The air feels thick with the scent of damp earth and fresh leaves. It is a scene of tranquility and natural beauty.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d1128af7-832f-4d1e-d76a-69a12a329d00/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Indoor,
    shortCaption: "The background is a long, arched hallway",
    caption:
      "The background is a long, arched hallway with a stone ceiling and floor. Light streams in through tall, narrow windows along one side, casting a warm glow on the pale stone walls. The hallway extends into the distance, creating a sense of perspective and grandeur.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f63cfeff-e76e-4c36-94ee-2e8a300ff000/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Outdoor,
    shortCaption: "The background is a long, arched pergola",
    caption:
      "The background is a long, arched pergola draped in a riot of vibrant purple wisteria blossoms. The flowers create a fragrant, living ceiling, casting dappled shadows on the stone floor below. The walkway extends into the distance, inviting the viewer to step into this enchanting floral wonderland.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d472a3dc-e202-4fc3-0287-c3e5e1247c00/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Outdoor,
    shortCaption: "The background is two towering brick walls",
    caption:
      "The background is formed by two towering brick walls that converge towards a vanishing point in the distance. The walls are a deep, earthy red, and their uniform texture creates a sense of repetition and monotony. A narrow brick pathway runs between the walls, inviting the viewer to step into the unknown and explore this seemingly endless corridor.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/2cfa79e5-5975-48aa-9321-e13140774300/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Outdoor,
    shortCaption: "The background is rolling hills and mountains under a stormy sky",
    caption:
      "The background is a dramatic scene of rolling hills and mountains under a stormy sky. The sky is heavy with dark, swirling clouds, casting a moody light over the landscape. Lush green hills stretch towards the horizon, punctuated by a wooden fence and gate leading into a field of tall grass. The overall impression is one of wild beauty and untamed nature.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d17f452b-7d83-4acf-88b2-a133f3fdfa00/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Outdoor,
    shortCaption: "The background is a grand, yellow mansion",
    caption:
      "The background is a lush green lawn that stretches out in front of a grand, yellow mansion. The mansion boasts a symmetrical facade with multiple stories, a prominent central entrance, and a red-tiled roof.  A pair of statues flank the entrance, adding to the stately appearance of the building. The overall impression is one of elegance and grandeur.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b5807dee-a4b1-433f-ae0c-bd38e9ce2300/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Outdoor,
    shortCaption: "The background is a bustling city street at night",
    caption:
      "The background is a bustling city street at night, with a long exposure creating streaks of light from passing cars. The scene is framed by a concrete overpass, casting a long shadow over the sidewalk and creating a sense of depth and perspective. The overall atmosphere is one of urban energy and movement.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/0110a38e-9d12-4026-3e08-b6611517f400/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Outdoor,
    shortCaption: "The background is a bustling city street",
    caption:
      "The background is a bustling city street lined with tall, brownstone buildings. The sun is setting, casting a warm glow over the scene. Cars and pedestrians navigate the crosswalk, creating a sense of urban energy and movement. The overall impression is one of a vibrant and dynamic city.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b48ab73a-413f-4190-9194-d25bbbd9cd00/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Outdoor,
    shortCaption: "The background is a vast, golden savanna",
    caption:
      "The background is a vast, golden savanna stretching out to the horizon under a sky painted with hues of orange and purple. A lone acacia tree stands tall in the foreground, casting a long shadow across the grassland. In the distance, rolling hills and other acacia trees dot the landscape, creating a sense of vastness and tranquility.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/8ca41a24-dfb4-452a-66b2-5e43eddb9a00/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Outdoor,
    shortCaption: "The background is a clear, vibrant blue sky",
    caption:
      "The background is a clear, vibrant blue sky that stretches upwards, contrasting dramatically with the rugged terrain below. The foreground is dominated by a rocky, arid landscape, with a steep, rocky mountainside rising up towards the horizon. The mountainside is a patchwork of gray and brown rock, with hints of yellow and orange visible in the crevices.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ced592f6-bf6b-4b12-455f-f0276b44dd00/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Outdoor,
    shortCaption: "The background is a small, intimate café",
    caption:
      "The background is a bustling city street at night, with warm lamplight spilling onto the pavement from the windows of a nearby café. The scene is framed by a small, intimate café table and chairs set out on the sidewalk, creating a cozy and inviting atmosphere. The table is adorned with a vase of delicate pink flowers, adding a touch of elegance to the scene.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/068489e9-5836-47a1-cfe2-2172b17ba500/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Outdoor,
    shortCaption: "The background is a grand mansion",
    caption:
      "The background is a lush green lawn, dotted with manicured hedges. In the foreground, a section of a grand mansion is showcased. The building boasts a series of elegant arched windows, framed by fluted columns and intricate stonework. The overall impression is one of timeless elegance and refined taste.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/eceaa9f8-626f-4f0d-d2de-feedc9712b00/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Indoor,
    shortCaption: "The background is a dimly lit barn interior",
    caption:
      "The background is a dimly lit barn interior, with wooden beams and walls creating a rustic atmosphere. The scene is illuminated by shafts of sunlight streaming through the wide-open double doors. Inside, stacks of golden hay bales are piled against the walls, casting long shadows across the dusty floor. The overall impression is one of warmth, tranquility, and a connection to rural life.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c58db629-76ae-48a8-027a-75e440014e00/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Indoor,
    shortCaption: "The background is a modern, open-plan office space",
    caption:
      "The background is a modern, open-plan office space with a minimalist aesthetic. Large windows line the walls, flooding the room with natural light. Exposed concrete ceilings and columns add a touch of industrial charm. A collection of colorful chairs and tables are arranged around a central workspace, creating a vibrant and collaborative atmosphere.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a342b089-bfc4-446c-4825-f3c5a6097000/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Indoor,
    shortCaption: "The background is a dimly lit, opulent room",
    caption:
      "The background is a dimly lit, opulent room with a high ceiling adorned with a sparkling chandelier.  The walls are covered in ornate wallpaper and paintings, while tall windows are draped with heavy curtains. The overall atmosphere is one of grandeur and old-world luxury.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/44cab83a-aa8b-48a0-ab82-9e7cfaefad00/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Outdoor,
    shortCaption: "The background is a dimly lit alleyway with tall buildings on either side",
    caption:
      "The background is a dimly lit alleyway with tall buildings on either side. The buildings are covered in graffiti and cast long shadows in the darkness. A single streetlight illuminates the cobblestone path, creating a contrast of light and shadow. The overall atmosphere is eerie and mysterious.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d3919fc0-21c6-4ca3-ed80-c48688a4f600/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Studio,
    shortCaption: "The background is black",
    caption:
      "The background is a dark, featureless void, hinting at the infinite possibilities that await within this space. The overall atmosphere is one of anticipation and creative potential.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d9d3902e-d8fc-484d-0a7b-c1f02d954a00/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Studio,
    shortCaption: "The background is white with a red leather sofa",
    caption:
      "The background is a plain white surface that allows the rich red of the leather sofa to truly pop. The sofa itself has a classic Chesterfield design with deep buttoning and rolled arms. The overall impression is one of timeless elegance and luxurious comfort.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b931a6ad-fbfa-4a5e-43a6-7f8f98e9e100/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Outdoor,
    shortCaption: "The background is a vast, stormy sky and sea",
    caption:
      "The background is a vast, stormy sky, heavy with dark clouds that cast dramatic shadows across the landscape. The horizon line blends seamlessly into the swirling grey water, creating a sense of mystery and vastness. A desolate beach stretches towards the viewer, littered with driftwood and seaweed, adding to the atmosphere of wildness and untamed beauty.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c13f3638-72f8-4d0c-714c-47e7e97ece00/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Studio,
    shortCaption: "The background is brown with a brown leather loveseat",
    caption:
      "The background is a solid, warm beige wall, providing a neutral backdrop that highlights the centerpiece of the image—a brown leather loveseat with tufted cushions and rolled arms, positioned centrally and facing the viewer. The loveseat, with its detailed studs and the natural creases of the leather, suggests a classic, comfortable design, while the soft, directional sunlight from a window on the left casts shadows that emphasize its three-dimensionality and the texture of the wooden floorboards beneath it. The overall theme is one of simple elegance and comfort, with a serene and inviting atmosphere created by the warm color palette and the natural lighting.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b0908dd0-db5a-41dc-3950-268ac633c900/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Indoor,
    shortCaption: "The background is a central, sweeping marble staircase",
    caption:
      "The background is a grand, symmetrical hall with a central, sweeping marble staircase that curves gracefully upward, forming a near-perfect circle when viewed from the front. The staircase, with its smooth, polished texture and classic balustrades, leads the eye towards a glass-paneled ceiling, suggesting a spacious and well-lit interior, while ornate architectural details like arched doorways and decorative molding add to the sense of opulence and historical grandeur. The overall theme is one of classical elegance and architectural magnificence, with a serene and majestic atmosphere created by the symmetry, the cool tones of the marble, and the soft, diffused light filtering through the overhead glass panels.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d1e8dd87-7001-47e5-50e2-eb5482291c00/1024",
  },
  {
    category: CustomModelBackgroundDefaultTemplateCategory.Indoor,
    shortCaption: "The background is a cafe",
    caption:
      "The background is a cozy, sunlit cafe interior with a warm, inviting atmosphere, featuring wooden furniture and soft, tufted seating that suggests comfort and relaxation. A square wooden table is centered in the foreground, adorned with a small vase of flowers and two cups of coffee, acting as a focal point and inviting the viewer to imagine themselves seated there. The space is filled with details like a bookshelf in the back, framed artwork, and decorative lamps, all contributing to a lived-in, charming aesthetic that balances rustic and elegant elements.",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1907a30c-d4ef-49c7-7105-4cda65b3d500/1024",
  },
];
