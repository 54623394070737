import { BaseImage } from "@ando-editor/ui-components";
import React, { useEffect, useState } from "react";
const authImages = [
  {
    name: "Maroon Dress",
    category: "Fashion",
    generatedImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/3ee18fa2-c63d-40a3-3566-66e9e4ad6c00/1024",
    datasetImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/506c8e3f-dce3-49eb-1e54-05f95412de00/1024",
  },
  {
    name: "Pink Women's Blazer",
    category: "Fashion",
    generatedImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1abe1e5c-9bce-4141-5662-a9b605d5f100/1024",
    datasetImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/76b8eeb9-b365-4047-ec5e-2afe68877b00/1024",
  },
  {
    name: "Blue Wool Coat",
    category: "Fashion",
    generatedImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/18659716-f931-4ab8-b557-f9b391ea8000/1024",
    datasetImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1c1099a9-1da2-45c6-8668-ef9ba1dc8800/1024",
  },
  {
    name: "Topaz Ring",
    category: "Jewelry",
    generatedImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f378d413-f696-4651-b4be-bfa25bdd3700/1024",
    datasetImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/41a3b6d1-7086-4260-eb0a-f4f842a4a900/1024",
  },
  {
    name: "Waist Wrap",
    category: "Fashion",
    generatedImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/7850b31b-2556-4f6f-31e3-9dc1ae4b8b00/1024",
    datasetImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/09a45f2e-7a75-4f04-6188-9d69eb2e2600/1024",
  },
  {
    name: "Men's Denim Shirt",
    category: "Men's Fashion",
    generatedImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/626565b6-54c3-4eca-092f-077acf6eb900/1024",
    datasetImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/699a3bd2-cff6-464f-bcd4-6aab8d867700/1024",
  },
  {
    name: "Green Hat",
    category: "Hat",
    generatedImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f27a5d94-b7b9-4914-d979-8a50c6186c00/1024",
    datasetImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5634ea8e-05bd-41d5-f1d3-08078ad55900/1024",
  },
  {
    name: "Women's Brown Coat",
    category: "Fashion",
    generatedImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/8313463f-0570-4ca5-d6f4-5d7568b25600/1024",
    datasetImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d26392d0-9009-4aa9-9dfc-2e6bb0a20900/1024",
  },
  {
    name: "Woman's Black Coat",
    category: "Fashion",
    generatedImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ed0997b6-0f31-42a1-6768-b66f17683600/1024",
    datasetImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d71bb275-8d68-4689-d509-3eeb49c86800/1024",
  },
  {
    name: "Lace Wedding Dress",
    category: "Fashion",
    generatedImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e7e4f970-f9a6-43f4-b469-f5dfe1e3a700/1024",
    datasetImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e76e4ff0-f8ef-4a5f-e3a6-17e95e45da00/1024",
  },
  {
    name: "Toddler Halloween Costume",
    category: "Fashion",
    generatedImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f5fb922c-b384-41e0-2280-356c18fa4000/1024",
    datasetImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/05253a24-081c-421a-e28a-7088ec751100/1024",
  },
  {
    name: "Green Bomber Jacket",
    category: "Fashion",
    generatedImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9213f527-a173-4a27-4974-e6aaaeba3400/1024",
    datasetImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/8671771b-040f-4a46-0dca-6394978f2200/1024",
  },
  {
    name: "Plush Swivel Chair",
    category: "Furniture",
    generatedImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/73a2565b-4ea7-4df3-3086-d83de874c600/1024",
    datasetImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ee424bbd-47d6-456d-e65e-d1b52471da00/1024",
  },
  {
    name: "Blue Dog Sweater",
    category: "Fashion",
    generatedImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a5a4adf5-8035-4e2e-537e-2d54fb340900/1024",
    datasetImageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/3e08f2a4-82b7-45ff-0915-e0b960579600/1024",
  },
];

export function FullScreenAuthContainer({
  children,
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div className="w-screen h-screen flex justify-center items-center bg-zinc-900 text-zinc-500 overflow-y-auto">
      <div
        className={`w-[90%] md:w-[500px] px-8 md:px-12 py-6 rounded-lg flex flex-col bg-zinc-800/50 border border-zinc-700/30 items-center shadow-lg box-border ${className}`}
        {...props}
      >
        {children}
      </div>
    </div>
  );
}

export function Caption({ currentImageIndex }: { currentImageIndex: number }) {
  return (
    <div className="flex items-center min-w-[300px] gap-3 bg-zinc-800/80 rounded-lg p-4">
      <div className="w-20 h-20 rounded-full overflow-hidden">
        <BaseImage
          src={authImages[currentImageIndex].datasetImageUrl}
          alt="Dataset image"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="flex flex-col">
        <div className="text-zinc-300 text-base font-bold">
          {authImages[currentImageIndex].name}
        </div>
        <div className="text-zinc-300 text-sm font-semibold">
          {authImages[currentImageIndex].category}
        </div>
        <div className="text-zinc-300 text-sm italic font-base">Generated on Flair AI</div>
      </div>
    </div>
  );
}

export function SplitScreenAuthContainer({
  children,
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * authImages.length);
    setCurrentImageIndex(randomIndex);
  }, []);

  return (
    <div className="w-screen h-screen flex bg-zinc-900 text-zinc-500 overflow-hidden">
      <div className="hidden lg:flex lg:w-1/2 h-full justify-center items-center relative">
        <img
          key={currentImageIndex}
          src={authImages[currentImageIndex].generatedImageUrl}
          alt={authImages[currentImageIndex].name}
          className="object-cover h-full w-full"
        />
        <div className="absolute bottom-4 left-4 text-zinc-300 text-sm">
          <Caption currentImageIndex={currentImageIndex} />
        </div>
      </div>
      <div className="w-full lg:w-1/2 h-full flex justify-center items-center">
        <div
          className={`w-[90%] md:w-[500px] px-8 md:px-12 py-6 rounded-lg flex flex-col bg-zinc-800/50 border border-zinc-700/30 items-center shadow-lg box-border ${className}`}
          {...props}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
