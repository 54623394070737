import { AppRoleType } from "@/core/common/types";
import {
  GetImageEditSuggestionsArgs,
  GetImageEditSuggestionsResponse,
  ImageEditSuggestionDoc,
  isImageEditSuggestionDoc,
} from "@/core/common/types/image-edit-suggestion";
import {
  ImageEditorEntrypointAction,
  ImageEditorPastGeneration,
  ImageEditorProject,
  isImageEditorPastGeneration,
  isImageEditorProject,
  StorageAssetImageEditorPastGeneration,
} from "@/core/common/types/image-editor";
import { isPublicTeamId, PublicTeamId } from "@/core/common/types/public-team-id";
import { removeUndefinedFromObject } from "@/core/utils/object-utils";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { getUserAssetIdFromPath } from "@/core/utils/storage-path-utils";
import { sortByTimeModified } from "@/core/utils/time-utils";
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { Functions, httpsCallable, HttpsCallable } from "firebase/functions";
import { IMAGE_EDITOR_ENTRYPOINT } from "./firebase-function-name";

/**
 * Firestore constants for this manager.
 * Adjust as needed if your backend uses different values.
 */
export const IMAGE_EDITOR_PROJECTS_COLLECTION_NAME = "imageEditorProjects";
export const IMAGE_EDITOR_PAST_GENERATIONS_COLLECTION_NAME = "imageEditorPastGenerations";
export const IMAGE_EDITOR_SUGGESTIONS_COLLECTION_NAME = "imageEditorSuggestions";

/** Helpers to get Firestore references for projects and past generations. */
function getImageEditorProjectsCollectionRef(firestore: Firestore) {
  return collection(firestore, IMAGE_EDITOR_PROJECTS_COLLECTION_NAME);
}

function getImageEditorProjectDocRef({
  firestore,
  projectId,
}: {
  firestore: Firestore;
  projectId: string;
}) {
  return doc(getImageEditorProjectsCollectionRef(firestore), projectId);
}

function getImageEditorPastGenerationsCollectionRef(firestore: Firestore) {
  return collection(firestore, IMAGE_EDITOR_PAST_GENERATIONS_COLLECTION_NAME);
}

function getNewImageEditorPastGenerationDocRef({ firestore }: { firestore: Firestore }) {
  return doc(getImageEditorPastGenerationsCollectionRef(firestore));
}

function getImageEditorPastGenerationDocRef({
  firestore,
  pastGenerationId,
}: {
  firestore: Firestore;
  pastGenerationId: string;
}) {
  return doc(getImageEditorPastGenerationsCollectionRef(firestore), pastGenerationId);
}

/**
 * For the onSnapshot list queries, we can gather docs into a dictionary.
 */
function getImageEditorProjectsFromDocs<T>(
  docs: QueryDocumentSnapshot<T>[],
): Record<string, ImageEditorProject> {
  const output: Record<string, ImageEditorProject> = {};
  docs.forEach((snap) => {
    const data = snap.data();
    if (isImageEditorProject(data)) {
      output[snap.id] = data;
    }
  });
  return output;
}

function getImageEditorPastGenerationsFromDocs<T>(
  docs: QueryDocumentSnapshot<T>[],
): Record<string, ImageEditorPastGeneration> {
  const output: Record<string, ImageEditorPastGeneration> = {};
  docs.forEach((snap) => {
    const data = snap.data();
    if (isImageEditorPastGeneration(data)) {
      output[snap.id] = data;
    }
  });
  return output;
}

// Image edit suggestions

/**
 * Returns a CollectionReference to all image editor suggestions.
 *
 * @param firestore - Firestore instance
 */
export function getImageEditorSuggestionsCollectionRef(
  firestore: Firestore,
): CollectionReference<ImageEditSuggestionDoc> {
  return collection(
    firestore,
    IMAGE_EDITOR_SUGGESTIONS_COLLECTION_NAME,
  ) as CollectionReference<ImageEditSuggestionDoc>;
}

/**
 * Returns a DocumentReference for a specific image editor suggestion doc.
 *
 * @param firestore - Firestore instance
 * @param imageEditorSuggestionId - The ID of the suggestion doc
 */
export function getImageEditorSuggestionDocRef({
  firestore,
  imageEditorSuggestionId,
}: {
  firestore: Firestore;
  imageEditorSuggestionId: string;
}): DocumentReference<ImageEditSuggestionDoc> {
  return doc(
    getImageEditorSuggestionsCollectionRef(firestore),
    imageEditorSuggestionId,
  ) as DocumentReference<ImageEditSuggestionDoc>;
}

/**
 * Given an array of QueryDocumentSnapshots, filters/validates them into a
 * Record of { [docId]: ImageEditSuggestionDoc } if they pass the type guard.
 *
 * @param docs - Array of Firestore snapshots
 */
export function imageEditorSuggestionsFromDocs<T>(
  docs: QueryDocumentSnapshot<T>[],
): Record<string, ImageEditSuggestionDoc> {
  const output: Record<string, ImageEditSuggestionDoc> = {};
  docs.forEach((snap) => {
    const data = snap.data();
    if (isImageEditSuggestionDoc(data)) {
      output[snap.id] = data;
    }
  });
  return output;
}

/**
 * Generates a Firestore query for the user/team's suggestions, if needed.
 * Adjust query constraints (e.g. publicTeamId) as your rules require.
 */
function getUserImageEditorSuggestionsQuery({
  firestore,
  publicTeamId,
}: {
  firestore: Firestore;
  publicTeamId: string; // or PublicTeamId if you prefer
}) {
  const queryConstraints: QueryConstraint[] = [
    where("publicTeamId", "==", publicTeamId),
    // Optionally filter out isDeleted = true
    // where("isDeleted", "==", false),
  ];
  return query(getImageEditorSuggestionsCollectionRef(firestore), ...queryConstraints);
}

/**
 * Fetches all image-editor suggestions for a particular team/user, once.
 */
export async function getUserImageEditorSuggestions({
  firestore,
  publicTeamId,
}: {
  firestore: Firestore;
  publicTeamId: string;
}): Promise<Record<string, ImageEditSuggestionDoc>> {
  try {
    const snap = await getDocs(getUserImageEditorSuggestionsQuery({ firestore, publicTeamId }));
    return imageEditorSuggestionsFromDocs(snap.docs);
  } catch (err) {
    debugError("Error fetching image editor suggestions: ", err);
    return {};
  }
}

/**
 * Real-time listener for all suggestions matching the user/team query.
 * Returns an unsubscribe function. The callback is invoked with a dictionary
 * of suggestions keyed by doc ID.
 */
export function onUserImageEditorSuggestionsUpdate({
  firestore,
  publicTeamId,
  callback,
}: {
  firestore: Firestore;
  publicTeamId: string;
  callback: (suggestions: Record<string, ImageEditSuggestionDoc>) => void;
}): () => void {
  return onSnapshot(getUserImageEditorSuggestionsQuery({ firestore, publicTeamId }), (snap) => {
    callback(imageEditorSuggestionsFromDocs(snap.docs));
  });
}

/**
 * Fetch a single suggestion doc by ID (once).
 */
export async function getImageEditorSuggestion({
  firestore,
  imageEditorSuggestionId,
}: {
  firestore: Firestore;
  imageEditorSuggestionId: string;
}): Promise<ImageEditSuggestionDoc | undefined> {
  try {
    const ref = getImageEditorSuggestionDocRef({
      firestore,
      imageEditorSuggestionId,
    });
    const snap = await getDoc(ref);
    const data = snap.data();
    return isImageEditSuggestionDoc(data) ? data : undefined;
  } catch (error) {
    debugError(`[getImageEditorSuggestion] Error: `, error);
    return undefined;
  }
}

/**
 * Real-time listener for a single suggestion doc by ID.
 * Returns an unsubscribe function. The callback is invoked whenever the
 * doc changes or is deleted.
 */
export function onImageEditorSuggestionUpdate({
  firestore,
  imageEditorSuggestionId,
  callback,
}: {
  firestore: Firestore;
  imageEditorSuggestionId: string;
  callback: (doc: ImageEditSuggestionDoc | undefined) => void;
}): () => void {
  const ref = getImageEditorSuggestionDocRef({
    firestore,
    imageEditorSuggestionId,
  });
  return onSnapshot(ref, (snap) => {
    const data = snap.data();
    callback(isImageEditSuggestionDoc(data) ? data : undefined);
  });
}

/**
 * Updates a suggestion doc with the given partial fields.
 * Firestore security rules typically restrict which fields can be updated.
 */
export async function updateImageEditorSuggestion({
  firestore,
  imageEditorSuggestionId,
  updateData,
}: {
  firestore: Firestore;
  imageEditorSuggestionId: string;
  updateData: Partial<ImageEditSuggestionDoc>;
}): Promise<{ ok: boolean; message: string }> {
  try {
    // Typically, we set timeModified to the server timestamp:
    updateData.timeModified = serverTimestamp() as Timestamp;

    const ref = getImageEditorSuggestionDocRef({
      firestore,
      imageEditorSuggestionId,
    });

    await updateDoc(ref, removeUndefinedFromObject(updateData));

    return {
      ok: true,
      message: "OK",
    };
  } catch (error) {
    debugError(`Error updating image editor suggestion doc: ${imageEditorSuggestionId}: `, error);
    return {
      ok: false,
      message: `Cannot update suggestion doc ${imageEditorSuggestionId}.`,
    };
  }
}

export interface CreateNewImageEditorProjectArgs {
  publicTeamId: PublicTeamId;
  displayName?: string;
  tags?: string[];
  sourceImageStoragePath: string;
}

export type CreateNewImageEditorProjectResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      message: string;
      projectId: string;
    };

export interface ImageEditorEntrypointCreateNewImageEditorProjectInput {
  type: ImageEditorEntrypointAction.CreateNewImageEditorProject;
  args: CreateNewImageEditorProjectArgs;
}

export interface ImageEditorEntrypointSuggestImageEditsInput {
  type: ImageEditorEntrypointAction.SuggestImageEdits;
  args: GetImageEditSuggestionsArgs;
}

export type ImageEditorEntrypointInput =
  | ImageEditorEntrypointCreateNewImageEditorProjectInput
  | ImageEditorEntrypointSuggestImageEditsInput;

export type ImageEditorEntrypointResponse =
  | CreateNewImageEditorProjectResponse
  | GetImageEditSuggestionsResponse;

export interface GetUserImageEditorProjectsInput {
  publicUserId: string;
  publicTeamId: PublicTeamId;
}

export type GetUserImageEditorProjectsResponse = Record<string, ImageEditorProject>;

export interface OnUserImageEditorProjectsUpdateInput {
  publicUserId: string;
  publicTeamId: PublicTeamId;
  callback: (projects: Record<string, ImageEditorProject>) => void;
}

export type OnImageEditorProjectsUpdateReponse = () => void;

export interface OnImageEditorProjectUpdateInput {
  projectId: string;
  callback: (project: ImageEditorProject | undefined) => void;
}

export type OnImageEditorProjectUpdateReponse = () => void;

export interface UpdateImageEditorProjectInput {
  projectId: string;
  updateData: Partial<ImageEditorProject>;
}

export type UpdateImageEditorProjectResponse = {
  ok: boolean;
  message: string;
};

export interface DeleteImageEditorProjectInput {
  projectId: string;
}

export type DeleteImageEditorProjectResponse = UpdateImageEditorProjectResponse;

export interface GetImageEditorProjectPastGenerationsInput {
  projectId: string;
}

export type GetImageEditorProjectPastGenerationsResponse = Record<
  string,
  ImageEditorPastGeneration
>;
export interface GetImageEditorPastGenerationsWithStoragePathInput {
  storagePath: string;
  projectId: string;
}

export type GetImageEditorPastGenerationsWithStoragePathResponse = Record<
  string,
  ImageEditorPastGeneration
>;

export interface GetImageEditorSourcePastGenerationInput {
  projectId: string;
}

export type GetImageEditorSourcePastGenerationResponse = ImageEditorPastGeneration | undefined;

export interface UpdateImageEditorSourcePastGenerationInput {
  projectId: string;
  pastGeneration: Partial<StorageAssetImageEditorPastGeneration>;
}

export type UpdateImageEditorSourcePastGenerationResponse = void;

export interface GetImageEditorProjectInput {
  projectId: string;
}
export type GetImageEditorProjectResponse = ImageEditorProject | undefined;

export interface OnImageEditorProjectPastGenerationsUpdateInput {
  projectId: string;
  callback: (generations: Record<string, ImageEditorPastGeneration>) => void;
}
export type OnImageEditorProjectPastGenerationsUpdateResponse = () => void;

export interface GetImageEditorPastGenerationInput {
  pastGenerationId: string;
}
export type GetImageEditorPastGenerationResponse = ImageEditorPastGeneration | undefined;

export interface OnImageEditorPastGenerationUpdateInput {
  pastGenerationId: string;
  callback: (pg: ImageEditorPastGeneration | undefined) => void;
}

export type OnImageEditorPastGenerationUpdateResponse = () => void;

export interface UpdateImageEditorPastGenerationInput {
  pastGenerationId: string;
  updateData: Partial<ImageEditorPastGeneration>;
}
export type UpdateImageEditorPastGenerationResponse = void;

export interface DeleteImageEditorPastGenerationInput {
  pastGenerationId: string;
}

export type DeleteImageEditorPastGenerationResponse = void;

export interface CreateImageEditorPastGenerationInput {
  pastGeneration: Omit<ImageEditorPastGeneration, "timeCreated" | "timeModified" | "id">;
}

export interface CreateImageEditorPastGenerationResponse {
  ok: boolean;
  message: string;
  pastGenerationId?: string;
}

export interface GetImageEditorProjectsByAssetStoragePathInput {
  assetStoragePath: string;
}

export type GetImageEditorProjectsByAssetStoragePathResponse = {
  id: string;
  project: ImageEditorProject;
}[];

export interface GetImageEditorProjectsBySourceImageStoragePathInput {
  sourceImageStoragePath: string;
}

export type GetImageEditorProjectsBySourceImageStoragePathResponse = {
  id: string;
  project: ImageEditorProject;
}[];

/**
 * Manages image editor projects and their past generations in Firestore,
 * plus calls the Cloud Function to create new ones, etc.
 */
export class ImageEditorManager {
  private firestore: Firestore;
  private firebaseFunctions: Functions;
  private suggestImageEditsPromises: Record<string, Promise<GetImageEditSuggestionsResponse>> = {};

  private imageEditorEntrypointCallable: HttpsCallable<
    ImageEditorEntrypointInput,
    ImageEditorEntrypointResponse
  >;

  constructor({
    firestore,
    firebaseFunctions,
  }: {
    firestore: Firestore;
    firebaseFunctions: Functions;
  }) {
    this.firestore = firestore;
    this.firebaseFunctions = firebaseFunctions;
    this.imageEditorEntrypointCallable = httpsCallable(
      this.firebaseFunctions,
      IMAGE_EDITOR_ENTRYPOINT,
    );
  }

  /**
   * Calls the backend function to create a new image editor project.
   */
  async createNewImageEditorProject(
    input: CreateNewImageEditorProjectArgs,
  ): Promise<CreateNewImageEditorProjectResponse> {
    try {
      const response = await this.imageEditorEntrypointCallable({
        type: ImageEditorEntrypointAction.CreateNewImageEditorProject,
        args: input,
      });
      return response.data as CreateNewImageEditorProjectResponse;
    } catch (error) {
      debugError(`Error creating image editor project: ${error}`, "error");
      return {
        ok: false,
        message: "Error creating image editor project.",
      };
    }
  }

  private async suggestImageEditsInternal(
    input: GetImageEditSuggestionsArgs,
  ): Promise<GetImageEditSuggestionsResponse> {
    try {
      // Load image suggestion
      const assetId = getUserAssetIdFromPath(input.imageStoragePath);
      if (!assetId) {
        return {
          ok: false,
          message: `Cannot find asset from storage path ${input.imageStoragePath}.`,
        };
      }

      const suggestion = await this.getImageEditorSuggestion({
        imageEditorSuggestionId: assetId,
      });

      if (suggestion) {
        return {
          ok: true,
          message: "OK",
          suggestion,
        };
      }

      const response = await this.imageEditorEntrypointCallable({
        type: ImageEditorEntrypointAction.SuggestImageEdits,
        args: input,
      });

      return response.data as GetImageEditSuggestionsResponse;
    } catch (error) {
      debugError(`[suggestImageEdits] Error suggest image edits: `, error);
      return {
        ok: false,
        message: "Error suggesting image edits for asset.",
      };
    }
  }

  async suggestImageEdits(
    input: GetImageEditSuggestionsArgs,
  ): Promise<GetImageEditSuggestionsResponse> {
    try {
      const key = JSON.stringify(input);

      const existingPromise = this.suggestImageEditsPromises[key];

      if (existingPromise != null) {
        return existingPromise;
      }

      const newPromise = this.suggestImageEditsInternal(input);

      this.suggestImageEditsPromises[key] = newPromise;

      return newPromise;
    } catch (error) {
      debugError(`[suggestImageEdits] Error suggest image edits: `, error);
      return {
        ok: false,
        message: "Error suggesting image edits for asset.",
      };
    }
  }

  // -------------------------------------------------------------------------
  // Project queries / updates
  // -------------------------------------------------------------------------

  /**
   * Query constraint for "all projects that belong to a team" or "all projects
   * with a role for a given publicUserId" (similar to custom-model approach).
   */
  private getUserImageEditorProjectsQuery({
    publicUserId,
    publicTeamId,
  }: {
    publicUserId: string;
    publicTeamId: PublicTeamId;
  }) {
    const queryConstraints: QueryConstraint[] = [];
    if (isPublicTeamId(publicTeamId)) {
      // Team-based query
      queryConstraints.push(where("publicTeamId", "==", publicTeamId));
    } else {
      // Role-based fallback: roles.<publicUserId> in [owner, writer, commenter, reader]
      queryConstraints.push(where(`roles.${publicUserId}`, "in", Object.values(AppRoleType)));
    }
    return query(getImageEditorProjectsCollectionRef(this.firestore), ...queryConstraints);
  }

  /**
   * Fetch all image-editor projects for a user/team combination, once.
   */
  async getUserImageEditorProjects(
    input: GetUserImageEditorProjectsInput,
  ): Promise<GetUserImageEditorProjectsResponse> {
    try {
      const snap = await getDocs(
        this.getUserImageEditorProjectsQuery({
          publicUserId: input.publicUserId,
          publicTeamId: input.publicTeamId,
        }),
      );
      return getImageEditorProjectsFromDocs(snap.docs);
    } catch (err) {
      debugError("Error fetching user image editor projects: ", err);
      return {};
    }
  }

  /**
   * Real-time listener for a user's/team's image editor projects.
   * Returns an unsubscribe function.
   */
  onUserImageEditorProjectsUpdate(
    input: OnUserImageEditorProjectsUpdateInput,
  ): OnImageEditorProjectsUpdateReponse {
    return onSnapshot(
      this.getUserImageEditorProjectsQuery({
        publicUserId: input.publicUserId,
        publicTeamId: input.publicTeamId,
      }),
      (snap) => {
        input.callback(getImageEditorProjectsFromDocs(snap.docs));
      },
    );
  }

  /**
   * Fetch a single image editor project by ID.
   */
  async getImageEditorProject(
    input: GetImageEditorProjectInput,
  ): Promise<GetImageEditorProjectResponse> {
    try {
      const ref = getImageEditorProjectDocRef({
        firestore: this.firestore,
        projectId: input.projectId,
      });
      const snap = await getDoc(ref);
      const data = snap.data();
      if (isImageEditorProject(data)) {
        return data;
      }
    } catch (error) {
      debugError(`Error fetching image editor project ${input.projectId}: `, error);
    }
    return undefined;
  }

  async getImageEditorProjectsByAssetStoragePath({
    assetStoragePath,
    publicTeamId,
  }: GetImageEditorProjectsByAssetStoragePathInput & {
    publicTeamId: PublicTeamId;
  }): Promise<GetImageEditorProjectsByAssetStoragePathResponse> {
    try {
      const collectionRef = getImageEditorProjectsCollectionRef(this.firestore);

      const assetId = getUserAssetIdFromPath(assetStoragePath);

      const docSnapshot = await getDocs(
        query(
          collectionRef,
          where(`usedAsset.${assetId}`, "==", true),
          where("publicTeamId", "==", publicTeamId),
        ),
      );
      return docSnapshot.docs
        .map((doc) => {
          if (!doc.exists()) {
            return;
          }
          const docData = doc.data();
          if (!isImageEditorProject(docData)) {
            return;
          }
          return {
            id: doc.id,
            project: docData,
          };
        })
        .filter((data) => data != null);
    } catch (error) {
      debugError(
        `[getImageEditorProjectsByAssetStoragePath] Error finding image editor project: `,
        error,
      );
      return [];
    }
  }

  async getImageEditorProjectsBySourceImageStoragePath({
    sourceImageStoragePath,
    publicTeamId,
  }: GetImageEditorProjectsBySourceImageStoragePathInput & {
    publicTeamId: PublicTeamId;
  }): Promise<GetImageEditorProjectsBySourceImageStoragePathResponse> {
    try {
      const collectionRef = getImageEditorProjectsCollectionRef(this.firestore);
      const docSnapshot = await getDocs(
        query(
          collectionRef,
          where("sourceImageStoragePath", "==", sourceImageStoragePath),
          where("publicTeamId", "==", publicTeamId),
        ),
      );
      return docSnapshot.docs
        .map((doc) => {
          if (!doc.exists()) {
            return;
          }
          const docData = doc.data();
          if (!isImageEditorProject(docData)) {
            return;
          }
          return {
            id: doc.id,
            project: docData,
          };
        })
        .filter((data) => data != null);
    } catch (error) {
      debugError(
        `[findImageEditorProjectBySourceImageStoragePath] Error finding image editor project: `,
        error,
      );
      return [];
    }
  }

  /**
   * Real-time listener for a single image editor project doc.
   */
  onImageEditorProjectUpdate(
    input: OnImageEditorProjectUpdateInput,
  ): OnImageEditorProjectUpdateReponse {
    const ref = getImageEditorProjectDocRef({
      firestore: this.firestore,
      projectId: input.projectId,
    });
    return onSnapshot(ref, (snap) => {
      const data = snap.data();
      input.callback(isImageEditorProject(data) ? data : undefined);
    });
  }

  /**
   * Update certain fields on an image editor project.
   * Firestore rules only allow updating certain user-editable fields
   * (like `isDeleted`, `timeModified`, etc.).
   */
  async updateImageEditorProject(
    input: UpdateImageEditorProjectInput,
  ): Promise<UpdateImageEditorProjectResponse> {
    try {
      input.updateData.timeModified = serverTimestamp() as Timestamp;
      const ref = getImageEditorProjectDocRef({
        firestore: this.firestore,
        projectId: input.projectId,
      });
      await updateDoc(ref, removeUndefinedFromObject(input.updateData));
      return {
        ok: true,
        message: "OK",
      };
    } catch (error) {
      debugError(`Error updating image editor project ${input.projectId}: `, error);
      return {
        ok: false,
        message: "Cannot update project.",
      };
    }
  }

  /**
   * Mark a project as deleted by setting its `isDeleted` field to true.
   * Depending on your Firestore rules, this might be all you need to do.
   */
  async deleteImageEditorProject(
    input: DeleteImageEditorProjectInput,
  ): Promise<DeleteImageEditorProjectResponse> {
    try {
      const response = await this.updateImageEditorProject({
        projectId: input.projectId,
        updateData: { isDeleted: true },
      });

      debugLog(`Image editor project ${input.projectId} marked deleted`);

      return response;
    } catch (error) {
      debugError(`Error deleting image editor project ${input.projectId}: `, error);
      return {
        ok: false,
        message: "Cannot delete project.",
      };
    }
  }

  // -------------------------------------------------------------------------
  // PastGenerations queries / updates
  // -------------------------------------------------------------------------

  /**
   * Past generations are stored in a separate top-level collection "imageEditorPastGenerations".
   * To get all past generations for a given project, we filter by `imageEditorProjectId`.
   */
  private getImageEditorProjectPastGenerationsQuery({ projectId }: { projectId: string }) {
    return query(
      getImageEditorPastGenerationsCollectionRef(this.firestore),
      where("imageEditorProjectId", "==", projectId),
    );
  }

  private getImageEditorPastGenerationsWithStoragePathQuery({
    storagePath,
    projectId,
  }: {
    storagePath: string;
    projectId: string;
  }) {
    return query(
      getImageEditorPastGenerationsCollectionRef(this.firestore),
      where("imageEditorProjectId", "==", projectId),
      where("storagePath", "==", storagePath),
    );
  }

  /**
   * Fetch all past generations for a given image editor project, once.
   */
  async getImageEditorProjectPastGenerations(
    input: GetImageEditorProjectPastGenerationsInput,
  ): Promise<GetImageEditorProjectPastGenerationsResponse> {
    try {
      const snap = await getDocs(
        this.getImageEditorProjectPastGenerationsQuery({ projectId: input.projectId }),
      );
      return getImageEditorPastGenerationsFromDocs(snap.docs);
    } catch (err) {
      debugError("Error fetching image editor past generations: ", err);
      return {};
    }
  }

  async getImageEditorPastGenerationsWithStoragePath(
    input: GetImageEditorPastGenerationsWithStoragePathInput,
  ): Promise<GetImageEditorPastGenerationsWithStoragePathResponse> {
    try {
      const snap = await getDocs(
        this.getImageEditorPastGenerationsWithStoragePathQuery({
          projectId: input.projectId,
          storagePath: input.storagePath,
        }),
      );
      return getImageEditorPastGenerationsFromDocs(snap.docs);
    } catch (err) {
      debugError("Error fetching image editor past generations: ", err);
      return {};
    }
  }

  async getImageEditorSourcePastGeneration(
    input: GetImageEditorSourcePastGenerationInput,
  ): Promise<GetImageEditorSourcePastGenerationResponse> {
    try {
      const { projectId } = input;

      const projectDoc = await this.getImageEditorProject({ projectId });
      if (!projectDoc) {
        return undefined;
      }

      const { sourceImageStoragePath } = projectDoc;

      const pastGenerations = await this.getImageEditorPastGenerationsWithStoragePath({
        projectId,
        storagePath: sourceImageStoragePath,
      });

      // Return the earliest past generation with the source image storage path
      return Object.values(pastGenerations).sort((a, b) => -sortByTimeModified(a, b))[0];
    } catch (error) {
      debugError(`Error getting image editor ${input?.projectId} source past generation `, error);
      return undefined;
    }
  }

  async updateImageEditorSourcePastGeneration(
    input: UpdateImageEditorSourcePastGenerationInput,
  ): Promise<UpdateImageEditorSourcePastGenerationResponse> {
    try {
      const { projectId } = input;

      const projectDoc = await this.getImageEditorProject({ projectId });
      if (!projectDoc) {
        return;
      }

      const { sourceImageStoragePath } = projectDoc;

      const snapshots = await getDocs(
        this.getImageEditorPastGenerationsWithStoragePathQuery({
          projectId,
          storagePath: sourceImageStoragePath,
        }),
      );

      const pastGenerationDoc = snapshots.docs.sort(
        (a, b) => -sortByTimeModified(a.data(), b.data()),
      )[0];

      const { pastGeneration } = input;

      await updateDoc(pastGenerationDoc.ref, removeUndefinedFromObject(pastGeneration));
    } catch (error) {
      debugError(`Error updating image editor ${input.projectId}: `, error);
    }
  }
  /**
   * Real-time listener for all past generations of a given project.
   * Returns an unsubscribe function.
   */
  onImageEditorProjectPastGenerationsUpdate(
    input: OnImageEditorProjectPastGenerationsUpdateInput,
  ): OnImageEditorProjectPastGenerationsUpdateResponse {
    return onSnapshot(
      this.getImageEditorProjectPastGenerationsQuery({ projectId: input.projectId }),
      (snap) => {
        input.callback(getImageEditorPastGenerationsFromDocs(snap.docs));
      },
    );
  }

  /**
   * Fetch a single past generation by ID (since it's in a top-level collection).
   */
  async getImageEditorPastGeneration(
    input: GetImageEditorPastGenerationInput,
  ): Promise<GetImageEditorPastGenerationResponse> {
    try {
      const ref = getImageEditorPastGenerationDocRef({
        firestore: this.firestore,
        pastGenerationId: input.pastGenerationId,
      });
      const snap = await getDoc(ref);
      const data = snap.data();
      if (isImageEditorPastGeneration(data)) {
        return data;
      }
    } catch (error) {
      debugError(`Error fetching image edwitor past generation ${input.pastGenerationId}: `, error);
    }
    return undefined;
  }

  /**
   * Real-time listener for a single past generation doc by ID.
   */
  onImageEditorPastGenerationUpdate(
    input: OnImageEditorPastGenerationUpdateInput,
  ): OnImageEditorPastGenerationUpdateResponse {
    const ref = getImageEditorPastGenerationDocRef({
      firestore: this.firestore,
      pastGenerationId: input.pastGenerationId,
    });
    return onSnapshot(ref, (snap) => {
      const data = snap.data();
      input.callback(isImageEditorPastGeneration(data) ? data : undefined);
    });
  }

  async createImageEditorPastGeneration({
    pastGeneration,
  }: CreateImageEditorPastGenerationInput): Promise<CreateImageEditorPastGenerationResponse> {
    try {
      const pastGenerationDocRef = getNewImageEditorPastGenerationDocRef({
        firestore: this.firestore,
      });

      await setDoc(pastGenerationDocRef, {
        ...pastGeneration,
        id: pastGenerationDocRef.id,
        timeCreated: serverTimestamp() as Timestamp,
        timeModified: serverTimestamp() as Timestamp,
      });
      return {
        ok: true,
        message: "OK",
        pastGenerationId: pastGenerationDocRef.id,
      };
    } catch (error) {
      debugError(`Error creating image editor project past generation: `, error);
      return {
        ok: false,
        message: "Unknown server error.",
      };
    }
  }

  /**
   * Update a past generation document. Firestore rules typically allow updating
   * user-editable fields such as `isDeleted`, etc.
   */
  async updateImageEditorPastGeneration(
    input: UpdateImageEditorPastGenerationInput,
  ): Promise<UpdateImageEditorPastGenerationResponse> {
    try {
      input.updateData.timeModified = serverTimestamp() as Timestamp;
      const ref = getImageEditorPastGenerationDocRef({
        firestore: this.firestore,
        pastGenerationId: input.pastGenerationId,
      });
      await updateDoc(ref, removeUndefinedFromObject(input.updateData));
    } catch (error) {
      debugError(`Error updating image editor past generation ${input.pastGenerationId}: `, error);
    }
  }

  /**
   * "Delete" a past generation by marking its `isDeleted` field `true`.
   */
  async deleteImageEditorPastGeneration(
    input: DeleteImageEditorPastGenerationInput,
  ): Promise<DeleteImageEditorPastGenerationResponse> {
    try {
      await this.updateImageEditorPastGeneration({
        pastGenerationId: input.pastGenerationId,
        updateData: { isDeleted: true },
      });
      debugLog(`Past generation ${input.pastGenerationId} marked deleted`);
    } catch (error) {
      debugError(`Error deleting image editor past generation ${input.pastGenerationId}: `, error);
    }
  }

  // -------------------------------------------------------------------------
  // Image editor queries / updates
  // -------------------------------------------------------------------------
  /**
   * Fetch all image editor suggestions for a given team/user, once.
   *
   * @param input - Object containing the publicTeamId.
   * @returns A promise resolving to a dictionary of suggestions keyed by doc ID.
   */
  async getUserImageEditorSuggestions({
    publicTeamId,
  }: {
    publicTeamId: PublicTeamId;
  }): Promise<Record<string, ImageEditSuggestionDoc>> {
    try {
      return await getUserImageEditorSuggestions({
        firestore: this.firestore,
        publicTeamId,
      });
    } catch (err) {
      debugError("Error fetching image editor suggestions: ", err);
      return {};
    }
  }

  /**
   * Real-time listener for image editor suggestions for a given team/user.
   * Returns an unsubscribe function.
   *
   * @param input - Object containing the publicTeamId and a callback.
   * @returns Unsubscribe function.
   */
  onUserImageEditorSuggestionsUpdate({
    publicTeamId,
    callback,
  }: {
    publicTeamId: PublicTeamId;
    callback: (suggestions: Record<string, ImageEditSuggestionDoc>) => void;
  }): () => void {
    return onUserImageEditorSuggestionsUpdate({
      firestore: this.firestore,
      publicTeamId,
      callback,
    });
  }

  /**
   * Fetch a single image editor suggestion document by ID.
   *
   * @param input - Object containing the imageEditorSuggestionId.
   * @returns A promise resolving to the suggestion document, if found.
   */
  async getImageEditorSuggestion({
    imageEditorSuggestionId,
  }: {
    imageEditorSuggestionId: string;
  }): Promise<ImageEditSuggestionDoc | undefined> {
    try {
      return await getImageEditorSuggestion({
        firestore: this.firestore,
        imageEditorSuggestionId,
      });
    } catch (error) {
      debugError(`Error fetching image editor suggestion ${imageEditorSuggestionId}: `, error);
      return undefined;
    }
  }

  /**
   * Real-time listener for a single image editor suggestion document.
   * Returns an unsubscribe function.
   *
   * @param input - Object containing the imageEditorSuggestionId and a callback.
   * @returns Unsubscribe function.
   */
  onImageEditorSuggestionUpdate({
    imageEditorSuggestionId,
    callback,
  }: {
    imageEditorSuggestionId: string;
    callback: (doc: ImageEditSuggestionDoc | undefined) => void;
  }): () => void {
    return onImageEditorSuggestionUpdate({
      firestore: this.firestore,
      imageEditorSuggestionId,
      callback,
    });
  }

  /**
   * Update fields of an image editor suggestion document.
   *
   * @param input - Object containing the imageEditorSuggestionId and the updateData.
   * @returns A promise resolving to an object with the update status.
   */
  async updateImageEditorSuggestion({
    imageEditorSuggestionId,
    updateData,
  }: {
    imageEditorSuggestionId: string;
    updateData: Partial<ImageEditSuggestionDoc>;
  }): Promise<{ ok: boolean; message: string }> {
    try {
      return await updateImageEditorSuggestion({
        firestore: this.firestore,
        imageEditorSuggestionId,
        updateData,
      });
    } catch (error) {
      debugError(`Error updating image editor suggestion ${imageEditorSuggestionId}: `, error);
      return { ok: false, message: "Cannot update suggestion." };
    }
  }
}
