import bigInt from "big-integer";

export function incrementBigInt(value: string, unit = 1) {
  return bigInt(value).add(unit).toString();
}

export function isBigIntLessThanEqual(a: string, b: string) {
  return bigInt(a).compare(b) <= 0;
}

export function getNearestNumberInGrid(value: number, gap: number) {
  return Math.round(value / gap) * gap;
}

export function getBytesFromMegabytes(mb: number) {
  return mb * 1e6;
}

export function getMegabytesFromBytes(bytes: number) {
  return bytes * 1e-6;
}

export function getClosestNumber(goal: number, counts: number[]) {
  return counts.reduce(function (prev, curr) {
    return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
  });
}

/**
 * Performs three-point interpolation using a quadratic Bézier curve (a type of NURBS).
 * The curve is guaranteed to pass through all three points exactly:
 * - startPoint at t=0
 * - midPoint at t=0.5
 * - endPoint at t=1
 *
 * @param args - The parameters for interpolation
 * @param args.startPoint - The starting value (at t=0)
 * @param args.midPoint - The middle value (at t=0.5)
 * @param args.endPoint - The ending value (at t=1)
 * @param args.t - The interpolation parameter [0,1]
 * @returns The interpolated value at the given t
 */
export function threePointInterp(args: {
  startPoint: number;
  midPoint: number;
  endPoint: number;
  t: number;
}): number {
  const { startPoint, midPoint, endPoint, t } = args;

  // Quadratic Bézier interpolation formula that passes through the three points:
  // C(t) = (1-t)(1-2t) * startPoint + 4t(1-t) * midPoint + t(2t-1) * endPoint

  // Calculate Bézier basis functions
  const b0 = (1 - t) * (1 - 2 * t); // First basis function
  const b1 = 4 * t * (1 - t); // Second basis function
  const b2 = t * (2 * t - 1); // Third basis function
  // Apply the basis functions to the control points
  return b0 * startPoint + b1 * midPoint + b2 * endPoint;
}

export function lerp(a: number, b: number, t: number): number {
  return a + t * (b - a);
}

/**
 * Clamps a number between a minimum and a maximum value.
 * It works correctly even if min is greater than max.
 *
 * @param value - The number to clamp.
 * @param min - The minimum boundary.
 * @param max - The maximum boundary.
 * @returns The clamped value.
 */
function clamp(value: number, min: number, max: number): number {
  if (min < max) {
    return Math.min(Math.max(value, min), max);
  } else {
    return Math.min(Math.max(value, max), min);
  }
}

/**
 * Rescales a number from a source range to a target range.
 * It supports scenarios where the minimum can be greater than the maximum.
 *
 * @param params - An object containing the value and range boundaries.
 * @param params.value - The number to rescale.
 * @param params.sourceMin - The minimum of the source range.
 * @param params.sourceMax - The maximum of the source range.
 * @param params.targetMin - The minimum of the target range.
 * @param params.targetMax - The maximum of the target range.
 * @returns The rescaled number, clamped within the target range.
 * @throws Will throw an error if sourceMin and sourceMax are equal.
 */
export function rescaleNumber({
  value,
  sourceMax,
  sourceMin,
  targetMax,
  targetMin,
}: {
  value: number;
  sourceMin: number;
  sourceMax: number;
  targetMin: number;
  targetMax: number;
}): number {
  const sourceRange = sourceMax - sourceMin;

  if (sourceRange === 0) {
    return targetMin;
  }

  // Normalize the value to a [0, 1] range (or [1, 0] if sourceMax < sourceMin)
  const normalizedValue = (value - sourceMin) / sourceRange;

  // Rescale the normalized value to the target range
  const rescaledValue = normalizedValue * (targetMax - targetMin) + targetMin;

  // Clamp the rescaled value within the target range, handling inverted ranges
  return clamp(rescaledValue, targetMin, targetMax);
}

/**
 * Rounds a number to the nearest specified decimal places.
 *
 * @param value - The number to round.
 * @param decimalPlaces - The number of decimal places to round to.
 * @returns The number rounded to the specified decimal places.
 */
export function roundToNearestNumber(value: number, decimalPlaces: number): number {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(value * factor) / factor;
}
