import {
  AppRoleType,
  PublicUserId,
  UploadReferenceHumanArgs,
  UploadReferenceHumanFileArgs,
  UserAssetType,
} from "@/core/common/types";
import { UploadReferenceHumanResponse } from "@/core/common/types/reference-human";
import { debugError } from "@/core/utils/print-utilts";
import { collection, doc, DocumentData, Firestore, where } from "firebase/firestore";
import { Functions, httpsCallable, HttpsCallable } from "firebase/functions";
import {
  createGenerateUserAssetUploadUrlFunction,
  GenerateUserAssetUploadUrlFunction,
  uploadUserAssetFileToStorage,
} from "./asset-upload-utils";

import { PublicTeamId } from "@/core/common/types/team";
import { UserAssetTag } from "@/core/common/types/user-asset-type";
import { SchemaType } from "@google/generative-ai";
import { ReplaceHumanCustomModelPromptArgs, ReplaceHumanCustomModelPromptResponse } from "../base";
import { chatWithImages, parseChatWithImagesResponse } from "./chat-with-images-utils";
import { FirebaseDocQueryGenerator } from "./firebase-doc-query-generator";

export const referenceHumanCollectionName = "referenceHumans";

export function getReferenceHumanCollectionRef(firestore: Firestore) {
  return collection(firestore, referenceHumanCollectionName);
}

interface ReplacePromptResponse {
  prompt: string;
}

function isReplacePromptResponse(response: any): response is ReplacePromptResponse {
  return response?.prompt && typeof response.prompt === "string";
}

export function getReferenceHumanDocRef({
  firestore,
  humanId,
}: {
  firestore: Firestore;
  humanId: string;
}) {
  return doc(getReferenceHumanCollectionRef(firestore), humanId);
}

export class ReferenceHumanManager {
  private firestore: Firestore;
  private firebaseFunctions: Functions;

  private uploadReferenceHumanCallable: HttpsCallable<
    UploadReferenceHumanArgs,
    UploadReferenceHumanResponse
  >;

  private generateUserAssetUploadUrlColabJuly24: GenerateUserAssetUploadUrlFunction;

  constructor({
    firestore,
    firebaseFunctions,
  }: {
    firestore: Firestore;
    firebaseFunctions: Functions;
  }) {
    this.firestore = firestore;
    this.firebaseFunctions = firebaseFunctions;

    this.uploadReferenceHumanCallable = httpsCallable(
      firebaseFunctions,
      "uploadReferenceHumanColabJuly24_v2",
    );

    this.generateUserAssetUploadUrlColabJuly24 = createGenerateUserAssetUploadUrlFunction({
      firebaseFunctions,
    });
  }

  private getReferenceHumanCollectionRef() {
    return getReferenceHumanCollectionRef(this.firestore);
  }

  private getReferenceHumanDocRef(humanId: string) {
    return getReferenceHumanDocRef({
      firestore: this.firestore,
      humanId,
    });
  }

  async uploadReferenceHumanFile(
    args: UploadReferenceHumanFileArgs,
  ): Promise<UploadReferenceHumanResponse> {
    try {
      const faceImageStoragePath = await uploadUserAssetFileToStorage({
        data: args.faceImage,
        publicTeamId: args.publicTeamId,
        assetType: UserAssetType.ReferenceImage,
        tags: [UserAssetTag.ReferenceFaceImage],
        generateAssetUploadUrl: this.generateUserAssetUploadUrlColabJuly24,
      });

      if (!faceImageStoragePath) {
        return {
          ok: false,
          message: "Unable to upload reference face image.",
        };
      }

      const response = await this.uploadReferenceHumanCallable({
        faceImageStoragePath,
      });

      return response.data;
    } catch (error) {
      debugError("Error uploading reference human to storage ", error);

      return {
        ok: false,
        message: "Internal server error.",
      };
    }
  }

  getReferenceHumanGenerator({
    publicUserId,
    batchSize = 10,
  }: {
    batchSize: number;
    publicUserId: PublicUserId;
    publicTeamId: PublicTeamId;
  }) {
    return new FirebaseDocQueryGenerator<DocumentData>({
      baseQuery: this.getReferenceHumanCollectionRef(),
      batchSize,
      queryConstraints: [
        // where("publicTeamId", "==", publicTeamId),
        where(`roles.${publicUserId}`, "in", Object.values(AppRoleType)),
      ],
    });
  }

  async replaceHumanCustomModelPrompt({
    prompt,
    humanCaption,
  }: ReplaceHumanCustomModelPromptArgs): Promise<ReplaceHumanCustomModelPromptResponse> {
    try {
      const llmPrompt = `
You are a text-rewriting assistant specialized in substituting the human subject of a text with a new subject. Follow these step-by-step instructions exactly and return only the final JSON result in the format {"caption": "..."}.

1. Task Overview:
   - You are given two pieces of text:
       (A) A "humanCaption" which describes a person (e.g. "a woman with blonde hair").
       (B) A "prompt" which is a complete text prompt describing an image or situation (e.g. "photo of a man with black hair wearing a suit and holding a briefcase").
   - Your job: Replace any existing person (if present) in (B) with the new person specified in (A). For example, if (A) is "a woman with blonde hair" and (B) is "photo of a man with black hair wearing a suit and holding a briefcase", the output should be "photo of a woman with blonde hair wearing a suit and holding a briefcase".

2. Detailed Steps:
   - Locate the primary human subject in the original prompt (B).
   - Remove or replace references to that existing subject with the new subject described by (A).
   - If the original prompt (B) does not explicitly mention a person (e.g. "a large rock in a forest"), then:
       - Insert the new "humanCaption" into the appropriate place that makes sense contextually.
       - If the original text has verbs or actions that imply a subject (e.g. "wearing", "holding", "standing"), attach them to the newly inserted subject from (A).
   - Do not alter other objects, contexts, or details in (B). For instance, if the prompt has "red jacket" or "beach background", keep those details. Only swap or introduce the person as needed.
   - If the new subject partially overlaps with the original person (e.g. original text says "a woman with black hair" but the new subject is "a woman with blonde hair"), you should preserve the rest of the existing descriptors (like "wearing a necklace") but overwrite contradictory descriptors (hair color, gender, etc.) with those from the new caption.
   - Return the final result in a JSON object with the exact schema {"caption": "...modified text..."} and nothing else.

3. Formatting Requirements:
   - Return **only** one JSON object in the form:
     {"caption": "final modified text here"}
   - Do not include additional explanations, phrases like “Here is your modified text,” or any extra commentary.
   - Do not include code fences or any text outside the JSON.
   - If multiple changes are needed, make them all in the final "caption".

4. Example Edge Behaviors:
   - If the original prompt has **no mention** of a person, insert the new subject in a natural way (e.g., "a big tree near a fence" → "a big tree near a fence with a woman in a red hat").
   - If multiple people are mentioned, replace **only** the primary or first-mentioned person unless the context strongly implies a single person. If ambiguous, replace the first-labeled person (or the best guess of the “main person”).
   - If the new "humanCaption" is contradictory to descriptors, allow those contradictory descriptors to be overwritten (hair color, clothing, gender). Keep non-contradictory descriptors that do not conflict.

5. Key Reminders:
   - Use clear instructions so the model does not guess what to do.
   - Enforce the final structure strictly.
   - Provide examples so the model understands all corner cases.

6. Return Policy:
   - Your final output to the user must strictly follow the JSON schema.
   - Do not output in any other format.
   - End your message immediately after providing the single JSON object.

Replace the person in the following prompt with caption: '${humanCaption}'.
Use the person caption as the subject with a verb if there is no person, e.g. wearing, holding, standing, etc.
Return a JSON object of schema {"caption": string}.
Return the modified prompt directly, DO NOT say anything like "here's a description" etc.
Prompt: '${prompt}'.
      `.trim();

      const responseText = await chatWithImages({
        firebaseFunctions: this.firebaseFunctions,
        request: {
          imageUrls: [],
          llmPrompt,
          generationConfig: {
            responseMimeType: "application/json",
            responseSchema: {
              description: "Output prompt.",
              type: SchemaType.OBJECT,
              properties: {
                prompt: {
                  type: SchemaType.STRING,
                  nullable: false,
                  description: "Output prompt.",
                },
              },
              required: ["prompt"],
            },
          },
        },
      });

      const parsedResponse = await parseChatWithImagesResponse({
        responseText,
        isResponse: isReplacePromptResponse,
      });

      return parsedResponse?.prompt ?? "";
    } catch (error) {
      debugError(
        "[replaceHumanCustomModelPrompt] Error replacing human custom model prompt: ",
        error,
      );
      return "";
    }
  }
}
